<template>
  <div class="">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h2 class="text-base font-semibold leading-6 text-gray-900">{{ titlePage }}</h2>
        <p class="mt-2 text-sm text-gray-700">{{ itemName }}</p>
      </div>
      <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none" v-if="type !== 'training-categories'">
        <button type="button" @click="displayEditDialog = true; dataToModify = null"
          class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">{{
            textButton }}</button>
      </div>
    </div>
    <div class="mt-8 flow-root mx-auto">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">

          <table class="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                  Nom
                </th>
                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                  <span class="sr-only">Edition</span>
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
              <tr v-for="relevantItem in relevantItems" :key="relevantItem.id" class="even:bg-gray-50">
                <td class="whitespace-nowrap px-3 py-4 text-sm font-medium even:bg-gray-50">
                  <span v-if="relevantItem.numerotation"> {{
                    relevantItem.numerotation }}</span>
                  {{
                    relevantItem.name }}
                </td>
                <td><button class="text-indigo-700 underline text-sm underline-offset-4"
                    @click="dataToModify = relevantItem; displayEditDialog = true;">modifier</button></td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
  <AdminDialog @confirm="createItem" :typeEdition="typeEdition" :title="titleModalEdition" :type="type"
    @close="displayEditDialog = false" :objectToModify="dataToModify" v-if="displayEditDialog"
    :primaryButtonLabel="'créer'" :secondaryButtonLabel="'annuler'" />
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    :detail="notificationMessage" @close="showNotification = false" />
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, computed, inject } from 'vue';
import client from '@/api/client';
import AdminDialog from '@/components/referential/AdminDialog.vue';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';

const store = inject('store');
const props = defineProps({
  type: String,
});

const relevantItems = ref([]);
const displayEditDialog = ref(false);
const displayConfirmDeleteDialog = ref(false);
const typeEdition = ref('creation');
const idTypeToEdit = ref(null);
const dataToModify = ref(null);
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const textButton = ref('');
const titleModalEdition = ref('');
const titlePage = ref('');
const itemName = ref('');

const fetchItems = async () => {
  try {
    const response = await client.get(`api/${props.type}`);
    relevantItems.value = response.data;
  } catch (error) {
    console.error('Failed to fetch Degrees :', error);
  }
};

const typeModelForm = ref([
  {
    field: 'name',
    label: 'Nom',
    type: 'text',
    entry: '',
  },
]);

const createItem = async (itemValues, itemId) => {
  let arrayToPost = {};
  arrayToPost.name = itemValues.name;
  if (props.type === 'training-themes') {
    arrayToPost.numerotation = itemValues.numerotation;
  } else if (props.type === 'training-places') {
    arrayToPost.address = itemValues.address;
    arrayToPost.zip_code = itemValues.zip_code;
    arrayToPost.city_id = itemValues.city?.id;
    arrayToPost.type = itemValues.type;
  } else if (props.type === 'training-categories') {
    arrayToPost.irt_link = itemValues.irt_link;
  }
  displayEditDialog.value = false;
  if (!itemId) {
    try {
      const response = await client.post(`api/${props.type}`, arrayToPost);
      fetchItems();
      showNotification.value = true;
      setTimeout(() => { showNotification.value = false; }, 3000);
      notificationType.value = 'success';
      notificationTitle.value = 'Bravo, l\'item a bien été créé';
      notificationMessage.value = '';
    } catch (error) {
      showNotification.value = true;
      notificationType.value = 'error';
      notificationTitle.value = 'Erreur';
      notificationMessage.value = `Erreur à la création de l'item ${error.response && error.response.data.message ? error.response.data.message : ''}`;
    }
  } else {
    try {
      const response = await client.put(`api/${props.type}/${itemId}`, arrayToPost);
      fetchItems();
      showNotification.value = true;
      setTimeout(() => { showNotification.value = false; }, 3000);
      notificationType.value = 'success';
      notificationTitle.value = 'Bravo, l\'item a bien été créé';
      notificationMessage.value = '';
    } catch (error) {
      showNotification.value = true;
      notificationType.value = 'error';
      notificationTitle.value = 'Erreur';
      notificationMessage.value = `Erreur à la création de l'item ${error.response && error.response.data.message ? error.response.data.message : ''}`;
    }
  }

};

onMounted(() => {
  if (props.type === 'degrees') {
    textButton.value = 'Ajouter un diplôme';
    titleModalEdition.value = 'Edition d\'un diplôme';
    titlePage.value = 'Diplômes';
    itemName.value = 'Liste des diplômes enregistrés';
    fetchItems();
  } else if (props.type === 'professional-categories') {
    textButton.value = 'Ajouter une catégorie';
    titleModalEdition.value = 'Edition d\'une catégorie';
    titlePage.value = 'Catégories professionnelles';
    itemName.value = 'Liste des catégories enregistrées';
    fetchItems();
  }
  else if (props.type === 'referral-sources') {
    textButton.value = 'Ajouter une source';
    titleModalEdition.value = 'Edition d\'une source';
    titlePage.value = 'Sources de connaissance de l\'IRT';
    itemName.value = 'Liste des sources de connaissance de l\'IRT enregistrées';
    fetchItems();
  }
  else if (props.type === 'activity-sectors') {
    textButton.value = 'Ajouter une secteur';
    titleModalEdition.value = 'Edition d\'un secteur';
    titlePage.value = 'Secteur professionnel';
    itemName.value = 'Liste des secteurs enregistrés';
    fetchItems();
  }
  else if (props.type === 'professional-situations') {
    textButton.value = 'Ajouter une situation';
    titleModalEdition.value = 'Edition d\'une situation';
    titlePage.value = 'Situations professionnelles';
    itemName.value = 'Liste des situations enregistrées';
    fetchItems();
  }
  else if (props.type === 'company-sizes') {
    textButton.value = 'Ajouter une taille';
    titleModalEdition.value = 'Edition d\'une taille ';
    titlePage.value = 'Tailles d\'entreprise';
    itemName.value = 'Liste des tailles d\'entreprise enregistrées';
    fetchItems();
  }
  else if (props.type === 'council-sections') {
    textButton.value = 'Ajouter une section';
    titleModalEdition.value = 'Edition d\'une section';
    titlePage.value = 'Sections prud\'homme';
    itemName.value = 'Liste des sections prud\'homme enregistrées';
    fetchItems();
  }
  else if (props.type === 'mandates') {
    textButton.value = 'Ajouter un mandat';
    titleModalEdition.value = 'Edition d\'un mandat';
    titlePage.value = 'Mandats';
    itemName.value = 'Liste des mandats enregistrés';
    fetchItems();
  }
  else if (props.type === 'councils') {
    textButton.value = 'Ajouter un conseil';
    titleModalEdition.value = 'Edition d\'un conseil';
    titlePage.value = 'Mandats';
    itemName.value = 'Liste des mandats enregistrés';
    fetchItems();
  }
  else if (props.type === 'training-themes') {
    textButton.value = 'Ajouter une thématique';
    titleModalEdition.value = 'Edition d\'une thématique';
    titlePage.value = 'Thématiques de formation';
    itemName.value = 'Liste des thématiques enregistrées';
    fetchItems();
  } else if (props.type === 'training-categories') {
    textButton.value = 'Ajouter une catégorie';
    titleModalEdition.value = 'Edition d\'une catégorie';
    titlePage.value = 'Catégories de formation';
    itemName.value = 'Liste des catégories enregistrées';
    fetchItems();
  } else if (props.type === 'training-places') {
    textButton.value = 'Ajouter un lieu';
    titleModalEdition.value = 'Edition d\'un lieu';
    titlePage.value = 'Lieux de formation';
    itemName.value = 'Liste des lieux enregistrés';
    fetchItems();
  }
});

</script>