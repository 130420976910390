<template>
  <DashboardLayout>
    <template v-slot:default="{ currentUserName }">
      <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left sm:pl-8">
        <h1 class="text-lg font-semibold leading-6 text-gray-900 mt-12">Bonjour,</h1>
        <h1 class="text-xl sm:text-3xl font-bold leading-tight tracking-tight text-gray-900 mb-2">{{ currentUserName }}
        </h1>
        <p class="text-xl font-bold text-gray-900 sm:text-2xl"></p>
        <p class="text-sm font-medium text-gray-600">{{ currentUserRole }}</p>
      </div>
    </template>

    <template v-slot:primary>
      <IncomingTrainings :title="'Formations des 15 prochains jours'" :type="'next-15-days'" />
    </template>

    <template v-slot:secondary>
      <PastTrainings />
    </template>
  </DashboardLayout>
</template>

<script setup>
import {
  ref, onMounted, inject,
} from 'vue';

import DashboardLayout from '@/layouts/DashboardLayout.vue';
import PastTrainings from '@/components/dashboards/leadTeacher/PastTrainings.vue';
import IncomingTrainings from '@/components/dashboards/leadTeacher/IncomingTrainings.vue';

const store = inject('store');

const currentUserName = ref('');
const currentUserRole = ref('');

onMounted(() => {
  let { user } = store;
  if (!user) {
    user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      store.setUser(user);
    }
  }

  if (user) {
    currentUserName.value = user.username;
    currentUserRole.value = user.role.name;
  }
});
</script>
