<template>
  <div class="hidden 2xl:fixed 2xl:inset-y-0 2xl:z-50 2xl:flex 2xl:w-72 2xl:flex-col">
    <div class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
      <div class="flex h-16 shrink-0 items-center">
        <img class="h-8 w-auto" src="@/assets/img/logo/logo.svg" alt="SIRT - Institut Régional du Travail" />
      </div>
      <nav class="flex flex-1 flex-col">
        <ul role="list" class="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" class="-mx-2 space-y-1">
              <li v-for="item in menuProfileItems" :key="item.name">
                <a :href="item.href" :class="[
                  item.current
                    ? 'bg-gray-50 text-sirt-600'
                    : 'text-gray-700 hover:text-sirt-600 hover:bg-gray-50',
                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                ]">
                  <component :is="item.icon" :class="[
                    item.current
                      ? 'text-sirt-600'
                      : 'text-gray-400 group-hover:text-sirt-600',
                    'h-6 w-6 shrink-0',
                  ]" aria-hidden="true" />
                  {{ item.name }}
                </a>
              </li>
            </ul>
          </li>
          <li v-if="profile?.role?.slug === 'administrateur'">
            <div class="text-xs font-semibold leading-6 text-gray-400">
              Administration
            </div>
            <ul role="list" class="-mx-2 mt-2 space-y-1">
              <li v-for="team in enhancedTeams" :key="team.name">
                <a :href="team.href" :class="[
                  team.current
                    ? 'bg-gray-50 text-sirt-600'
                    : 'text-gray-700 hover:text-sirt-600 hover:bg-gray-50',
                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                ]">
                  <span :class="[
                    team.current
                      ? 'text-sirt-600 border-sirt-600'
                      : 'text-gray-400 border-gray-200 group-hover:border-sirt-600 group-hover:text-sirt-600',
                    'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white',
                  ]">{{ team.initial }}</span>
                  <span class="truncate">{{ team.name }}</span>
                </a>
              </li>
            </ul>
          </li>
          <li
            v-if="profile?.role?.slug === 'administrateur' || profile?.role?.slug === 'gestionnaire' || profile?.role?.slug === 'stagiaire'">
            <div class="text-xs font-semibold leading-6 text-gray-400">
              Liens publics
            </div>
            <ul role="list" class="-mx-2 mt-2 space-y-1">
              <li v-for="link in enhancedPublicLinks" :key="link.name">
                <a :href="link.href" :class="[
                  link.current
                    ? 'bg-gray-50 text-sirt-600'
                    : 'text-gray-700 hover:text-sirt-600 hover:bg-gray-50',
                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                ]">
                  <span :class="[
                    link.current
                      ? 'text-sirt-600 border-sirt-600'
                      : 'text-gray-400 border-gray-200 group-hover:border-sirt-600 group-hover:text-sirt-600',
                    'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white',
                  ]">{{ link.initial }}</span>
                  <span class="truncate">{{ link.name }}</span>
                </a>
              </li>
            </ul>
          </li>
          <li class="mt-auto">
            <a href="/informations-legales"
              class="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-sirt-600">
              <ScaleIcon class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-sirt-600" aria-hidden="true" />
              Informations légales
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, computed, inject, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import {
  CalendarDaysIcon,
  RectangleStackIcon,
  AcademicCapIcon,
  HomeIcon,
  UsersIcon,
  ScaleIcon,
  ClockIcon,
  UserCircleIcon,
} from '@heroicons/vue/24/outline';

const route = useRoute();
const store = inject('store');
const profile = ref();
const navigation = [
  {
    name: 'Tableau de bord', href: '/', icon: HomeIcon, current: false, auth: 'all',
  },
  {
    name: 'Mon profil', href: '/profil', icon: UserCircleIcon, current: false, auth: ['stagiaire'],
  },
  {
    name: 'Formations', href: '/formations', icon: RectangleStackIcon, current: false, auth: ['responsable-pedagogique', 'intervenant', 'administrateur', 'gestionnaire'],
  },
  {
    name: 'Stagiaires', href: '/stagiaires', icon: UsersIcon, current: false, auth: ['responsable-pedagogique', 'intervenant', 'administrateur', 'gestionnaire'],
  },
  {
    name: 'Formateurs', href: '/formateurs', icon: AcademicCapIcon, current: false, auth: ['responsable-pedagogique', 'administrateur', 'gestionnaire'],
  },
  {
    name: 'Heures formateurs', href: '/formateurs/heures', icon: ClockIcon, current: false, auth: ['administrateur', 'responsable-pedagogique'],
  },
  {
    name: 'Calendrier', href: '/agenda', icon: CalendarDaysIcon, current: false, auth: ['responsable-pedagogique', 'intervenant', 'administrateur', 'gestionnaire'],
  },
];
let menuProfileItems = ref([]);
const enhancedNavigation = computed(() => navigation.map((item) => ({
  ...item,
  current: item.href === '/' ? route.path === item.href : route.path.startsWith(item.href),
})));

const teams = [
  {
    id: 1, name: 'Équipe', href: '/equipe', initial: 'E',
  },
  {
    id: 2, name: 'Régie', href: '/regie', initial: 'R',
  },
  {
    id: 3, name: 'Exports', href: '/exports', initial: 'E',
  },
  {
    id: 4, name: 'Référentiel', href: '/referentiel', initial: 'R',
  },
];

const publicLinks = [
  { name: 'Catalogue de formations', initial: 'C', href: '/catalogue-de-formations' },
];

const enhancedTeams = computed(() => teams.map((team) => ({
  ...team,
  current: route.path.startsWith(team.href),
})));

const enhancedPublicLinks = computed(() => publicLinks.map((link) => ({
  ...link,
  current: route.path.startsWith(link.href),
})));

const checkMenuItems = () => {
  let slug = null;
  if (profile.value) {
    slug = profile.value.role.slug;
  }
  menuProfileItems.value = navigation.filter(item => {
    return item.auth === 'all' || item.auth.includes(slug)
  });
};

onMounted(() => {
  profile.value = JSON.parse(localStorage.getItem('user'));
  checkMenuItems();
});

</script>
