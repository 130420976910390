<template>
  <BigLoader v-if="isLoading" title="Chargement de la formation" text="" />
  <TrainingTitleModification v-if="displayTitleModal" :training="relevantTraining" @close="displayTitleModal = false"
    title="Modifier l'intitulé" message="Vous pouvez uniquement modifier l'intitulé de la formation."
    primary-button-label="Enregistrer" secondary-button-label="Annuler" @confirm="confirmStatus" />
  <AppLayout>
    <div class="lg:hidden">
      <label for="tabs" class="sr-only">Choisissez un onglet</label>
      <select id="tabs" name="tabs"
        class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-sm"
        @change="updateCurrentTab($event.target.value)">
        <option v-for=" tab in menuProfileItems " :key="tab.name" :value="tab.name" :selected="tab.current">
          {{ tab.name }}
        </option>
      </select>
    </div>
    <div class="hidden lg:block">
      <nav class="flex justify-center space-x-4" aria-label="Tabs">
        <a v-for=" tab in menuProfileItems " :key="tab.name" href="javascript:void(0)"
          @click.prevent="updateCurrentTab(tab.name)" :class="[
            tab.current
              ? 'bg-indigo-100 text-indigo-700'
              : 'text-gray-500 hover:text-gray-700',
            'rounded-md px-3 py-2 text-sm font-medium',
          ]
            " :aria-current="tab.current ? 'page' : undefined">{{ tab.name }}</a>
      </nav>
    </div>
    <main v-if="relevantTraining.id">
      <div class="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
        <div class="md:flex md:items-center md:justify-between">
          <div class="min-w-0 flex-1">
            <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:tracking-tight">
              {{ relevantTraining?.title }}
            </h2>
            <div class="mt-5" v-if="profile?.role.slug !== 'stagiaire'"><span
                :class="'status' + relevantTraining.status?.id" class="status">
                {{ relevantTraining.status?.name }}
              </span>
              <button type="button" class="ml-2 text-xs underline text-indigo-700"
                v-if="profile?.role.slug === 'administrateur'"
                @click.prevent="displayStatusModal = true; forceStatus = true; componentKey += 1">
                Forcer le statut
              </button>
            </div>
          </div>
          <div class="mt-4 flex md:ml-4 md:mt-0" v-if="user_can_edit(profile?.role.slug)">
            <router-link :to="'/formations/editer-une-formation/' + $route.params.id"> <button type="button"
                v-if="(profile?.role.slug === 'administrateur' || profile?.role.slug === 'gestionnaire' || profile?.role.slug === 'responsable-pedagogique') && relevantTraining.status?.id === 1"
                class="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                Éditer
              </button></router-link>
            <button type="button" @click="displayTitleModal = true"
              v-if="(profile?.role.slug === 'administrateur' || profile?.role.slug === 'gestionnaire' || profile?.role.slug === 'responsable-pedagogique') && (relevantTraining.status?.id === 2 || relevantTraining.status?.id === 3)"
              class="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
              Modifier
            </button>

            <TrainingChangeStatusModal :training="relevantTraining"
              v-if="(profile?.role.slug === 'administrateur') || (profile?.role.slug === 'gestionnaire')"
              :key="componentKey" @confirm="confirmStatus" :forceStatus="forceStatus" />
          </div>
        </div>
        <TrainingInformations v-if="currentTab.name === 'Informations'" :training="relevantTraining"
          :registration="registration" @statusUpdated="fetchTraining" />
        <TrainingActions v-else-if="currentTab.name === 'Actions'" @changeTab="updateCurrentTab"
          :training="relevantTraining" />
        <TrainingFiles v-else-if="currentTab.name === 'Actions par stagiaire'" :trainees="relevantTraining.trainees"
          :training="relevantTraining" />
        <div v-else-if="currentTab.name === 'Stagiaires'">
          <TrainingStats :stats="trainingStats" />
          <TrainingTraineesList :trainees="relevantTraining.trainees" :training="relevantTraining"
            @statusUpdated="fetchTraining" :key="componentKey" />
        </div>
        <TrainingTeamWithHours v-else-if="currentTab.name === 'Équipe' && profile.role.slug !== 'gestionnaire'"
          :teachers="relevantTraining.teachers" :supervisors="relevantTraining.pedagogical_supervisors"
          :idTraining="relevantTraining.id" :training="relevantTraining" />
        <TrainingTeam v-else-if="currentTab.name === 'Équipe' && profile.role.slug === 'gestionnaire'"
          :teachers="relevantTraining.teachers" :supervisors="relevantTraining.pedagogical_supervisors" />
        <TrainingAttendances v-if="currentTab.name === 'Présences'" :training="relevantTraining"
          @confirm="fetchTraining(); componentKey++" style="position:relative;" :key="componentKey" />
        <TrainingExpenses v-else-if="currentTab.name === 'Défraiements'" :training="relevantTraining" />
        <TrainingFinances v-else-if="currentTab.name === 'Indicateurs'" />
        <TrainingReimbursement v-else-if="currentTab.name === 'Remboursements'" :training="relevantTraining"
          :registration="registration" />
        <TrainingEvaluations v-else-if="currentTab.name === 'Évaluations'" :training="relevantTraining" />

      </div>
    </main>
  </AppLayout>
</template>

<script setup>
/* eslint-disable */
import { ref, computed, onMounted, inject, watch } from 'vue';
import AppLayout from '@/layouts/AppLayout.vue';
import TrainingInformations from '@/components/trainings/TrainingInformations.vue';
import TrainingActions from '@/components/trainings/TrainingActions.vue';
import TrainingFiles from '@/components/trainings/TrainingFiles.vue';
import TrainingStats from '@/components/trainings/TrainingStats.vue';
import TrainingTraineesList from '@/components/trainings/TrainingTraineesList.vue';
import TrainingTeamWithHours from '@/components/trainings/TrainingTeamWithHours.vue';
import TrainingFinances from '@/components/trainings/TrainingFinances.vue';
import TrainingComments from '@/components/trainings/TrainingComments.vue';
import TrainingExpenses from '@/components/trainings/TrainingExpenses.vue';
import TrainingAttendances from '@/components/trainings/TrainingAttendances.vue';
import { useRoute, useRouter } from 'vue-router';
import fetchTrainingsServices from '@/services/TrainingsServices.js';
import moment from 'moment';
import TrainingChangeStatusModal from '@/components/trainings/TrainingChangeStatusModal.vue';
import { PencilSquareIcon } from '@heroicons/vue/20/solid';
import BigLoader from '@/components/app/BigLoader.vue';
import TrainingTitleModification from '@/components/trainings/TrainingTitleModification.vue';
import TrainingReimbursement from '@/components/trainings/TrainingReimbursement.vue';
import TrainingEvaluations from '@/components/trainings/TrainingEvaluations.vue';
import TrainingTeam from '@/components/trainings/TrainingTeam.vue';

const store = inject('store');
const profile = ref();
const route = useRoute();
const router = useRouter();
const displayStatusModal = ref(false);
const componentKey = ref(0);
const forceStatus = ref(false);
const isLoading = ref(false);
const tabs = ref([
  { name: 'Informations', href: '#', current: true, auth: ['responsable-pedagogique', 'intervenant', 'administrateur', 'gestionnaire', 'stagiaire'] },
  { name: 'Actions', href: '#', current: false, auth: ['administrateur', 'gestionnaire'] },
  { name: 'Stagiaires', href: '#', current: false, auth: ['responsable-pedagogique', 'intervenant', 'administrateur', 'gestionnaire'] },
  { name: 'Équipe', href: '#', current: false, auth: ['responsable-pedagogique', 'administrateur', 'gestionnaire'] },
  { name: 'Présences', href: '#', current: false, auth: ['responsable-pedagogique', 'intervenant', 'administrateur', 'gestionnaire'] },
  { name: 'Défraiements', href: '#', current: false, auth: ['administrateur', 'gestionnaire'] },
  { name: 'Actions par stagiaire', href: '#', current: false, auth: ['responsable-pedagogique', 'intervenant', 'administrateur', 'gestionnaire'] },
  { name: 'Indicateurs', href: '#', current: false, auth: [''] },
  { name: 'Remboursements', href: '#', current: false, auth: ['stagiaire', 'responsable-pedagogique', 'intervenant'] },
  { name: 'Évaluations', href: '#', current: false, auth: ['administrateur', 'gestionnaire', 'responsable-pedagogique', 'intervenant'] },
]);
const displayTitleModal = ref(false);
const menuProfileItems = ref([]);
const registration = ref();

const currentTab = computed(() => {
  const activeTab = menuProfileItems.value.find((tab) => tab.current);
  return activeTab || tabs.value[0];
});

const updateCurrentTab = (selectedTabName) => {
  menuProfileItems.value = menuProfileItems.value.map((tab) => ({
    ...tab,
    current: tab.name === selectedTabName,
  }));
};
const relevantTraining = ref([]);

const user_can_edit = (role) => {
  if (role === 'gestionnaire' || role === 'administrateur' || role === 'responsable-pedagogique') {
    return true;
  } else {
    return false;
  }
};
const trainingStats = ref([]);
const fillRate = computed(() => {
  const stat = (relevantTraining.value.registered_count * 100) / relevantTraining.value.max_attendees;
  const fillRate = Math.floor(stat);
  return fillRate + '%';
});

const fetchTraining = async () => {
  isLoading.value = true;
  fetchTrainingsServices.fetchTraining(route.params.id).then((data) => {
    relevantTraining.value = data;
    componentKey.value += 1;
    const stat = (relevantTraining.value.registered_count * 100) / relevantTraining.value.max_attendees;
    const fillRate = Math.floor(stat);
    trainingStats.value = [
      { name: 'Inscrits', stat: relevantTraining.value.registered_count },
      { name: 'Préinscrits', stat: relevantTraining.value.pre_registered_count },
      { name: 'Nombre de places', stat: relevantTraining.value.max_attendees },
      { name: 'Taux de remplissage', stat: fillRate + '%' },
    ];
    isLoading.value = false;
    if (profile.value.role.slug === 'stagiaire') {
      findRegistration();
    }
  });
};

const checkMenuItems = () => {
  let slug = null;
  if (profile.value) {
    slug = profile.value.role.slug;
  }
  menuProfileItems.value = tabs.value.filter(item => {
    return item.auth === 'all' || item.auth.includes(slug)
  });
};

const confirmStatus = () => {
  fetchTraining();
  forceStatus.value = false
};

const findRegistration = () => {
  registration.value = relevantTraining.value.trainees.find(trainee => trainee.id === profile.value.id);
};

watch(() => route.params.idTraining, (newIdTraining) => {
  console.log('newIdTraining', newIdTraining);
  this.fetchTrainingData(newIdTraining);
});
onMounted(() => {
  profile.value = JSON.parse(localStorage.getItem('user'));
  checkMenuItems();
  fetchTraining();
  if (route.query.tab) {
    updateCurrentTab(route.query.tab);
  }
});
</script>
