<template>
  <BigLoader v-if="isLoading" title="Chargement des stagiaires" text="" />
  <AppLayout>
    <div class="px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-base font-semibold leading-6 text-gray-900">
            Formateurs
          </h1>
          <p class="mt-2 text-sm text-gray-700">
            Vous trouverez ici la liste des responsables pédagogiques et des intervenants.
          </p>
        </div>
        <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <div>
            <button type="button" @click.prevent="gotoTeacherCreation(3)" class="btn-secondary mr-2"
              v-if="profile?.role?.slug !== 'intervenant' && profile?.role?.slug !== 'responsable-pedagogique'">Créer
              un responsable péda.</button>
            <button type="button" @click.prevent="gotoTeacherCreation(4)" class="btn-secondary"
              v-if="profile?.role?.slug !== 'intervenant'">Créer
              un intervenant</button>
          </div>
        </div>
      </div>
      <div class="mt-8 flow-root">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table class="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    Nom
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Rôle
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Statut
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Fonction
                  </th>

                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span class="sr-only">Détails</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="teacher in teachers" :key="teacher.id">
                  <td class="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                    <div class="flex items-center">
                      <div class="h-11 w-11 flex-shrink-0">
                        <template v-if="teacher.temporary_profile_picture_url">
                          <img class="h-11 w-11 rounded-full object-cover" :src="teacher.temporary_profile_picture_url"
                            alt="Profile picture" />
                        </template>
                        <template v-else>
                          <UserCircleIcon class="h-11 w-11 text-gray-400" />
                        </template>
                      </div>
                      <div class="ml-4">
                        <div class="font-medium text-gray-900">
                          <button @click.prevent="goToteacherDetails(teacher.id)"> {{ teacher.user.name }}</button>
                        </div>

                        <div class="mt-1 text-gray-500">{{ teacher.user.email }}</div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div><span v-if="teacher.role_id === 3" class="status status3">Responsable
                        pédagogique</span><span class="status status2" v-if="teacher.role_id === 4">Intervenant</span>
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                    <div class="mt-1 text-gray-500">
                      {{ teacher.employment_status_name }}
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                    <div class="mt-1 text-gray-500">
                      {{ teacher.title }}
                    </div>
                  </td>

                  <td class="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                    <a href="#" @click.prevent="goToteacherDetails(teacher.id)">Détails<span class="sr-only">, {{
                      teacher.user.name
                        }}</span></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <Pagination :current-page="currentPage" :total-pages="totalPages" @change-page="changePage" />
  </AppLayout>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import AppLayout from '@/layouts/AppLayout.vue';
import { useRouter } from 'vue-router';
import Pagination from '@/components/lists/PaginationTables.vue';
import client from '@/api/client';
import { UserCircleIcon } from '@heroicons/vue/24/outline';
import BigLoader from '@/components/app/BigLoader.vue';

const teachers = ref([]);
const currentPage = ref(1);
const totalPages = ref(1);
const pageSize = 10;
const isLoading = ref(false);
const router = useRouter();

const gotoTeacherCreation = (idRole) => {
  router.push({ name: 'Créer un membre de l\'équipe', params: { roleId: idRole } });
};

const fetchteachers = async (page = 1) => {
  isLoading.value = true;
  try {
    const response = await client.get('/api/teachers', {
      params: { page, per_page: pageSize },
    });
    teachers.value = response.data.data;
    currentPage.value = response.data.current_page;
    totalPages.value = response.data.last_page;
    isLoading.value = false;
  } catch (error) {
    console.error('Failed to fetch teachers:', error);
  }
};

const goToteacherDetails = (id) => {
  router.push({ name: 'Détails de l\'enseignant', params: { id } });
};

const changePage = (page) => {
  fetchteachers(page);
};

onMounted(() => {
  fetchteachers();
});
</script>
