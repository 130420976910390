<template>
    <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
        :detail="notificationMessage" @close="showNotification = false" />
    <ExpenseUpload v-if="displayExpenseUpload" @close="closeExpenseUpload" :key="componentKey" style="z-index: 999;"
        :expense="expenseId" @savedReceipt="receiptResponse" />
    <TransitionRoot as="template" :show="open" style="z-index:100;">
        <Dialog as="div" class="relative z-10" @close="open = false">
            <div class="fixed inset-0" />

            <div class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                        <TransitionChild as="template"
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enter-from="translate-x-full" enter-to="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leave-from="translate-x-0" leave-to="translate-x-full">
                            <DialogPanel class="pointer-events-auto w-screen max-w-md">
                                <div class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                                    <div class="px-4 py-6 sm:px-6 bg-indigo-700">
                                        <div class="flex items-start justify-between">
                                            <div>
                                                <h2 id="slide-over-heading"
                                                    class="text-base font-semibold leading-6 text-white">Défraiement
                                                    pour la formation
                                                </h2>
                                                <div class="text-indigo-300">{{ training.title }}</div>
                                            </div>
                                            <div class="ml-3 flex h-7 items-center">
                                                <button type="button"
                                                    class="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                    @click="$emit('close')">
                                                    <span class="absolute -inset-2.5" />
                                                    <span class="sr-only">Fermer</span>
                                                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Main -->
                                    <div>
                                        <div class="pb-1 sm:pb-6">
                                            <div>
                                                <div class="relative h-40 sm:h-56" v-if="expense.profile_picture_url">
                                                    <img class="absolute h-full w-full object-cover"
                                                        :src="expense.profile_picture_url" alt="" />

                                                </div>
                                                <div class="mt-6 px-4 sm:mt-8 sm:flex sm:items-end sm:px-6 mb-5">
                                                    <div class="sm:flex-1">
                                                        <div>
                                                            <div class="flex items-center">
                                                                <h3 class="text-xl font-bold text-gray-900 sm:text-2xl">
                                                                    {{ username }}</h3>
                                                            </div>
                                                            <p class="text-sm text-gray-500 pt-2" v-if="!isNewExpense">
                                                            </p>
                                                            <span v-if="formExpense.training_expense_status_id"
                                                                :class="'expenseStatus expenseStatus' + formExpense.training_expense_status_id">
                                                                {{ expenseStatus.name }}
                                                            </span>
                                                        </div>
                                                        <div class="mt-5 flex space-y-3 sm:space-x-3 sm:space-y-0">
                                                            <button
                                                                class="btn-primary w-full items-center justify-center"
                                                                v-if="isNewExpense"
                                                                @click.prevent="postExpense(1)">Créer</button>

                                                            <button
                                                                class="btn-secondary w-full items-center justify-center"
                                                                v-if="!isNewExpense && (formExpense.training_expense_status_id !== 2 && formExpense.training_expense_status_id !== 4) && training.training_status_id !== 7"
                                                                @click.prevent="putExpense(1)">Enregistrer</button>
                                                            <button type="button" @click.prevent="putExpense(2)"
                                                                v-if="!isNewExpense && (formExpense.training_expense_status_id !== 2 && formExpense.training_expense_status_id !== 4) && training.training_status_id !== 7"
                                                                class="btn-success inline-flex w-full items-center justify-center">À
                                                                payer</button>
                                                            <button
                                                                class="btn-secondary w-full items-center justify-center"
                                                                v-if="formExpense.training_expense_status_id === 2 && profile?.role?.slug === 'gestionnaire' && !edit"
                                                                @click.prevent="putExpense(1)">Modifier</button>
                                                            <button
                                                                class="btn-secondary w-full items-center justify-center"
                                                                v-if="formExpense.training_expense_status_id === 2 && profile?.role?.slug === 'administrateur' && !edit"
                                                                @click.prevent="editExpense">Modifier</button> <button
                                                                type="button" @click.prevent="putExpense(2)"
                                                                v-if="formExpense.training_expense_status_id === 2 && profile?.role?.slug === 'administrateur' && edit"
                                                                class="btn-success inline-flex w-full items-center justify-center">Enregistrer</button>
                                                        </div>
                                                        <div
                                                            class="bg-emerald-50 px-4 py-2 rounded font-sm text-emerald-700 mt-5 flex items-center ring-1 ring-inset ring-emerald-700/20">
                                                            <div class=""><b>Montant </b>: <span
                                                                    v-if="formExpense.total_forced_amount > 0">{{
                                                                        formExpense.total_forced_amount }}</span><span
                                                                    v-else>{{ formExpense.total_amount
                                                                    }}</span> €</div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="px-4 pb-5 pt-5 sm:px-0 sm:pt-0">
                                            <dl class="space-y-8 px-4 sm:space-y-6 sm:px-6" v-if="edit">
                                                <div class="relative">
                                                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                                                        <div class="w-full border-t border-gray-300" />
                                                    </div>
                                                    <div class="relative flex justify-start">
                                                        <span
                                                            class="bg-white pr-3 text-base font-semibold leading-6 text-gray-900">Forcer
                                                            le montant</span>
                                                    </div>
                                                </div>
                                                <div class="relative mt-2 rounded-md shadow-sm">
                                                    <div
                                                        class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                        <span class="text-gray-500 sm:text-sm">€</span>
                                                    </div>
                                                    <input type="number" name="forced_amount" id="forced_amount"
                                                        v-model="formExpense.total_forced_amount"
                                                        class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        placeholder="0.00" aria-describedby="price-currency" />
                                                    <div
                                                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                        <span class="text-gray-500 sm:text-sm"
                                                            id="price-currency">EUR</span>
                                                    </div>
                                                </div>

                                                <div class="relative">
                                                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                                                        <div class="w-full border-t border-gray-300" />
                                                    </div>
                                                    <div class="relative flex justify-start">
                                                        <span
                                                            class="bg-white pr-3 text-base font-semibold leading-6 text-gray-900">Repas</span>
                                                    </div>
                                                </div>

                                                <div class="col-span-full">
                                                    <label for="email"
                                                        class="block text-sm font-medium leading-6 text-gray-900">Nombre
                                                        de repas</label>
                                                    <div class="mt-2">
                                                        <input type="number" name="meals" id="meals" disabled
                                                            v-model="evening_meals" class="inputDisabled" />
                                                    </div>
                                                </div>

                                                <div class="col-span-full">
                                                    <label for="price"
                                                        class="block text-sm font-medium leading-6 text-gray-900">Montant
                                                        (plafond : {{ cap_amount }}€ / maximum :{{ totalAmountMeals
                                                        }}€)</label>
                                                    <div class="relative mt-2 rounded-md shadow-sm">
                                                        <input type="number" name="price" id="price"
                                                            v-model="formExpense.evening_meals" class="inputSimple"
                                                            aria-describedby="price-currency" />
                                                        <div
                                                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                            <span class="text-gray-500 sm:text-sm"
                                                                id="price-currency">EUR</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="relative">
                                                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                                                        <div class="w-full border-t border-gray-300" />
                                                    </div>
                                                    <div class="relative flex justify-start">
                                                        <span
                                                            class="bg-white pr-3 text-base font-semibold leading-6 text-gray-900">Hébergement,
                                                            hôtel</span>
                                                    </div>
                                                </div>
                                                <div class="col-span-full" v-if="!isFeesOpen">
                                                    <SwitchGroup as="div" class="flex items-center">
                                                        <Switch v-model="hostingFees" disabled
                                                            class="pointer-events-none"
                                                            :class="[hostingFees ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                                                            <span aria-hidden="true"
                                                                :class="[hostingFees ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                                                        </Switch>
                                                        <SwitchLabel as="span" class="ml-3 text-sm">
                                                            <span class="font-medium text-gray-900">{{ hostingFees ?
                                                                'Hébergement à prévoir' :
                                                                'Pas d\'hébergement à prévoir'
                                                                }}</span>
                                                        </SwitchLabel>
                                                    </SwitchGroup>
                                                </div>

                                                <div class="col-span-full" v-if="hostingFees">
                                                    <label for="otherFees"
                                                        class="block text-sm font-medium leading-6 text-gray-900">Hébergement</label>
                                                    <div class="relative mt-2 rounded-md shadow-sm">
                                                        <div
                                                            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                            <span class="text-gray-500 sm:text-sm">€</span>
                                                        </div>
                                                        <input type="number" name="accommodation" id="accommodation"
                                                            v-model="formExpense.accommodation_costs"
                                                            class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            placeholder="0.00" aria-describedby="price-currency" />
                                                        <div
                                                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                            <span class="text-gray-500 sm:text-sm"
                                                                id="price-currency">EUR</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="relative">
                                                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                                                        <div class="w-full border-t border-gray-300" />
                                                    </div>
                                                    <div class="relative flex justify-start">
                                                        <span
                                                            class="bg-white pr-3 text-base font-semibold leading-6 text-gray-900">Transport</span>
                                                    </div>
                                                </div>

                                                <div class="col-span-full" v-if="!isFeesOpen">
                                                    <SwitchGroup as="div" class="flex items-center" disabled>
                                                        <Switch v-model="tripFees" disabled class="pointer-events-none"
                                                            :class="[tripFees ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                                                            <span aria-hidden="true"
                                                                :class="[tripFees ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                                                        </Switch>
                                                        <SwitchLabel as="span" class="ml-3 text-sm">
                                                            <span class="font-medium text-gray-900">{{ tripFees ?
                                                                'Prise en compte des frais kilométriques' :
                                                                'Pas de prise en compte des frais kilométriques'
                                                                }}</span>
                                                        </SwitchLabel>
                                                    </SwitchGroup>
                                                </div>
                                                <div class="bg-indigo-50 rounded-md p-2" v-if="tripFees == true">
                                                    <div class="flex">
                                                        <div class="flex-shrink-0">
                                                        </div>
                                                        <div class="ml-3">
                                                            <h3 class="text-sm font-semibold text-indigo-800">
                                                                {{ training.number_of_round_trips }} trajets pris en
                                                                charge</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-span-full" v-if="tripFees == true"
                                                    style="margin-top:5px;">
                                                    <AlertMessage v-if="transportMessage" :title="transportMessage"
                                                        type="warning" />
                                                    <div v-else>
                                                        <dt class="text-sm font-medium text-gray-500 sm:flex-shrink-0">
                                                            Puissance fiscale déclarée</dt>
                                                        <dd class="mt-1 text-sm text-gray-900 sm:col-span-4">
                                                            <p>
                                                                {{ vehicleCategory?.category }} (indemnité par kilomètre
                                                                {{ indemnity }}EUR)</p>
                                                        </dd>
                                                    </div>

                                                </div>
                                                <div class="text-xs" v-if="tripFees & !transportMessage">
                                                    <div class="flex items-center mb-2">
                                                        <MapPinIcon class="w-4 h-4 mr-2" />{{ profile_address }}
                                                    </div>
                                                    <div class="flex items-center">
                                                        <MapPinIcon class="w-4 h-4 mr-2" />{{ training_address }}
                                                    </div>
                                                </div>
                                                <div class="col-span-full" v-if="tripFees & !transportMessage">
                                                    <label for="price"
                                                        class="block text-sm font-medium leading-6 text-gray-900">Distance</label>
                                                    <div class="relative mt-2 rounded-md shadow-sm">
                                                        <input type="number" name="distance" id="distance"
                                                            v-model="computedDistance" disabled="" class="inputDisabled"
                                                            placeholder="0.00" aria-describedby="price-currency" />
                                                        <div
                                                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                            <span class="text-gray-500 sm:text-sm"
                                                                id="price-currency">km</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-span-full" v-if="tripFees & !transportMessage">
                                                    <div>
                                                        <label for="computedTripFees"
                                                            class="block text-sm font-medium leading-6 text-gray-900">Frais
                                                            kilométriques calculés</label>
                                                        <div class="relative mt-2 rounded-md shadow-sm">
                                                            <div
                                                                class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                                <span class="text-gray-500 sm:text-sm">€</span>
                                                            </div>
                                                            <input type="number" name="computedTripFees"
                                                                v-model="computedTripFees" id="computedTripFees"
                                                                disabled="" class="inputDisabled  pl-7 "
                                                                placeholder="" />
                                                            <div
                                                                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                                <span class="text-gray-500 sm:text-sm"
                                                                    id="price-currency">EUR</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-span-full" v-if="tripFees & !transportMessage">
                                                    <label for="forcedTripFees"
                                                        class="block text-sm font-medium leading-6 text-gray-900">Forcer
                                                        le montant des frais kilométriques</label>
                                                    <div class="relative mt-2 rounded-md shadow-sm">
                                                        <div
                                                            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                            <span class="text-gray-500 sm:text-sm">€</span>
                                                        </div>
                                                        <input type="number" name="forcedTripFees" id="forcedTripFees"
                                                            v-model="formExpense.transport_costs"
                                                            class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            placeholder="0.00" aria-describedby="price-currency" />
                                                        <div
                                                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                            <span class="text-gray-500 sm:text-sm"
                                                                id="price-currency">EUR</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-span-full" v-if="tripFees == true">
                                                    <label for="otherFees"
                                                        class="block text-sm font-medium leading-6 text-gray-900">Péages,
                                                        parking, etc.</label>
                                                    <div class="relative mt-2 rounded-md shadow-sm">
                                                        <div
                                                            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                            <span class="text-gray-500 sm:text-sm">€</span>
                                                        </div>
                                                        <input type="number" name="toll_parking_costss"
                                                            id="toll_parking_costs"
                                                            v-model="formExpense.toll_parking_costs"
                                                            class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            placeholder="0.00" aria-describedby="price-currency" />
                                                        <div
                                                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                            <span class="text-gray-500 sm:text-sm"
                                                                id="price-currency">EUR</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-span-full">
                                                    <label for="otherFees"
                                                        class="block text-sm font-medium leading-6 text-gray-900">Transports
                                                        en commun</label>
                                                    <div class="relative mt-2 rounded-md shadow-sm">
                                                        <div
                                                            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                            <span class="text-gray-500 sm:text-sm">€</span>
                                                        </div>
                                                        <input type="number" name="publicTransports"
                                                            v-model="formExpense.public_transport_costs"
                                                            id="publicTransports"
                                                            class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            placeholder="0.00" aria-describedby="price-currency" />
                                                        <div
                                                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                            <span class="text-gray-500 sm:text-sm"
                                                                id="price-currency">EUR</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="relative">
                                                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                                                        <div class="w-full border-t border-gray-300" />
                                                    </div>
                                                    <div class="relative flex justify-start">
                                                        <span
                                                            class="bg-white pr-3 text-base font-semibold leading-6 text-gray-900">Autres</span>
                                                    </div>
                                                </div>

                                                <div class="col-span-full">
                                                    <label for="otherFees"
                                                        class="block text-sm font-medium leading-6 text-gray-900">Autres
                                                        remboursement</label>
                                                    <div class="relative mt-2 rounded-md shadow-sm">
                                                        <div
                                                            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                            <span class="text-gray-500 sm:text-sm">€</span>
                                                        </div>
                                                        <input type="number" name="otherFees" id="otherFees"
                                                            v-model="formExpense.other_costs"
                                                            class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            placeholder="0.00" aria-describedby="price-currency" />
                                                        <div
                                                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                            <span class="text-gray-500 sm:text-sm"
                                                                id="price-currency">EUR</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-span-full">
                                                    <label for="otherFees"
                                                        class="block text-sm font-medium leading-6 text-gray-900">Commentaire
                                                        (facultatif)</label>
                                                    <div class="relative mt-2 rounded-md shadow-sm">
                                                        <textarea type="text" name="other_costs_comment"
                                                            id="other_costs_comment"
                                                            v-model="formExpense.other_costs_comment"
                                                            class="block w-full rounded-md border-0 py-1.5 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            placeholder="Vous pouvez préciser un commentaire"
                                                            aria-describedby="price-currency" />
                                                    </div>
                                                </div>

                                                <div class="relative">
                                                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                                                        <div class="w-full border-t border-gray-300" />
                                                    </div>
                                                    <div class="relative flex justify-start">
                                                        <span
                                                            class="bg-white pr-3 text-base font-semibold leading-6 text-gray-900">Justificatifs</span>
                                                    </div>
                                                </div>
                                                <AlertMessage v-if="!formExpense.receipts"
                                                    title="aucun justificatif déposé pour le moment" type="warning" />
                                                <div class="mt-5">
                                                    <button class="btn-secondary" @click.prevent="addReceipt">Déposer
                                                        un justificatif</button>
                                                </div>
                                                <div class="col-span-full" v-if="formExpense.receipts">
                                                    <dd class="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                        <ul role="list"
                                                            class="divide-y divide-gray-100 rounded-md border border-gray-200">
                                                            <li v-for="userReceipt in formExpense.receipts"
                                                                :key="userReceipt.id"
                                                                class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                                                <div class="flex w-0 flex-1 items-center">
                                                                    <PaperClipIcon
                                                                        class="h-5 w-5 flex-shrink-0 text-gray-400"
                                                                        aria-hidden="true" />
                                                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">
                                                                        <span class="truncate font-medium">{{
                                                                            userReceipt.description }}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="ml-4 flex-shrink-0">
                                                                    <a :href="userReceipt.temporary_receipt_url"
                                                                        target="_blank"
                                                                        class="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer">Voir</a>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </dd>
                                                </div>
                                                <div class="mt-5 flex space-y-3 sm:space-x-3 sm:space-y-0">
                                                    <button class="btn-primary w-full items-center justify-center"
                                                        v-if="isNewExpense"
                                                        @click.prevent="postExpense(1)">Créer</button>

                                                    <button class="btn-secondary w-full items-center justify-center"
                                                        v-if="!isNewExpense && (formExpense.training_expense_status_id !== 2 && formExpense.training_expense_status_id !== 4)"
                                                        @click.prevent="putExpense(1)">Enregistrer</button>
                                                    <button type="button" @click.prevent="putExpense(2)"
                                                        v-if="!isNewExpense && (formExpense.training_expense_status_id !== 2 && formExpense.training_expense_status_id !== 4)"
                                                        class="btn-success inline-flex w-full items-center justify-center">À
                                                        payer</button>
                                                </div>
                                            </dl>
                                            <div v-else class="space-y-8 px-4 sm:space-y-6 sm:px-6">

                                                <div v-if="formExpense.training_expense_status_id === 4">

                                                    <dl class=" mt-2 divide-y divide-gray-200 border-b border-t
                                                    border-gray-200">

                                                        <div class="flex justify-between py-3 text-sm font-medium">
                                                            <dt class="text-gray-500">Régie</dt>
                                                            <dd class="text-gray-900">
                                                                {{
                                                                    formExpense.training_expense_payments[0].payment_order_id
                                                                }}
                                                            </dd>
                                                        </div>
                                                        <div class="flex justify-between py-3 text-sm font-medium">
                                                            <dt class="text-gray-500">Mode de paiement</dt>
                                                            <dd class="text-gray-900">{{
                                                                formExpense.training_expense_payments[0].payment_method
                                                                }}</dd>
                                                        </div>
                                                        <div class="flex justify-between py-3 text-sm font-medium">
                                                            <dt class="text-gray-500">Référence du paiment</dt>
                                                            <dd class="text-gray-900">{{
                                                                formExpense.training_expense_payments[0]?.payment_reference
                                                            }}</dd>
                                                        </div>
                                                    </dl>
                                                </div>
                                                <div>
                                                    <ExpenseReadDetail :expense="formExpense" :openInfos="false" />
                                                </div>
                                                <!-- for admin to made payment -->
                                                <ExpensePayment :expense="formExpense" @confirm="paymentResponse"
                                                    v-if="formExpense.training_expense_status_id === 2 && profile.role?.slug === 'administrateur' && training.training_status_id !== 7" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, defineProps } from 'vue';
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue';
import {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue';
import { XMarkIcon, MapPinIcon } from '@heroicons/vue/24/outline';
import { PaperClipIcon } from '@heroicons/vue/24/solid';
// import { MapPinIcon } from '@heroicons/vue/20/solid';
import { useRoute, useRouter } from 'vue-router';
import fetchExpensesServices from '@/services/ExpensesServices.js';
import fetchPersonalInfoServices from '@/services/PersonalInfoServices.js';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import client from '@/api/client';
import AlertMessage from '@/components/alerts/AlertMessage.vue';
import ExpenseUpload from '@/components/expenses/ExpenseUpload.vue';
import ExpenseReadDetail from '@/components/expenses/ExpenseReadDetail.vue';
import ExpensePayment from '@/components/expenses/ExpensePayment.vue';

const route = useRoute();
const router = useRouter();
const emit = defineEmits(['confirm', 'close']);

const open = ref(true);
const tripFees = ref(false);
const hostingFees = ref(false);
const otherFees = ref(false);
const evening_meals = ref(null);
const number_of_round_trips = ref(null);
const totalAmountMeals = ref(null);
const relevantVehicleCategories = ref([]);
const vehicleCategory = ref(null);
const computedTripFees = ref(null);
const isNewExpense = ref(false);
const displayExpenseUpload = ref(false);
const expenseId = ref(null);
const componentKey = ref(0);
const cap_amount = ref(null);
const computedDistance = ref(null);
const indemnity = ref(null);
let edit = ref(null);
const transportMessage = ref(null);

const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const formExpense = ref([]);
const profile = ref(null);
const expenseStatus = ref([]);
const profile_address = ref(null);
const training_address = ref(null);
const isFeesOpen = ref(false);

let props = defineProps({
    expense: [Array, Object],
    expensesStatus: Array,
    training: [Array, Object],
    idExpense: [Number, String],
    idProfile: [Number, String],
    username: String,
    role: String,
})

function statusText(status) {
    return props.expensesStatus[status]?.text || 'Inconnu';
}

function statusClass(status) {
    const statusId = props.expensesStatus.findIndex((item) => item.name === status);
    return `expenseStatus expenseStatus${statusId + 1}`;
}

const calculateMealsAmount = () => {
    fetchExpensesServices.calculateMealsCostForProfile(props.training.id, props.idProfile).then((data) => {
        cap_amount.value = data.cap_amount;
        totalAmountMeals.value = data.evening_meals_cost;
    });
}

const postExpense = async (status, justificatif) => {
    let transportFees = null;

    if (formExpense.value.transport_costs || formExpense.value.transport_costs === 0) {
        transportFees = formExpense.value.transport_costs;
    } else {
        transportFees = computedTripFees.value;
    }
    if (!transportFees) {
        transportFees = 0;
    }
    try {
        const response = await client.post(`api/training-expenses`, {
            training_id: props.training.id,
            profile_id: props.idProfile,
            vehicle_category_id: formExpense.value.vehicle_category_id,
            evening_meals: formExpense.value.evening_meals,
            transport_costs: transportFees,
            accommodation_costs: formExpense.value.accommodation_costs,
            other_costs: formExpense.value.other_costs,
            training_expense_status_id: status,
            toll_parking_costs: formExpense.value.toll_parking_costs,
            public_transport_costs: formExpense.value.public_transport_costs,
            other_costs_comment: formExpense.value.other_costs_comment,
            total_forced_amount: formExpense.value.total_forced_amount,
            calculated_distance: computedDistance.value,
        });
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été créé';
        notificationMessage.value = '';
        fetchExpensesServices.fetchTrainingExpense(response.data.id).then((data) => {
            formExpense.value = data;
        });
        isNewExpense.value = false;
        expenseId.value = response.data.id;
        if (justificatif) {
            displayExpenseUpload.value = true;
            expenseId.value = response.data.id;
        }
        emit('confirm');
    } catch (error) {
        notificationType.value = 'error';
        setTimeout(() => { showNotification.value = false; }, 3000);
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement';
        if (error.response) {
            notificationMessage.value = error.response.data.message;
        }
    }
}
const putExpense = async (status) => {
    let transportFees = null;
    if (formExpense.value.transport_costs !== '') {
        transportFees = formExpense.value.transport_costs;
    } else {
        transportFees = computedTripFees.value;
        console.log('transportFees', transportFees);
    }
    try {
        const response = await client.put(`api/training-expenses/${expenseId.value}`, {
            training_id: props.training.id,
            profile_id: formExpense.value.profile_id,
            vehicle_category_id: formExpense.value.vehicle_category_id,
            evening_meals: formExpense.value.evening_meals,
            transport_costs: transportFees,
            accommodation_costs: formExpense.value.accommodation_costs,
            other_costs: formExpense.value.other_costs,
            training_expense_status_id: status,
            toll_parking_costs: formExpense.value.toll_parking_costs,
            public_transport_costs: formExpense.value.public_transport_costs,
            other_costs_comment: formExpense.value.other_costs_comment,
            total_forced_amount: formExpense.value.total_forced_amount,
            calculated_distance: computedDistance.value,
        });
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié';
        notificationMessage.value = '';
        formExpense.value = response.data;
        if (status === 2 && profile.value.role?.slug !== 'administrateur') {
            emit('confirm');
            emit('close');
        } else {
            emit('confirm');
            edit.value = true;
        }
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationTitle.value = 'Erreur lors de l\'enregistrement de la dépense';
        if (error.response) {
            notificationMessage.value = error.response.data.message;
        }
    }
}
const checkIfTrue = (value) => {
    return value ? true : false;
}
const addReceipt = () => {
    if (expenseId.value === null) {
        postExpense(1, true);
    } else {
        displayExpenseUpload.value = true;
    }
}
const calculateTripFees = async () => {
    try {
        const response = await client.patch(`/api/trainings-expenses/${props.training.id}/profiles/${props.idProfile}/calculate-transport-cost`);
        computedTripFees.value = response.data.transport_cost.toFixed(2);
        computedDistance.value = response.data.calculated_distance;
        profile_address.value = response.data.profile_address;
        training_address.value = response.data.training_address;
        indemnity.value = response.data.indemnity;
        fetchPersonalInfoServices.fetchVehicleCategories().then((data) => {
            relevantVehicleCategories.value = data;
            vehicleCategory.value = relevantVehicleCategories.value.find(category => category.id === response.data.vehicle_category_id);
        });

    } catch (error) {
        transportMessage.value = error.response.data.message;
    }

}
const closeExpenseUpload = () => {
    displayExpenseUpload.value = false;
    componentKey.value += 1;
    open.value = true;
};

const receiptResponse = (type, response) => {
    displayExpenseUpload.value = false;
    open.value = true;
    if (type === 'success') {
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, le justificatif a bien été ajouté';
        notificationMessage.value = '';
        fetchExpensesServices.fetchTrainingExpense(expenseId.value).then((data) => {
            formExpense.value = data;
        });
    } else {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement';
        notificationMessage.value = response;
    }
}

const paymentResponse = (type, response) => {
    if (type === 'success') {
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, la mise en paiement a bien été effectuée';
        notificationMessage.value = '';
        fetchExpensesServices.fetchTrainingExpense(expenseId.value).then((data) => {
            formExpense.value = data;
        });
    } else {
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement';
        notificationMessage.value = response;
    }
}

const fetchExpense = async () => {
    if (!isFeesOpen.value) {
        tripFees.value = checkIfTrue(props.training.transportation_fees);
        hostingFees.value = checkIfTrue(props.training.accommodation);
    } else {
        tripFees.value = true;
        hostingFees.value = true;
    }
    evening_meals.value = props.training.dinners;
    number_of_round_trips.value = props.training.number_of_round_trips;
}

const editExpense = () => {
    edit.value = true;
    fetchExpense();
}

onMounted(() => {
    if (props.role === 'Stagiaire') {
        isFeesOpen.value = false;
    } else {
        isFeesOpen.value = true;
    }
    profile.value = JSON.parse(localStorage.getItem('user'));
    if (props.idExpense) {
        expenseId.value = props.idExpense;
        fetchExpense();
        fetchExpensesServices.fetchTrainingExpense(expenseId.value).then((data) => {
            formExpense.value = data;
            expenseStatus.value = data.status;
            if ((formExpense.value.training_expense_status_id === 2) || formExpense.value.training_expense_status_id === 4) {
                edit.value = false;
            } else if (props.training.training_status_id === 7) {
                edit.value = false;
            } else {
                edit.value = true;
                calculateMealsAmount();
                calculateTripFees();
            }
        });
    } else {
        if (props.training.training_status_id === 7) {
            edit.value = false;
        } else {
            isNewExpense.value = true;
            edit.value = true;
            fetchExpense();
            calculateMealsAmount();
            calculateTripFees();
        }
    }
});
</script>