<template>
    <div style="z-index:2000; position:absolute">
        <TeacherNeedReimbursed v-if="displayTeacherModal" :title="'Vérifier les modalités de défraiements'"
            :message="'Voulez-vous vérifier les modalités de défraiements de ce formateur ?'"
            :primaryButtonLabel="'Enregistrer'" :secondaryButtonLabel="'Annuler'" :teacher="selectedPerson"
            :type="typeTeacher" @confirm="saveTeachers" @close="displayTeacherModal = false" :key="reimburseKey"
            :trainingId="trainingId" @error="displayError" />
    </div>
    <TeacherHours v-if="openHoursModal && selectedTeacher" :editStatus="true" @close="closeHoursModal"
        @savingHours="savedHours" :idTraining="trainingId" :training="training" :teacher="selectedTeacher"
        :type="typeTeacher" :key="componentKey" />
    <TrainingSpeakers v-if="speakersModalOpen" @close="speakersModalOpen = false" @confirm="openModalReimbursment"
        :idTraining="trainingId" :type="typeTeacher" />
    <AlertMessage v-if="displayAlert" :type="alertType" :title="alertTitle" class="mt-12" :content="alertMessage"
        :key="alertKey" />
    <div class="">
        <div class="flex items-center">
            <h2 class="text-indigo-700 mt-10 font-semibold mb-2">Responsables pédagogiques</h2>
            <div class="flex items-center space-x-3 mt-10 ml-auto" v-if="relatedSupervisors.length < 1">
                <button type="button" @click.prevent="addTeamMember('responsable-pedagogique')"
                    class="rounded-full bg-indigo-600 p-2 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    <PlusIcon class="h-5 w-5" aria-hidden="true" />
                </button>
                <label for="searchSpeaker" class="text-sm font-medium leading-6 text-gray-900">Ajoutez un responsable
                    pédgogique
                </label>
            </div>
        </div>
        <AlertMessage v-if="relatedSupervisors.length < 1" :type="'info'"
            :title="'Aucun responsable pédagogique associé'" class="mt-12" />
        <div class="mt-8 flow-root" v-if="relatedSupervisors.length">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table class="min-w-full divide-y divide-gray-300">
                        <thead>
                            <tr>
                                <th scope="col"
                                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">Nom
                                </th>
                                <th scope="col"
                                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                                    Besoins
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">CM
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">TD
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">HETD
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">CM
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">TD
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">HETD
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Statut
                                    ARES
                                </th>
                                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-3">
                                    <span class="sr-only">Saisie des heures</span>
                                </th>
                                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-3">
                                    <span class="sr-only">Détail</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white">
                            <tr v-for="teacher in relatedSupervisors" :key="teacher.name" class="even:bg-gray-50">
                                <td class="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 max-w-96">
                                    <div class="font-medium text-gray-900">{{ teacher.user?.name }}</div>
                                    <div class="font-medium text-gray-900">{{ teacher.user?.email }}</div>
                                    <div class="mt-1 truncate text-gray-500">{{ teacher.phone }}</div>
                                </td>
                                <td class="max-w-32"> <span class="status status1 !mx-0"
                                        v-if="teacher.pivot?.needs_accommodation">herbergement</span> <span
                                        class="status status1"
                                        v-if="teacher.pivot?.reimbursed_transportation">transport</span></td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"> <span
                                        class="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">{{
                                            teacher.pivot?.expected_lecture_hours }}</span></td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"> <span
                                        class="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">{{
                                            teacher.pivot?.expected_tutorial_hours }}</span></td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"> <span
                                        class="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">{{
                                            hetd(teacher.pivot?.expected_lecture_hours,
                                                teacher.pivot?.expected_tutorial_hours)
                                        }}</span></td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"> <span
                                        class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                        {{ teacher.pivot?.actual_lecture_hours }}
                                    </span></td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"> <span
                                        class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                        {{ teacher.pivot?.actual_tutorial_hours }}
                                    </span></td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"> <span
                                        class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                        {{
                                            hetd(teacher.pivot?.actual_lecture_hours, teacher.pivot?.actual_tutorial_hours)
                                        }}
                                    </span></td>
                                <td v-if="teacher.pivot?.ares_status_id">
                                    <div class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                        v-if="teacher.training_teacher_ares_status"><span
                                            :class="'aresStatus aresStatus' + teacher.pivot?.ares_status_id">
                                            {{ teacher.training_teacher_ares_status[0]?.name }}</span></div>
                                    <div class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                        v-if="teacher.training_pedagogical_supervisor_ares_status"> <span
                                            :class="'aresStatus aresStatus' + teacher.pivot?.ares_status_id">
                                            {{ teacher.training_pedagogical_supervisor_ares_status[0]?.name }}
                                        </span></div>
                                </td>
                                <td v-else class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    <span class="aresStatus aresStatus0">
                                        Non renseigné</span>
                                </td>
                                <td
                                    class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                                    <a href="#" @click.prevent="enterHours(teacher, 'responsable-pedagogique')"
                                        class="text-indigo-600 hover:text-indigo-900">Saisir les
                                        heures<span class="sr-only">, {{ teacher.user?.name }}</span></a>
                                </td>
                                <td
                                    class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                                    <a :href="'/formateurs/' + teacher.id"
                                        class="text-indigo-600 hover:text-indigo-900">Détail<span class="sr-only">, {{
                                            teacher.user?.name }}</span></a>
                                </td>
                                <td
                                    class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                                    <button class="text-red-600 hover:text-red-900"
                                        @click.prevent="displayConfirmDeleteDialog = true; idTypeToEdit = teacher.id; roleToDelete = 'responsable-pedagogique'">
                                        <XCircleIcon class="-ml-0.5 h-10 w-10" aria-hidden="true" /><span
                                            class="sr-only">, {{ teacher.user?.name }}</span>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>

        <div class="flex items-center">
            <h2 class="text-indigo-700 mt-10 font-semibold mb-2">Intervenants</h2>
            <div class="flex items-center space-x-3 mt-10 ml-auto">
                <button type="button" @click.prevent="addTeamMember('intervenant')"
                    class="rounded-full bg-indigo-600 p-2 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    <PlusIcon class="h-5 w-5" aria-hidden="true" />
                </button>
                <label for="searchSpeaker" class="text-sm font-medium leading-6 text-gray-900">Ajoutez un intervenant
                </label>
            </div>
        </div>
        <div class="mt-8 flow-root" v-if="relatedTeachers.length > 0">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table class="min-w-full divide-y divide-gray-300">
                        <thead>
                            <tr>
                                <th scope="col"
                                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">Nom
                                </th>
                                <th scope="col"
                                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                                    Besoins
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">CM
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">TD
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">HETD
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">CM
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">TD
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">HETD
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Statut
                                    ARES
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Rémunéré
                                </th>
                                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-3">
                                    <span class="sr-only">Saisie des heures</span>
                                </th>
                                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-3">
                                    <span class="sr-only">Détail</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white">
                            <tr v-for="teacher in relatedTeachers" :key="teacher.name" class="even:bg-gray-50">
                                <td class="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 max-w-96">
                                    <div class="font-medium text-gray-900">{{ teacher.user?.name }}</div>
                                    <div class="font-medium text-gray-900">{{ teacher.user?.email }}</div>
                                    <div class="mt-1 truncate text-gray-500">{{ teacher.phone }}</div>
                                    <span
                                        class="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10"
                                        v-if="teacher.role?.name === 'Intervenant'">
                                        {{ teacher.role?.name }}</span>
                                    <span v-else
                                        class="mt-2inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10">
                                        {{ teacher.role?.name }}</span>
                                </td>
                                <td class="max-w-32"> <span class="status status1 !mx-0"
                                        v-if="teacher.pivot?.needs_accommodation">herbergement</span> <span
                                        class="status status1"
                                        v-if="teacher.pivot?.reimbursed_transportation">transport</span></td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"> <span
                                        class="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">{{
                                            teacher.pivot?.expected_lecture_hours }}</span></td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"> <span
                                        class="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">{{
                                            teacher.pivot?.expected_tutorial_hours }}</span></td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"> <span
                                        class="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">{{
                                            hetd(teacher.pivot?.expected_lecture_hours,
                                                teacher.pivot?.expected_tutorial_hours)
                                        }}</span></td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"> <span
                                        class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                        {{ teacher.pivot?.actual_lecture_hours }}
                                    </span></td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"> <span
                                        class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                        {{ teacher.pivot?.actual_tutorial_hours }}
                                    </span></td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"> <span
                                        class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                        {{
                                            hetd(teacher.pivot?.actual_lecture_hours, teacher.pivot?.actual_tutorial_hours)
                                        }}
                                    </span></td>
                                <td v-if="teacher.pivot?.ares_status_id">
                                    <div class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                        v-if="teacher.training_teacher_ares_status"><span
                                            :class="'aresStatus aresStatus' + teacher.pivot?.ares_status_id">
                                            {{ teacher.training_teacher_ares_status[0]?.name }}</span></div>
                                    <div class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                        v-if="teacher.training_pedagogical_supervisor_ares_status"> <span
                                            :class="'aresStatus aresStatus' + teacher.pivot?.ares_status_id">
                                            {{ teacher.training_pedagogical_supervisor_ares_status[0]?.name }}
                                        </span></div>
                                </td>
                                <td v-else class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    <span class="aresStatus aresStatus0">
                                        Non renseigné</span>
                                </td>
                                <td><span class="status status5"
                                        v-if="teacher.pivot?.is_remunerated === 1">rémunéré</span>
                                    <span class="status status1" v-if="teacher.pivot?.is_remunerated === 0">non
                                        rémunéré</span>
                                </td>
                                <td
                                    class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                                    <a href="#" @click.prevent="enterHours(teacher, 'intervenant')"
                                        class="text-indigo-600 hover:text-indigo-900">Saisir les
                                        heures<span class="sr-only">, {{ teacher.user?.name }}</span></a>
                                </td>
                                <td
                                    class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                                    <a :href="'/formateurs/' + teacher.id"
                                        class="text-indigo-600 hover:text-indigo-900">Détail<span class="sr-only">, {{
                                            teacher.user?.name }}</span></a>
                                </td>
                                <td
                                    class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                                    <button class="text-red-600 hover:text-red-900"
                                        @click.prevent="displayConfirmDeleteDialog = true; idTypeToEdit = teacher.id; roleToDelete = 'intervenant'">
                                        <XCircleIcon class="-ml-0.5 h-10 w-10" aria-hidden="true" /><span
                                            class="sr-only">, {{ teacher.user?.name }}</span>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <AlertMessage v-if="relatedTeachers.length < 1" :type="'info'" :title="'Aucun intervenant associé'"
            class="mt-12" />
    </div>
    <ConfirmationDialog @confirm="deleteItem(idTypeToEdit, roleToDelete)" v-if="displayConfirmDeleteDialog"
        :title="confirmTexts[0].title" :message="confirmTexts[0].message"
        :primaryActionLabel="confirmTexts[0].primaryActionLabel"
        :secondaryActionLabel="confirmTexts[0].secondaryActionLabel" @close="displayConfirmDeleteDialog = false" />
    <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
        :detail="notificationMessage" @close="showNotification = false" />
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, computed } from 'vue';
import TeacherHours from '@/components/teachers/TeacherHours.vue';
import TrainingSpeakers from '@/components/trainings/TrainingSpeakers.vue';
import { useRoute } from 'vue-router';
import client from '@/api/client';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import { XCircleIcon, PlusIcon } from '@heroicons/vue/20/solid';
import AlertMessage from '@/components/alerts/AlertMessage.vue';
import TeacherNeedReimbursed from '@/components/teachers/TeacherNeedsReimbursed.vue';

const props = defineProps({
    teachers: Object,
    supervisors: Object,
    idTraining: [Number, String],
    training: Object,
});
const route = useRoute();
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const idTypeToEdit = ref(null);
const roleToDelete = ref(null);
const displayConfirmDeleteDialog = ref(false);

const alertType = ref('');
const alertTitle = ref('');
const alertMessage = ref('');
const displayAlert = ref(false);

const selectedTeacher = ref(null);
const speakersModalOpen = ref(false);
const openHoursModal = ref(false);
const trainingId = route.params.id;
let daysRemaining = 0;
let daysAfter = 0;
const displayTeacherModal = ref(false);
const selectedPerson = ref(null);
const reimburseKey = ref(0);
const typeTeacher = ref('');
const hoursVerified = ref([]);
const expectedVerified = ref(false);
const actualVerified = ref(false);

const confirmTexts = [
    {
        title: 'Supprimer cet intervenant ?',
        message: 'Voulez-vous supprimer cet intervenant ?',
        primaryActionLabel: 'supprimer',
        secondaryActionLabel: 'annuler'
    },
]
const componentKey = ref(0);
const alertKey = ref(0);
const hetd = ((cm, td) => {
    let x = (cm * 1.5) + td;
    let res = x.toFixed(2);
    return res;
});

const enterHours = (person, type) => {
    openHoursModal.value = true;
    selectedTeacher.value = person;
    typeTeacher.value = type;
    componentKey.value += 1;
};

const closeHoursModal = () => {
    openHoursModal.value = false;
};

const addTeamMember = (type) => {
    speakersModalOpen.value = true;
    typeTeacher.value = type;
    componentKey.value += 1;
};
const savedHours = () => {
    openHoursModal.value = false;
    hoursVerified.value = [];
    fetchTeachers();
};

const saveTeachers = () => {
    speakersModalOpen.value = false;
    displayTeacherModal.value = false;
    selectedPerson.value = null;
    fetchTeachers();
};

const relatedTeachers = ref([]);
const relatedSupervisors = ref([]);

const toggleSpeakersModal = () => {
    speakersModalOpen.value = !speakersModalOpen.value;
};

const fetchTeachers = async () => {
    const response = await client.get(`api/trainings/${route.params.id}/staff?type=teachers`)
    relatedTeachers.value = response.data;
    checkConditions('intervenant');
    fetchSupervisors();
};

const fetchSupervisors = async () => {
    const response = await client.get(`api/trainings/${route.params.id}/staff?type=pedagogical_supervisors`)
    relatedSupervisors.value = response.data;
    checkConditions('responsable');
};

const deleteItem = async (itemToDelete, type) => {
    let urlws = '';
    if (type === 'intervenant') {
        urlws = `api/trainings/${route.params.id}/teachers/${itemToDelete}`;
    } else if (type === 'responsable-pedagogique') {
        urlws = `api/trainings/${route.params.id}/pedagogical-supervisors/${itemToDelete}`;
    }
    displayConfirmDeleteDialog.value = false;
    try {
        const response = await client.delete(`${urlws}`);
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié. ';
        notificationTitle.value += response.data.message;
        notificationMessage.value = '';
        if (type === 'intervenant') {
            fetchTeachers();
        } else {
            fetchSupervisors();
        }
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de l\'item';
        if (error.response) {
            notificationMessage.value = error.response.data.message;
        }
    }
};

const checkConditions = (type) => {
    const relatedHours = [];
    let teachers = [];
    if (type === 'intervenant') {
        teachers = relatedTeachers.value;
    } else {
        teachers = relatedSupervisors.value;
    }
    teachers.map(entry => {
        const lectureHours = entry.pivot.expected_lecture_hours;
        const tutorialHours = entry.pivot.expected_tutorial_hours;
        const actualLectureHours = entry.pivot.actual_lecture_hours;
        const actualTutorialHours = entry.pivot.actual_tutorial_hours;

        const expectedVerified =
            (lectureHours !== null && tutorialHours !== null) &&
            (lectureHours > 0 || tutorialHours > 0);

        const actualVerified =
            (actualLectureHours !== null && actualTutorialHours !== null) &&
            (actualLectureHours > 0 || actualTutorialHours > 0);

        relatedHours.push({
            id: entry.id,
            expectedVerified: expectedVerified,
            actualVerified: actualVerified,
        });

    });
    const allExpectedConditionsVerified = relatedHours.every(entry => entry.expectedVerified);
    const allActualConditionsVerified = relatedHours.every(entry => entry.actualVerified);
    hoursVerified.value.push({
        type: type,
        expected: allExpectedConditionsVerified,
        actual: allActualConditionsVerified,
    });
    if (hoursVerified.value.length === 2) {
        expectedVerified.value = hoursVerified.value.every(entry => entry.expected);
        actualVerified.value = hoursVerified.value.every(entry => entry.actual);

        checkTimeConditions();
    }
};

const openModalReimbursment = (person, type) => {
    typeTeacher.value = type;
    displayTeacherModal.value = true;
    speakersModalOpen.value = false;
    selectedPerson.value = person;
    reimburseKey.value += 1;
};

const checkTimeConditions = () => {
    daysRemaining = Math.ceil((new Date(props.training?.start_date) - new Date()) / (1000 * 60 * 60 * 24));
    daysAfter = Math.ceil((new Date() - new Date(props.training?.end_date)) / (1000 * 60 * 60 * 24));

    if (daysRemaining > 90) {
        displayAlert.value = true;
        if (expectedVerified.value) {
            alertType.value = 'success';
            alertTitle.value = 'Les heures prévisionnelles ont été saisies';
        } else {
            alertType.value = 'info';
            alertTitle.value = 'Il est temps de saisir les heures prévisionnelles';
        }
        alertMessage.value = `Il reste ${daysRemaining} jours avant le début de la formation`;
    } else if (daysRemaining < 90 && daysRemaining > 70) {
        displayAlert.value = true;
        alertType.value = 'info';
        if (expectedVerified.value) {
            alertType.value = 'success';
            alertTitle.value = 'Les heures prévisionnelles ont été saisies';
        } else {
            alertType.value = 'warning';
            alertTitle.value = 'Il est temps de saisir les heures prévisionnelles';
        }
        alertMessage.value = `Il reste ${daysRemaining} jours avant le début de la formation`;
    } else if (daysRemaining > 0 && daysRemaining <= 30) {
        displayAlert.value = true;
        if (expectedVerified.value) {
            alertType.value = 'success';
            alertTitle.value = 'Les heures prévisionnelles ont été saisies';
        } else {
            alertType.value = 'error';
            alertTitle.value = 'Attention, certaines heures prévisionnelles n\'ont pas été saisies';
        }
        alertMessage.value = `Il reste ${daysRemaining} jours avant le début de la formation`;
    } else if (daysRemaining < 0 && daysAfter <= 10) {
        displayAlert.value = true;
        // alertType.value = 'info';
        if (actualVerified.value) {
            alertType.value = 'warning';
            alertType.value = 'success';
            alertTitle.value = 'Les heures réalisées ont été saisies';
        } else {
            alertTitle.value = 'Les heures réalisées doivent étre saisies';
        }
        alertMessage.value = `La formation est finie depuis ${daysAfter} jours.`;
    } else if (daysAfter > 10) {
        displayAlert.value = true;
        // alertType.value = 'warning';
        if (actualVerified.value) {
            alertType.value = 'success';
            alertTitle.value = 'Les heures réalisées ont été saisies';
        } else {
            alertType.value = 'error';
            alertTitle.value = 'Attention, les heures réalisées doivent étre saisies';
        }
        alertMessage.value = `La formation est finie depuis ${daysAfter} jours.`;
    }
    alertKey.value += 1;
};

const displayError = (message) => {
    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Erreur lors de l\'enregistrement de l\'item';
    notificationMessage.value = message;
};

onMounted(() => {
    fetchTeachers();
});
</script>
