<template>
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    :detail="notificationMessage" @close="showNotification = false" />

  <BigLoader v-if="isLoading" :title="loaderTitle" :text="'Préparation en cours de votre document'" />
  <AppLayout>
    <!-- Exports -->
    <section class="mb-10">
      <h3 class="text-base font-semibold leading-6 text-gray-900">Exports</h3>
      <div class="mt-10 flex gap-4">
        <button @click.prevent="exportData('cph')" type="button"
          class="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
          <CloudArrowDownIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
          CPH (xls)
        </button>
        <button @click.prevent="exportData('personnel')" type="button"
          class="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
          <CloudArrowDownIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
          Représentants des personnels (xls)
        </button>
        <button @click.prevent="exportData('newsletter')" type="button"
          class="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
          <CloudArrowDownIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
          Newsletters (xls)
        </button>
        <button @click.prevent="exportData('unions')" type="button"
          class="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
          <CloudArrowDownIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
          Bilan des formations syndicales (xls)
        </button>
      </div>
    </section>

    <DashboardStats />
    <section class="mt-20">
      <StatsByType />
    </section>

    <section class="mt-20">
      <PercentageTrainingStats />
    </section>
  </AppLayout>
</template>

<script setup>
import { ref } from 'vue';
import { CloudArrowDownIcon } from '@heroicons/vue/20/solid';
import AppLayout from '@/layouts/AppLayout.vue';
import client from '@/api/client';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import moment from 'moment';
import 'moment/locale/fr';
import BigLoader from '@/components/app/BigLoader.vue';
import DashboardStats from '@/components/dashboards/DashboardStats.vue';
import StatsByType from '@/components/stats/StatsByType.vue';
import PercentageTrainingStats from '@/components/stats/PercentageTrainingStats.vue';

moment.locale('fr');
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const isLoading = ref(false);
const loaderTitle = ref('');

const exportData = async (type) => {
  const startDate = moment(new Date()).format('YYYY-MM-DD');
  let urlws = '';
  let fileName = '';
  if (type === 'cph') {
    urlws = '/api/export/export-cph-trainings';
    fileName = `export-cph-${startDate}.xls`;
  } else if (type === 'labor') {
    urlws = '/api/export/labor-tribunal-trainings';
    fileName = `export-cph-${startDate}.pdf`;
  } else if (type === 'newsletter') {
    urlws = '/api/export-newsletter-subscribers';
    fileName = `communication-stagiaire-${startDate}.xls`;
  } else if (type === 'personnel') {
    urlws = '/api/export-personnel-representatives';
    fileName = `representants-personnels-${startDate}.xls`;
  } else if (type === 'unions') {
    urlws = '/api/export-union-trainings';
    fileName = `bilan-formations-syndicales-${startDate}.xls`;
  }
  try {
    const response = await client.get(`${urlws}`, {
      responseType: 'blob',
    });

    if (response.status === 200) {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `${fileName}`);
      document.body.appendChild(fileLink);
      fileLink.click();
      isLoading.value = false;
    }
  } catch (error) {
    isLoading.value = false;

    if (error.response && error.response.data instanceof Blob) {
      const reader = new FileReader();
      reader.onload = () => {
        const errorData = JSON.parse(reader.result);
        notificationMessage.value = errorData.error || "Une erreur s'est produite lors de la génération du document.";
      };
      reader.readAsText(error.response.data);
    } else {
      notificationMessage.value = error.message || "Une erreur s'est produite lors de la génération du document.";
    }

    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Erreur';
  }
};
</script>
