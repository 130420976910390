<template>
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    :detail="notificationMessage" @close="showNotification = false" />
  <TrainingSendEmails v-if="displayModalEmail" @close="displayModalEmail = false" :trainee="selectedTrainee"
    :training="training" />

  <BigLoader v-if="isLoading" :title="loaderTitle" :text="'Préparation en cours de votre document'" />
  <div class="mt-12">
    <button class="btn-primary" @click.prevent="sendEmails">Envoyer un courriel</button>
    <div class="mt-6 flex" v-if="profile?.role?.slug === 'administrateur' || profile?.role?.slug === 'gestionnaire'">
      <Listbox as="div" v-model="selectedStatus" @update:model-value="filterStatus(selectedStatus)" class="grow">
        <ListboxLabel class="block text-sm font-medium leading-6 text-gray-900">Filtrer par statut</ListboxLabel>
        <div class="relative mt-2">
          <ListboxButton
            class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
            <span class="flex items-center" v-if="selectedStatus">
              <span :class="statusClasses[selectedStatus.name]"
                class="inline-block h-2 w-2 flex-shrink-0 rounded-full"></span>
              <span class="ml-3 block truncate" v-if="selectedStatus.name">{{ selectedStatus.name }}</span>
            </span>
            <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </ListboxButton>
          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
            leave-to-class="opacity-0">
            <ListboxOptions
              class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              <ListboxOption v-for="status in relevantStatuses" :key="status" :value="status"
                v-slot="{ active, selected }">
                <li
                  :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                  <div class="flex items-center">
                    <span :class="statusClasses[status.name]" class="inline-block h-2 w-2 flex-shrink-0 rounded-full"
                      aria-hidden="true"></span>
                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">{{ status.name
                      }}</span>
                  </div>
                  <span v-if="selected"
                    :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                </li>
              </ListboxOption>

            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
    </div>
    <div class="mt-8 flow-root">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table class="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th>
                  <div class="flex h-6 items-center">
                    <input id="all" aria-describedby="tous les stagiaires sélectionnés" name="comments" type="checkbox"
                      @input="selectAll"
                      class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                  </div>
                </th>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                  Nom
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Actions
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">

                </th>
                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                  <span class="sr-only">Détails</span>
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
              <tr v-for="(person, index) in traineesList" :key="person.email">
                <td>
                  <div class="flex h-6 items-center">
                    <input :id="'trainee-' + index" aria-describedby="comments-description" name="comments"
                      type="checkbox" :checked="selectedTrainees.includes(person)" @input="selectTrainee(person)"
                      class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                  </div>
                </td>
                <td class="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                  <div class="flex items-center">
                    <div class="h-11 w-11 flex-shrink-0" v-if="person.temporary_profile_picture_url">
                      <img class="h-11 w-11 rounded-full object-cover" :src="person.temporary_profile_picture_url"
                        alt="" />
                    </div>
                    <div v-else>
                      <UserCircleIcon class="h-11 w-11 text-gray-400" />
                    </div>
                    <div class="ml-4">
                      <div class="font-medium text-gray-900">
                        {{ person.user?.name }}
                      </div>
                      <div class="mt-1 text-gray-500">{{ person.user?.email }}</div>
                      <div class="mt-2">
                        <button v-if="person.pivot?.registration_status_id === 2"
                          class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">Validé</button>
                        <button v-if="person.pivot?.registration_status_id === 3"
                          class="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">Refusé</button>
                        <button v-if="person.pivot?.registration_status_id === 1"
                          class="inline-flex items-center gap-x-1.5 rounded-md bg-orange-100 px-2 py-1 text-xs font-medium text-orange-700 cursor-pointer">
                          <svg class="h-1.5 w-1.5 fill-orange-500" viewBox="0 0 6 6" aria-hidden="true">
                            <circle cx="3" cy="3" r="3" />
                          </svg>Préinscription</button>
                        <button v-if="person.pivot?.registration_status_id === 4"
                          class="inline-flex items-center gap-x-1.5 rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700 cursor-pointer">
                          <svg class="h-1.5 w-1.5 fill-blue-500" viewBox="0 0 6 6" aria-hidden="true">
                            <circle cx="3" cy="3" r="3" />
                          </svg>En attente</button>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-3 py-5 text-sm text-gray-500">
                  <button class="btn-secondary mr-4" @click.prevent="generateConvocation(person)"
                    v-if="person.pivot?.registration_status_id === 2">Convocation</button>
                  <button class="btn-secondary" @click="generateAttendance(person)"
                    v-if="person.pivot?.registration_status_id === 2">Attestation de présence</button>
                  <span v-if="person.pivot?.registration_status_id !== 2">pas d'actions disponible</span>
                </td>
                <td><button @click="openEmail(person)" class="btn-secondary"
                    v-if="person.pivot?.registration_status_id === 2">envoi mail</button></td>
                <td class="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                  <router-link :to="'/stagiaires/' + person.id" target="_blank">Détails<span class="sr-only">, {{
                    person.user?.name }}</span>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <Pagination :current-page="currentPage" :total-pages="totalPages" @change-page="changePage" />
  </div>
</template>

<script setup>
/* eslint-disable */
import { onMounted, ref } from 'vue';
import Pagination from '@/components/lists/PaginationTables.vue';
import FilesList from '../files/FilesList.vue';
import BigLoader from '@/components/app/BigLoader.vue';
import client from '@/api/client';
import { UserCircleIcon } from '@heroicons/vue/24/outline';
import TrainingSendEmails from './TrainingSendEmails.vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import moment from 'moment';
import 'moment/locale/fr';
import {
  Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions,
} from '@headlessui/vue';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';

moment.locale('fr');

const props = defineProps({
  trainees: Object,
  training: [Object, Array],
});

// Pagination data
const currentPage = ref(1);
const totalPages = ref(1);
const pageSize = 20;

const traineesList = ref();
const profile = ref();
const isLoading = ref(false);
const loaderTitle = ref('');
const displayModalEmail = ref(false);
const selectedTrainee = ref(null);
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const relevantStatuses = ref([]);
const selectedTrainees = ref([]);

const statusClasses = {
  Préinscription: 'bg-blue-400',
  Validé: 'bg-green-400',
  Refusé: 'bg-red-400',
  'En attente': 'bg-yellow-400',
};

const changePage = (page) => {
  traineesList.value = props.trainees.slice((page - 1) * pageSize, page * pageSize);
  currentPage.value = page;
};


const generateAttendance = async (trainee) => {
  const startDate = moment(new Date()).format('YYYY-MM-DD');
  isLoading.value = true;
  loaderTitle.value = 'Génération de l\'attestation de présence en cours';
  const person = trainee.user?.name.replace(/\s+/g, '-').replace(/[^\w-]/g, '').toLowerCase();

  try {
    const response = await client.get(`/api/certificate/${props.training.id}/${trainee.id}`, {
      responseType: 'blob',
    });

    if (response.status === 200) {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `${person}-attestation-${startDate}.pdf`);
      document.body.appendChild(fileLink);
      fileLink.click();
      isLoading.value = false;
    }
  } catch (error) {
    isLoading.value = false;

    if (error.response && error.response.data instanceof Blob) {
      const reader = new FileReader();
      reader.onload = () => {
        const errorData = JSON.parse(reader.result);
        notificationMessage.value = errorData.error || "Une erreur s'est produite lors de la génération du document.";
      };
      reader.readAsText(error.response.data);
    } else {
      notificationMessage.value = error.message || "Une erreur s'est produite lors de la génération du document.";
    }

    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Erreur';
  }
};

const generateConvocation = async (trainee) => {
  const startDate = moment(new Date()).format('YYYY-MM-DD');
  isLoading.value = true;
  loaderTitle.value = 'Génération de la convocation en cours';
  const person = trainee.user?.name.replace(/\s+/g, '-').replace(/[^\w-]/g, '').toLowerCase();
  try {
    const response = await client.get(`api/trainings/${props.training.id}/convocation/${trainee.id}`, {
      responseType: 'blob',
    }).then((response) => {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `${person}-convocation-${startDate}.pdf`);
      document.body.appendChild(fileLink);
      fileLink.click();
      isLoading.value = false;
    });
  } catch (error) {
    isLoading.value = false;

    if (error.response && error.response.data instanceof Blob) {
      const reader = new FileReader();
      reader.onload = () => {
        const errorData = JSON.parse(reader.result);
        notificationMessage.value = errorData.error || "Une erreur s'est produite lors de la génération du document.";
      };
      reader.readAsText(error.response.data);
    } else {
      notificationMessage.value = error.message || "Une erreur s'est produite lors de la génération du document.";
    }

    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Erreur';
  }
}
const selectedStatus = ref([]);

const openEmail = (trainee) => {
  displayModalEmail.value = true;
  selectedTrainee.value = trainee;
}

const filterStatus = (status) => {
  selectedStatus.value = status;
  traineesList.value = props.trainees.filter((trainee) => trainee.pivot?.registration_status_id === status.id);
  totalPages.value = 1;
};

const fetchRegistrationStatuses = async () => {
  try {
    const response = await client.get('/api/registration-statuses');
    relevantStatuses.value = response.data;
    if (profile.value.role.slug === 'responsable-pedagogique') {
      const filteredStatuses = relevantStatuses.value.filter(status => status.id === 2);
      relevantStatuses.value = filteredStatuses;
    }
    const defaultStatus = relevantStatuses.value.find(status => status.slug === 'valide');
    selectedStatus.value = defaultStatus;
  } catch (error) {
    console.error('Failed to fetch registration statuses:', error);
  }
};

const selectAll = () => {
  console.log('select');
  if (selectedTrainees.value.length === traineesList.value.length) {
    selectedTrainees.value = [];
    return;
  } else { selectedTrainees.value = traineesList.value; }


}
const selectTrainee = (trainee) => {
  if (selectedTrainees.value.includes(trainee)) {
    selectedTrainees.value = selectedTrainees.value.filter((item) => item !== trainee);
  } else {
    selectedTrainees.value = [...selectedTrainees.value, trainee];
  }
}

const sendEmails = () => {
  // Ensure there are selected trainees
  if (!selectedTrainees.value.length) {
    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Erreur';
    notificationMessage.value = 'Aucun stagiaire sélectionné pour l\'envoi.';
    return;
  }

  const emails = selectedTrainees.value.map((trainee) => trainee.user.email);
  const subject = 'Formation : ' + props.training.title;

  // Detect the operating system using userAgent
  const isWindows = navigator.userAgent.indexOf('Windows') !== -1;
  const separator = isWindows ? ';' : ',';

  const primaryEmail = 'irt.contact@univ-amu.fr';
  const replyToEmail = 'irt.contact@univ-amu.fr';

  // Construct the mailto URL with BCC and Reply-To
  const mailto = `mailto:${primaryEmail}?bcc=${emails.join(separator)}` +
    `&subject=${encodeURIComponent(subject)}` +
    `&reply-to=${encodeURIComponent(replyToEmail)}`;

  // Launch the email
  window.location.href = mailto;
}

onMounted(() => {
  profile.value = JSON.parse(localStorage.getItem('user'));
  traineesList.value = props.trainees;
  if (traineesList.value.length > pageSize) {
    totalPages.value = Math.ceil(traineesList.value.length / pageSize);
  }

  traineesList.value = traineesList.value.filter(trainee => {
    return trainee.pivot?.registration_status_id === 2;
  })

  fetchRegistrationStatuses();
});
</script>
