<template>
    <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
        :detail="notificationMessage" @close="showNotification = false" />
    <div class="md:flex md:items-center md:justify-between">
        <div class="min-w-0 flex-1">
            <h1 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight"><span
                    v-if="!formProfile.id">Créer</span><span v-else>Modifier</span> mon
                compte</h1>
            <p class="text-indigo-700 mt-2"> {{ formProfile.user?.name }}</p>
        </div>
    </div>

    <div class="mx-auto px-4 sm:px-6 lg:px-8 mt-24">
        <div class="space-y-10 divide-y divide-gray-900/10">
            <AlertMessage type="error" :title="'Attention, votre profil n\'est pas complet'"
                v-if="formProfile.id && (formProfile.profile_completion !== 100) && displayErrors" />
            <AlertMessage type="success" :title="'Bravo, votre profil est complet'"
                v-if="formProfile.id && (formProfile.profile_completion === 100) && displayErrors" />

            <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
                <div class="px-4 sm:px-0">
                    <h2 class="text-base font-semibold leading-7 text-gray-900">{{ profileTitle }}</h2>
                    <p class="mt-1 text-sm leading-6 text-gray-600">{{ profileDescription }}</p>
                </div>
                <form class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                    <div class="px-4 py-6 sm:p-8">
                        <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div class="sm:col-span-6">
                                <fieldset class="mt-4">
                                    <legend class="sr-only">Genre</legend>
                                    <div class="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                                        <div v-for="genre in relevantGenres" :key="genre.id" class="flex items-center">
                                            <input :id="genre.id" name="genre" type="radio" :value="genre.id"
                                                :class="isMissing('genre_id') ? 'ring-red-300 ring-1 ring-inset' : ''"
                                                v-model="formProfile.genre_id" :key="componentKey"
                                                class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                            <label :for="genre.id"
                                                class="ml-3 block text-sm font-medium leading-6 text-gray-900">{{
                                                    genre.name }}</label>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>

                            <div class="sm:col-span-3">
                                <label for="birth-year" class="block text-sm font-medium leading-6 text-gray-900">Année
                                    de naissance
                                </label>
                                <div class="mt-2">
                                    <input type="text" name="birth-year" id="birth-year" autocomplete="bday-year"
                                        :class="isMissing('birth_year') ? 'missing-field' : ''"
                                        v-model="formProfile.birth_year"
                                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6" />
                                </div>
                            </div>

                            <div class="sm:col-span-3">
                                <label for="phone"
                                    class="block text-sm font-medium leading-6 text-gray-900">Téléphone</label>
                                <div class="mt-2">
                                    <input type="text" name="phone" id="phone" autocomplete="tel"
                                        v-model="formProfile.phone"
                                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        :class="isMissing('phone') ? 'missing-field' : ''" />
                                </div>
                            </div>

                            <div class="sm:col-span-full" v-if="formProfile.user">
                                <label for="phone" class="block text-sm font-medium leading-6 text-gray-900 mb-2">Email
                                    principal</label>
                                <span class="badge badge_selected">{{ formProfile.user?.email }}
                                    <XCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true"
                                        @click.prevent="displayAddMainEmail = true" />
                                </span>
                                <div class="mt-2 flex rounded-md shadow-sm" v-if="displayAddMainEmail">
                                    <div class="relative flex flex-grow items-stretch focus-within:z-10">
                                        <div
                                            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                            <EnvelopeIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </div>
                                        <input type="email" name="email" id="email" v-model="newMainEmail"
                                            class="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            placeholder="mail@example.com" />
                                    </div>
                                    <button type="button" @click.prevent="updateEmail"
                                        class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                        modifier l'email
                                    </button>
                                </div>
                            </div>
                            <div class="sm:col-span-full">
                                <div class="mt-5">
                                    <label for="phone"
                                        class="block text-sm font-medium leading-6 text-gray-900 mb-2">Emails
                                        secondaires</label>
                                    <div v-for="(email, index) in emailsSelected" :key="email">
                                        <span class="badge badge_selected">{{ email }}
                                            <XCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true"
                                                @click.prevent="removeItem(index)" />
                                        </span>

                                    </div>

                                    <div class="mt-2 flex rounded-md shadow-sm">
                                        <div class="relative flex flex-grow items-stretch focus-within:z-10">
                                            <div
                                                class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                <EnvelopeIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            </div>
                                            <input type="email" name="email" id="email" v-model="newSecondaryEmail"
                                                class="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                placeholder="mail@example.com" />
                                        </div>
                                        <button type="button" @click.prevent="addEmail(newSecondaryEmail)"
                                            class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                            ajouter l'email
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="col-span-full">
                                <div class="mt-2 sm:flex sm:items-center gap-x-3">
                                    <div v-if="formProfile.temporary_profile_picture_url">
                                        <img class="h-20 w-20 rounded-full object-cover"
                                            :src="formProfile.temporary_profile_picture_url" alt="" />
                                    </div>
                                    <UserCircleIcon class="h-12 w-12 text-gray-300" aria-hidden="true" v-else />

                                    <input id="fileInput" type="file" @change="selectFile($event)"
                                        accept="image/png, image/jpeg, image/jpg" placeholder="image"
                                        class="mt-4 sm:mt-0 w-full sm:w-auto rounded-md bg-white px-2.5 py-1.5 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-5 placeholder:text-sm" />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div
                        class="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8  line-btn">
                        <button type="button" class="text-sm font-semibold leading-6 text-gray-900">
                            Annuler
                        </button>
                        <button class="btn-primary" @click.prevent="saveProfile"
                            v-if="modification || profile?.id">Enregistrer</button>
                        <button type="submit" @click.prevent="createAccount" v-else class="btn-primary"
                            :class="[!isFormValid ? 'opacity-30' : '']" :disabled="!isFormValid">Créer</button>
                    </div>
                </form>
            </div>

            <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
                <div class="px-4 sm:px-0">
                    <h2 class="text-base font-semibold leading-7 text-gray-900">{{ addressTitle }}</h2>
                    <p class="mt-1 text-sm leading-6 text-gray-600">{{ addressDescription }}</p>
                </div>

                <form class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                    <div class="px-4 py-6 sm:p-8">
                        <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div class="sm:col-span-2">
                                <label for="street_number"
                                    class="block text-sm font-medium leading-6 text-gray-900">Numéro</label>
                                <div class="mt-2">
                                    <input type="text" name="street_number" id="street_number"
                                        autocomplete="street_number" v-model="formProfile.street_number"
                                        :class="isMissing('street_number') ? 'missing-field' : ''"
                                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                </div>
                            </div>
                            <div class="sm:col-span-4">
                                <label for="street_name"
                                    class="block text-sm font-medium leading-6 text-gray-900">Adresse postale</label>
                                <div class="mt-2">
                                    <input type="text" name="street_name" id="street_name"
                                        v-model="formProfile.street_name" autocomplete="street_name"
                                        :class="isMissing('street_name') ? 'missing-field' : ''"
                                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                </div>
                            </div>

                            <div class="sm:col-span-2">
                                <label for="postal-code" class="block text-sm font-medium leading-6 text-gray-900">Code
                                    postal</label>
                                <div class="mt-2">
                                    <input type="text" name="postal-code" id="postal-code" autocomplete="postal-code"
                                        @input="searchCityByPostcode(formProfile.profile_zip_code)"
                                        v-model="formProfile.profile_zip_code"
                                        :class="isMissing('profile_zip_code') ? 'missing-field' : ''"
                                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                </div>
                            </div>
                            <div class="sm:col-span-4">
                                <div v-if="formProfile.city"
                                    class="mt-8 pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                    {{ formProfile.city.name }}</div>
                                <div v-else>
                                    <div class="sm:col-span-4"
                                        v-if="formProfile.profile_zip_code || formProfile.city_id">
                                        <SelectComponent v-model="formProfile.city" :arrayData="relevantCities"
                                            :label="'Ville'" :placeholder="'Choisissez une ville'"
                                            :key="componentKey" />
                                    </div>
                                </div>
                                <div class="mt-9">
                                    <SmallLoader v-if="isLoading" :text="'Recherche des villes associées'" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8 line-btn">
                        <button type="button" class="text-sm font-semibold leading-6 text-gray-900">
                            Annuler
                        </button>
                        <button class="btn-primary" @click.prevent="saveProfile"
                            v-if="modification || profile?.id">Enregistrer</button>
                        <button type="submit" @click.prevent="createAccount" v-else class="btn-primary"
                            :class="[!isFormValid ? 'opacity-30' : '']" :disabled="!isFormValid">Créer</button>
                    </div>
                </form>
            </div>

            <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
                <div class="px-4 sm:px-0">
                    <h2 class="text-base font-semibold leading-7 text-gray-900">{{ professionalTitle }}</h2>
                    <p class="mt-1 text-sm leading-6 text-gray-600">{{ professionalDescription }}</p>
                </div>

                <form class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                    <div class="px-4 py-6 sm:p-8">
                        <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 relative">

                            <div class="col-span-full" style="z-index:10">
                                <div class="sm:col-span-3">
                                    <SelectComponent v-model="formProfile.professional_situation"
                                        :error="isMissing('professional_situation_id')"
                                        :arrayData="relevantProfessionalSituations" :label="'Situation professionnelle'"
                                        :placeholder="'Choisissez une situation'" :key="componentKey" />
                                </div>
                            </div>

                            <div class="col-span-full" style="z-index:9">
                                <SelectComponent v-model="formProfile.professional_category" style="z-index:99"
                                    :error="isMissing('professional_category_id')"
                                    :arrayData="relevantProfessionalCategories" :label="'Catégorie professionnelle'"
                                    :placeholder="'Choisissez une catégorie'" :key="componentKey" />
                            </div>

                            <div class="col-span-full" style=" z-index:8">
                                <SelectComponent v-model="formProfile.activity_sector"
                                    :error="isMissing('activity_sector_id')" :arrayData="relevantActivitySectors"
                                    :label="'Secteur d\'activité'" :placeholder="'Choisissez un secteur'"
                                    :key="componentKey" />
                            </div>

                            <div class="col-span-full" style="z-index:7">
                                <SelectComponent v-model="formProfile.degree" :arrayData="relevantDegrees"
                                    style="z-index:97" :error="isMissing('degree_id')" :label="'Diplôme'"
                                    :placeholder="'Choisissez un diplôme'" :key="componentKey" />
                            </div>

                            <div class="col-span-full" style="z-index:6">
                                <label for="city" class="block text-sm font-medium leading-6 text-gray-900">Nom de
                                    l'entreprise</label>
                                <div class="mt-2">
                                    <input type="text" name="company_name" id="company_name" autocomplete="company_name"
                                        v-model="formProfile.company_name"
                                        :class="isMissing('company_name') ? 'missing-field' : ''"
                                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                </div>
                            </div>

                            <div class="sm:col-span-3" style="z-index:5">
                                <SelectComponent v-model="formProfile.company_size" :arrayData="relevantCompanySizes"
                                    :label="'Taille de l\'entreprise'" :placeholder="'Choisissez une taille'"
                                    :error="isMissing('company_size_id')" :key="componentKey" />
                            </div>
                        </div>
                    </div>
                    <div
                        class="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8 line-btn">
                        <button type="button" class="text-sm font-semibold leading-6 text-gray-900">
                            Annuler
                        </button>
                        <button class="btn-primary" @click.prevent="saveProfile"
                            v-if="modification || profile?.id">Enregistrer</button>
                        <button type="submit" @click.prevent="createAccount" v-else class="btn-primary"
                            :class="[!isFormValid ? 'opacity-30' : '']" :disabled="!isFormValid">Créer</button>
                    </div>
                </form>
            </div>

            <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3"
                :class="!formProfile.id ? 'opacity-30 pointer-events-none' : ''">
                <div class="px-4 sm:px-0">
                    <h2 class="text-base font-semibold leading-7 text-gray-900">{{ unionTitle }}</h2>
                    <p class="mt-1 text-sm leading-6 text-gray-600">{{ unionDescription }}</p>
                </div>

                <form class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                    <div class="px-4 py-6 sm:p-8">
                        <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                            <div class="col-span-full">
                                <SwitchGroup as="div" class="flex items-center">
                                    <Switch v-model="formProfile.is_union_member" @update:model-value="saveProfile"
                                        :class="[formProfile.is_union_member ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                                        <span aria-hidden="true"
                                            :class="[formProfile.is_union_member ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                                    </Switch>
                                    <SwitchLabel as="span" class="ml-3 text-sm">
                                        <span class="font-medium text-gray-900">{{ unionSwitchLabel }}</span> {{ ' ' }}
                                    </SwitchLabel>
                                    <ExclamationCircleIcon class="ml-3 h-5 w-5 text-red-500" aria-hidden="true"
                                        v-if="isMissing('is_union_member')" />
                                </SwitchGroup>
                            </div>
                            <div class="col-span-full" v-if="formProfile.is_union_member">
                                <TraineeUnion type="union" :unions="formProfile.unions" :profileId="profileId"
                                    @update="saveProfileUnion" :key="componentKeyUnion" />
                            </div>

                            <div class="col-span-full">
                                <SwitchGroup as="div" class="flex items-center">
                                    <Switch v-model="formProfile.has_mandate" @update:model-value="saveProfile"
                                        :class="[formProfile.has_mandate ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                                        <span aria-hidden="true"
                                            :class="[formProfile.has_mandate ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                                    </Switch>
                                    <SwitchLabel as="span" class="ml-3 text-sm">
                                        <span class="font-medium text-gray-900">{{ mandateSwitchLabel }}</span> {{ ' '
                                        }}
                                    </SwitchLabel>
                                    <ExclamationCircleIcon class="ml-3 h-5 w-5 text-red-500" aria-hidden="true"
                                        v-if="isMissing('has_mandate')" />
                                </SwitchGroup>
                            </div>
                            <div class="col-span-full" v-if="formProfile.has_mandate">
                                <TraineeMandates type="mandates" :mandates="formProfile.mandates" :profileId="profileId"
                                    :key="componentKeyUnion" @update="saveProfile" />
                            </div>

                        </div>
                    </div>
                    <div
                        class="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8  line-btn">
                        <button type="button" class="text-sm font-semibold leading-6 text-gray-900">
                            Annuler
                        </button>
                        <button class="btn-primary" @click.prevent="saveProfile"
                            v-if="modification || profile?.id">Enregistrer</button>
                        <button type="submit" @click.prevent="createAccount" v-else class="btn-primary"
                            :class="[!isFormValid ? 'opacity-30' : '']" :disabled="!isFormValid">Créer</button>
                    </div>
                </form>
            </div>

            <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3"
                :class="!formProfile.id ? 'opacity-30 pointer-events-none' : ''">
                <div class="px-4 sm:px-0">
                    <h2 class="text-base font-semibold leading-7 text-gray-900">{{ vehicleTitle }}</h2>
                    <p class="mt-1 text-sm leading-6 text-gray-600">
                        {{ vehicleDescription }}
                    </p>
                </div>

                <form class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                    <div class="px-4 py-6 sm:p-8">
                        <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                            <div class="col-span-full">
                                <SwitchGroup as="div" class="flex items-center mb-5">
                                    <Switch v-model="formProfile.has_vehicle" @update:model-value="saveProfile"
                                        :class="[formProfile.has_vehicle ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                                        <span aria-hidden="true"
                                            :class="[formProfile.has_vehicle ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                                    </Switch>
                                    <SwitchLabel as="span" class="ml-3 text-sm">
                                        <span class="font-medium text-gray-900">{{ vehicleSwitchLabel }}</span> {{ ' '
                                        }}
                                    </SwitchLabel>
                                    <ExclamationCircleIcon class="ml-3 h-5 w-5 text-red-500" aria-hidden="true"
                                        v-if="isMissing('has_vehicle')" />
                                </SwitchGroup>
                                <Listbox as="div" v-model="formProfile.vehicle_category" :key="componentKey"
                                    v-if="formProfile.has_vehicle">
                                    <ListboxLabel class="block text-sm font-medium leading-6 text-gray-900">Puissance
                                        fiscale
                                    </ListboxLabel>
                                    <div class="relative mt-2">
                                        <ListboxButton
                                            class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                            <span class="block truncate" v-if="formProfile.vehicle_category">{{
                                                formProfile.vehicle_category.category }}</span>
                                            <span v-else class="text-gray-500">Choisissez une puissance</span>
                                            <span
                                                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            </span>
                                        </ListboxButton>

                                        <transition leave-active-class="transition ease-in duration-100"
                                            leave-from-class="opacity-100" leave-to-class="opacity-0">
                                            <ListboxOptions
                                                class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                <ListboxOption as="template"
                                                    v-for="itemList in relevantVehicleCategories" :key="itemList.id"
                                                    :value="itemList" v-slot="{ active, selected }">
                                                    <li
                                                        :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                                        <span
                                                            :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{
                                                                itemList.category }}</span>
                                                        <span v-if="selected"
                                                            :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                    </li>
                                                </ListboxOption>
                                            </ListboxOptions>
                                        </transition>
                                    </div>
                                </Listbox>
                            </div>

                            <div class="col-span-full" v-if="formProfile.has_vehicle">
                                <label for="carte-grise" class="block text-sm font-medium leading-6 text-gray-900">Carte
                                    grise</label>
                                <div class="col-span-full" style="z-index:6">
                                </div>
                                <a :href="formProfile.temporary_vehicle_registration_url" tagret="_blank"
                                    alt="carte-grise">
                                    <div class="mt-3 text-sm bg-gray-200 px-4 py-3 rounded"
                                        v-if="!modifyVehicleRegistration">
                                        {{ formProfile.vehicle_registration_pdf }}</div>
                                </a>
                                <button class="btn-secondary mt-3" v-if="!modifyVehicleRegistration"
                                    @click.prevent="modifyVehicleRegistration = true">modifier</button>
                                <div style="position:relative">
                                    <AddVehicleRegistrationPdf :profileId="store.user?.id"
                                        @saveVehicleRegistration="saveVehicleRegistration"
                                        v-if="modifyVehicleRegistration" />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div
                        class="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8  line-btn">
                        <button type="button" class="text-sm font-semibold leading-6 text-gray-900">
                            Annuler
                        </button>
                        <button class="btn-primary" @click.prevent="saveProfile"
                            v-if="modification || profile?.id">Enregistrer</button>
                    </div>
                </form>
            </div>

            <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3"
                :class="!formProfile.id ? 'opacity-30 pointer-events-none' : ''">
                <div class="px-4 sm:px-0">
                    <h2 class="text-base font-semibold leading-7 text-gray-900">Coordonnées bancaires</h2>
                    <p class="mt-1 text-sm leading-6 text-gray-600">
                        Informations nécessaires en cas de paiement par virement. Le remboursement des frais par
                        virement est prévu courant 2025.
                    </p>
                </div>

                <form class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                    <div class="px-4 py-6 sm:p-8">
                        <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                            <div class="col-span-full">
                                <div class="isolate -space-y-px rounded-md shadow-sm">
                                    <div
                                        class="relative rounded-md rounded-b-none px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-indigo-600">
                                        <label for="iban" class="block text-xs font-medium text-gray-900">IBAN</label>
                                        <input type="text" name="iban" id="iban" v-model="formProfile.iban"
                                            :class="isMissing('iban') ? 'missing-field' : ''"
                                            class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                            placeholder="FR63 1450 8000 4042 7333 3449 3Z85" />
                                    </div>
                                    <div
                                        class="relative rounded-md rounded-t-none px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-indigo-600">
                                        <label for="bank" class="block text-xs font-medium text-gray-900">Bic</label>
                                        <input type="text" name="bank" id="bank" v-model="formProfile.bic"
                                            :class="isMissing('bic') ? 'missing-field' : ''"
                                            class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                            placeholder="Crédit Mutuel" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div
                        class="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8  line-btn">
                        <button type="button" class="text-sm font-semibold leading-6 text-gray-900">
                            Annuler
                        </button>
                        <button class="btn-primary" @click.prevent="saveProfile"
                            v-if="modification || profile?.id">Enregistrer</button>
                    </div>
                </form>
            </div>

            <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3"
                :class="!formProfile.id ? 'opacity-30 pointer-events-none' : ''">
                <div class="px-4 sm:px-0">
                    <h2 class="text-base font-semibold leading-7 text-gray-900">{{ newsletterTitle }}</h2>
                    <p class="mt-1 text-sm leading-6 text-gray-600">{{ newsletterDescription }}</p>
                </div>

                <form class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                    <div class="px-4 py-6 sm:p-8">
                        <div class="max-w-2xl space-y-10">
                            <fieldset>
                                <div class="mt-6 space-y-8" style="z-index:1; position:relative;">
                                    <SwitchGroup as="div" class="flex items-center">
                                        <Switch v-model="formProfile.subscribed_to_newsletter"
                                            :class="[formProfile.subscribed_to_newsletter ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                                            <span aria-hidden="true"
                                                :class="[formProfile.subscribed_to_newsletter ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                                        </Switch>
                                        <SwitchLabel as="span" class="ml-3 text-sm font-medium leading-6 text-gray-900"
                                            passive>{{ newsletterSwitchLabel }}</SwitchLabel>
                                        <ExclamationCircleIcon class="ml-3 h-5 w-5 text-red-500" aria-hidden="true"
                                            v-if="isMissing('subscribed_to_newsletter')" />
                                    </SwitchGroup>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div
                        class="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8  line-btn">
                        <button type="button" class="text-sm font-semibold leading-6 text-gray-900">
                            Annuler
                        </button>
                        <button class="btn-primary" @click.prevent="saveProfile"
                            v-if="modification || profile?.id">Enregistrer</button>
                        <button type="submit" @click.prevent="createAccount" v-else class="btn-primary"
                            :class="[!isFormValid ? 'opacity-30' : '']" :disabled="!isFormValid">Créer</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, computed, onMounted, inject } from 'vue';
import { UserCircleIcon, XCircleIcon, EnvelopeIcon } from '@heroicons/vue/24/solid';
import {
    Switch, SwitchGroup, SwitchLabel, SwitchDescription,
    Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions
} from '@headlessui/vue';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import client from '@/api/client';
import fetchPersonalInfoServices from '@/services/PersonalInfoServices.js';
import { debounce } from 'lodash';
import SelectComponent from '@/components/form/SelectComponent.vue';
import { useRouter, useRoute } from 'vue-router';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import AddVehicleRegistrationPdf from '@/components/users/AddVehicleRegistrationPdf.vue';
import AlertMessage from '@/components/alerts/AlertMessage.vue';
import { ExclamationCircleIcon } from '@heroicons/vue/20/solid';
import SmallLoader from '@/components/app/SmallLoader.vue';
import TraineeMandates from '@/components/trainees/TraineeMandates.vue';
import TraineeUnion from '@/components/trainees/TraineeUnion.vue';

const props = defineProps({
    displayErrors: {
        type: Boolean,
        default: true
    }
});

const router = useRouter();
const route = useRoute();
const emit = defineEmits(['update']);
const store = inject('store');
const currentUser = ref();
const profile = ref();
const newsletter = ref(false);
const componentKey = ref(0);
const componentKeyUnion = ref(0);
const isLoading = ref(false);

const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('')

const relevantProfessionalSituations = ref([]);
const relevantProfessionalCategories = ref([]);
const relevantCompanySizes = ref([]);
const relevantActivitySectors = ref([]);
const relevantDegrees = ref([]);
const relevantCities = ref([]);
const relevantVehicleCategories = ref([]);
const modification = ref(false);
let modifyVehicleRegistration = ref(false);
const displayErrors = ref(false);
const profileId = ref(null);
const loadUnion = ref(false);
const formProfile = ref([]);
const selectedPhoto = ref(null);
const emailsSelected = ref([]);
const newSecondaryEmail = ref([]);
const newMainEmail = ref([]);
const displayAddMainEmail = ref(false);
const relevantGenres = ref([]);

const mandatoryFields = ['genre_id', 'phone'];
const isFormValid = computed(() => {
    for (const field of mandatoryFields) {
        if (!formProfile.value[field]) {
            return false;
        }
    }
    return true;
})
// Computed properties for labels and descriptions
const isMissing = computed(() => (fieldName) => {
    let isTrue = 0;
    for (const field in formProfile.value.missing_fields) {
        if (formProfile.value.missing_fields[field] === fieldName) {
            isTrue++;
        }
    };
    if (isTrue > 0 && displayErrors.value) {
        return true;
    }
});
const identifiantTitle = computed(() => 'Identifiants');
const identifiantDescription = computed(() => 'Identifiants');

const profileTitle = computed(() => 'Profil');
const profileDescription = computed(() => 'Informations personnelles');

const addressTitle = computed(() => 'Adresse personnelle');
const addressDescription = computed(() => 'Nécessaire pour les défraiements');

const professionalTitle = computed(() => 'Informations professionnelles');
const professionalDescription = computed(() => 'Informations exigées par le ministère du travail');

const unionTitle = computed(() => 'Syndicat et mandats');
const unionDescription = computed(() => 'Affiliation à un syndicat et mandat de représentation du personnel');

const newsletterTitle = computed(() => 'Abonnement newsletter');
const newsletterDescription = computed(() => 'Newsletter mensuelle de l\'IRT');

const unionSwitchLabel = computed(() => 'Syndiqué');
const mandateSwitchLabel = computed(() => 'Mandat ?');
const mandateLabel = computed(() => 'Mandat');

const newsletterSwitchLabel = computed(() => 'Je souhaite recevoir la newsletter mensuelle de l\'IRT');
const newsletterSwitchDescription = computed(() => 'Je souhaite recevoir la newsletter mensuelle de l\'IRT');

const vehicleTitle = computed(() => 'Véhicule personnel');
const vehicleDescription = computed(() => 'Informations nécessaires au défraiement du transport');

const vehicleSwitchLabel = computed(() => 'Véhiculé ?');

const createAccount = async () => {
    let emailsSecondary = null;
    if (emailsSelected.value.length > 0) {
        emailsSecondary = emailsSelected.value;
    }
    try {
        const response = await client.post('/api/profiles', {
            user_id: currentUser.value.id,
            role_id: 5,
            birth_year: formProfile.value.birth_year,
            genre_id: formProfile.value.genre_id,
            phone: formProfile.value.phone,
            street_name: formProfile.value.street_name,
            street_number: formProfile.value.street_number,
            profile_zip_code: formProfile.value.profile_zip_code,
            city_id: formProfile.value.city?.id,
            professional_situation_id: formProfile.value.professional_situation?.id,
            professional_category_id: formProfile.value.professional_category?.id,
            degree_id: formProfile.value.degree?.id,
            company_name: formProfile.value.company_name,
            company_size_id: formProfile.value.company_size?.id,
            company_postal_code: formProfile.value.company_postal_code,
            is_union_member: formProfile.value.is_union_member,
            has_mandate: formProfile.value.has_mandate,
            has_vehicle: formProfile.value.has_vehicle,
            mandate: formProfile.value.mandate,
            union_id: formProfile.value.union?.id,
            mandates_ids: formProfile.value.mandates?.map((mandate) => mandate.id),
            newsletter: newsletter.value,
            activity_sector_id: formProfile.value.activity_sector?.id,
            subscribed_to_newsletter: formProfile.value.subscribed_to_newsletter,
            emails: emailsSecondary,
        });
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, le profil a bien été créé';
        notificationMessage.value = '';
        modification.value = true;
        profileId.value = response.data.id;
        if (selectedPhoto.value) {
            uploadFile(response.data.id, true);
        } else {
            router.push({ name: 'Modification du compte', params: { idProfile: response.data.id } });
            updateProfile();
        }
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de la création du profil';
        if (error.response) {
            notificationMessage.value = error.response.data.message;
        }
    }
};
const saveVehicleRegistration = async (type, message) => {
    notificationTitle.value = '';
    notificationMessage.value = '';
    modifyVehicleRegistration.value = false;

    if (type === 'success') {
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, la carte grise a bien été enregistrée';
        notificationMessage.value = '';
    } else {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de l\'item';
        notificationMessage.value = message.message;
    }
    updateProfile();
};
const saveProfile = async () => {
    try {
        const response = await client.put(`/api/profiles/${profile.value.id}`, {
            birth_year: formProfile.value.birth_year,
            genre_id: formProfile.value.genre_id,
            phone: formProfile.value.phone,
            street_name: formProfile.value.street_name,
            street_number: formProfile.value.street_number,
            profile_zip_code: formProfile.value.profile_zip_code,
            city_id: formProfile.value.city?.id,
            professional_situation_id: formProfile.value.professional_situation?.id,
            professional_category_id: formProfile.value.professional_category?.id,
            degree_id: formProfile.value.degree?.id,
            company_name: formProfile.value.company_name,
            company_size_id: formProfile.value.company_size?.id,
            company_postal_code: formProfile.value.company_postal_code,
            is_union_member: formProfile.value.is_union_member,
            has_mandate: formProfile.value.has_mandate,
            has_vehicle: formProfile.value.has_vehicle,
            unions_is: formProfile.value.union?.id,
            mandates_ids: formProfile.value.mandates?.map((mandate) => mandate.id),
            activity_sector_id: formProfile.value.activity_sector?.id,
            iban: formProfile.value.iban,
            bic: formProfile.value.bic,
            vehicle_category_id: formProfile.value.vehicle_category?.id,
            subscribed_to_newsletter: formProfile.value.subscribed_to_newsletter,
            emails: emailsSelected.value,
        });
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, le profil a bien été modifié';
        notificationMessage.value = '';
        updateProfile();
        emit('update');
        displayErrors.value = true;
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de l\'item';
        if (error.response) {
            notificationMessage.value = error.response.data.message;
        }
    }
};

const updateEmail = async () => {
    try {
        const response = await client.post('api/update-email', {
            email: newMainEmail.value,
        });
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo,';
        notificationMessage.value += response.data.message;
        modification.value = true;
        fetchProfile();
        store.fetchUserDetails();
        displayAddMainEmail.value = false;
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de la création du profil';
        if (error.response) {
            notificationMessage.value = error.response.data.message;
        }
    }
};

const searchCity = debounce(async (keyword) => {
    fetchPersonalInfoServices.fetchCityByPostcode(1, 100, keyword).then((response) => {
        filteredTrainings.value = response.data;
    });
}, 600);

const searchCityByPostcode = async (keyword) => {

    if (keyword.length == 5) {
        isLoading.value = true;
        fetchPersonalInfoServices.fetchCityByPostcode(keyword).then((response) => {
            relevantCities.value = response;
            if (relevantCities.value.length === 1) {
                formProfile.value.city = relevantCities.value[0];
                componentKey.value++;
            }
            isLoading.value = false;
        });
    } else if (keyword.length == 0) {
        isLoading.value = false;
        formProfile.value.city = null;
    }
};
const updateProfile = () => {
    fetchProfile();
    store.fetchUserProfile();
};

const saveProfileUnion = async () => {
    const response = await client.get(`/api/profiles/${profileId.value}`);
    formProfile.value = response.data;
    store.fetchUserProfile();
    checkBooelanFields();
    componentKeyUnion.value++;
};
const checkBooelanFields = () => {
    formProfile.value.vehicle_category = relevantVehicleCategories.value.find((vehicleCategory) => vehicleCategory.id === formProfile.value.vehicle_category_id);
    if (formProfile.value.has_mandate === 1) {
        formProfile.value.has_mandate = true;
    } else {
        formProfile.value.has_mandate = false;
    }
    if (formProfile.value.is_union_member === 1) {
        formProfile.value.is_union_member = true;
    } else {
        formProfile.value.is_union_member = false;
    }
    if (formProfile.value.has_vehicle === 1) {
        formProfile.value.has_vehicle = true;
    } else {
        formProfile.value.has_vehicle = false;
    }
    if (formProfile.value.subscribed_to_newsletter === 1 || formProfile.value.subscribed_to_newsletter === true) {
        formProfile.value.subscribed_to_newsletter = true;
    } else {
        formProfile.value.subscribed_to_newsletter = false;
    }
    if (formProfile.value.vehicle_registration_pdf === '/path/to/pdf' || formProfile.value.vehicle_registration_pdf === null) {
        modifyVehicleRegistration.value = true;
    }
    if (props.displayErrors) {
        displayErrors.value = true;
    }
    if (formProfile.value.emails) {
        emailsSelected.value = JSON.parse(formProfile.value.emails);
        if (formProfile.value.emails.length < 1) {
            displayAddEmail.value = true;
        }
    }
};

const fetchProfile = async () => {
    try {
        const response = await client.get(`/api/profiles/${profileId.value}`);
        formProfile.value = response.data;
        checkBooelanFields();
        componentKey.value++;
    } catch (error) {
        console.error('Failed to fetch trainee details:', error);
    }
}

const selectFile = (event) => {
    const file = event.target.files[0];
    if (file) {
        selectedPhoto.value = file;
        if (profile.value?.id) {
            uploadFile(profile.value?.id);
        };
    }
}

const uploadFile = async (idProfile, isNew) => {
    const formDataProfile = new FormData();
    formDataProfile.append('profile_picture', selectedPhoto.value);
    formDataProfile.append('profile_id', idProfile);
    try {
        const response = await client.post(`/api/profile/picture`, formDataProfile, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        if (isNew) {
            updateProfile();
            router.push({ name: 'Modification du compte', params: { idProfile: idProfile } });
        } else {
            fetchProfile();
            store.fetchUserProfile();
        }

    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement';
        if (error.response) {
            notificationMessage.value = error.response.data.message;
        }
    }
}

const addEmail = (email) => {
    emailsSelected.value.push(email);
}
const removeItem = (index) => {
    emailsSelected.value.splice(index, 1);
}
const fetchGenres = async () => {
    try {
        const response = await client.get('/api/genres');
        relevantGenres.value = response.data;
    } catch (error) {
        console.error('Failed to fetch genres:', error);
    }
}
onMounted(async () => {
    let { user } = store;
    if (!user) {
        user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            store.setUser(user);
        }
        else {
            currentUser.value = JSON.parse(localStorage.getItem('userdetails'));
        }
    } else {
        profile.value = user;
        profileId.value = user.id;
    }

    fetchPersonalInfoServices.fetchProfessionalSituations().then((data) => {
        relevantProfessionalSituations.value = data;
    });

    fetchPersonalInfoServices.fetchProfessionalCategories().then((data) => {
        relevantProfessionalCategories.value = data;
    });
    fetchPersonalInfoServices.fetchCompanySizes().then((data) => {
        relevantCompanySizes.value = data;
    });
    fetchPersonalInfoServices.fetchActivitySectors().then((data) => {
        relevantActivitySectors.value = data;
    });

    fetchPersonalInfoServices.fetchDegrees().then((data) => {
        relevantDegrees.value = data;
    });
    fetchPersonalInfoServices.fetchVehicleCategories().then((data) => {
        relevantVehicleCategories.value = data;
    });

    fetchGenres();
    if (profile?.value) {
        modification.value = true;
        fetchProfile();
    }
});
</script>
