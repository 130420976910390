<template>
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    :detail="notificationMessage" @close="showNotification = false" />

  <!--syndicat-->
  <div class="col-span-full" v-if="type === 'union'">
    <span v-for="item in unions" :key="item.id" class="block sm:inline-block sm:w-auto w-full mr-2">
      <button type="button" :class="isUnionOk(item)" class="w-full"
        @click.prevent="modifyUnion(item, 'modification'); typeEdition = 'modification';"><span class=""><span
            class="mr-2">
            {{ item.name }} </span><span class="text-xs">{{ moment(item.pivot?.start_date).format("Do/MM/YY") }}
            -</span> <span v-if="item.pivot?.end_date" class="text-xs">{{
              moment(item.pivot?.end_date).format("Do/MM/YY")
            }}</span></span>
        <XCircleIcon class="-ml-0.5 h-5 w-5 ml-auto" aria-hidden="true" />
      </button></span>
  </div>
  <div class="col-span-full mt-5" v-if="type === 'union'">
    <SelectComponent v-model="formProfile.union" :arrayData="relevantUnions" style="position:relative; z-index:10;"
      :label="'Organisation syndicale'" :placeholder="'Choisissez une organisation'" :key="componentKey"
      @update:model-value="addUnion()" />
  </div>

  <!--modale d'édition-->
  <div style="z-index:200; position:relative;">
    <TransitionRoot as="template" :show="open">
      <Dialog class="relative z-10" @close="open = false">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
          leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div class="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogPanel
                class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900 mb-5">
                      <!-- eslint-disable-next-line vue/no-unused-vars -->
                      <div><span v-if="typeEdition === 'creation'">Associer un </span><span
                          v-if="typeEdition === 'modification'">Modifier le</span> syndicat</div>
                      <div><span class="status status1 mt-5 !text-base">{{ unionSelected?.name }}</span></div>
                    </DialogTitle>
                    <div class="mt-2">
                      <div class="sm:flex sm:items-center justify-center px-4 mb-10">
                        <div class="mr-2 w-full text-left">
                          <label for="start-on" class="block text-sm font-medium leading-6 text-gray-900"
                            contenteditable="true">Date d'inscription au
                            syndicat
                          </label>
                          <div class="mt-2 w-full">
                            <!-- eslint-disable no-autofocus -->
                            <input type="date" name="start-on" id="start-on" v-model="startDate"
                              placeholder="Date de début"
                              class="mb-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                          </div>
                        </div>

                        <div class="w-full text-left" v-if="typeEdition === 'modification'">
                          <label for="end-on" class="block text-sm font-medium leading-6 text-gray-900">Date de
                            désinscription</label>
                          <div class="mt-2 w-full ">
                            <input type="date" name="end-on" id="end-on" v-model="endDate"
                              class="mb-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                          </div>
                        </div>

                      </div>
                      <AlertMessage type="error" v-if="!endDate && !isValid && typeEdition === 'modification'"
                        class="mb-10" title="Vous devez inscrire une date de fin" />
                      <SwitchGroup as="div" class="flex items-center mb-5" v-if="typeEdition === 'modification'">
                        <Switch v-model="isValid"
                          :class="[isValid ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                          <span aria-hidden="true"
                            :class="[isValid ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                        </Switch>
                        <SwitchLabel as="span" class="ml-3 text-sm text-left">
                          <span class="font-medium text-gray-900">{{ isValid ?
                            'Oui, je suis toujours inscrit à ce syndicat' :
                            'Non, je ne suis plus inscrit à ce syndicat'
                            }}</span>
                        </SwitchLabel>
                      </SwitchGroup>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button v-if="typeEdition === 'creation'" type="button"
                    :class="startDate ? '' : 'pointer-events-none opacity-50 cursor-not-allowed'"
                    class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    @click="createUnion">Créer</button>
                  <button v-else-if="typeEdition === 'modification'" @click="putUnion" type="button"
                    :class="!endDate && !isValid ? 'pointer-events-none opacity-50 cursor-not-allowed' : ''"
                    class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2">Modifier</button>
                  <button type="button"
                    class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    @click="open = false" ref="cancelButtonRef">Annuler</button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, computed, onMounted, inject } from 'vue';
import fetchPersonalInfoServices from '@/services/PersonalInfoServices.js';
import SelectComponent from '@/components/form/SelectComponent.vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import client from '@/api/client';
import {
  Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import { CheckIcon, ChevronUpDownIcon, XCircleIcon, PencilSquareIcon } from '@heroicons/vue/20/solid';
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import moment from 'moment';
import 'moment/locale/fr';
import AlertMessage from '@/components/alerts/AlertMessage.vue';

moment.locale('fr');

const props = defineProps({
  adminMakesRegistration: {
    type: Boolean,
    default: false
  },
  displayErrors: {
    type: Boolean,
    default: true
  },
  unions: [Array, Object],
  mandates: [Array, Object],
  type: String,
  profileId: [Number, String],
});
const emit = defineEmits(['update']);
const formProfile = ref([]);
const relevantUnions = ref([]);
const componentKey = ref(0);

const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const open = ref(false);
const unionSelected = ref(null);
const typeEdition = ref('creation');
const startDate = ref(null);
const endDate = ref(null);
let isValid = ref(false);

const isUnionOk = (union) => {
  if (union.pivot.is_valid && union.pivot.start_date) {
    return 'badge_selected'
  } else if (union.pivot.is_valid && !union.pivot.start_date) {
    return 'badge_error'
  } else if (!union.pivot.is_valid && (!union.pivot.end_date || !union.pivot.start_date)) {
    return 'badge_error'
  } else if (!union.pivot.is_valid && (union.pivot.end_date && union.pivot.start_date)) {
    return 'badge_unselected'
  }
};


const modifyUnion = async (union, type) => {
  typeEdition.value = type;
  unionSelected.value = union;
  startDate.value = union.pivot.start_date;
  endDate.value = union.pivot.end_date;
  open.value = true;

  if (typeEdition.value === 'modification') {
    isValid.value = union.pivot.is_valid === 1 ? true : false;

    componentKey.value += 1;
  }
};
const addUnion = async () => {
  unionSelected.value = formProfile.value.union;
  typeEdition.value = 'creation';
  startDate.value = null;
  open.value = true;
  componentKey.value += 1;
};

const createUnion = async () => {
  if (startDate.value === null) {
    notificationType.value = 'error';
    showNotification.value = true;
    notificationTitle.value = 'Vous devez renseigner une date de début';
    notificationMessage.value = 'La date de début est obligatoire';
    return;
  } else {
    try {
      const response = await client.post(`/api/profiles/${props.profileId}/unions`, {
        union_id: unionSelected.value.id,
        is_valid: true,
        start_date: startDate.value,
        end_date: null,
      });
      unionSelected.value = null;
      startDate.value = null;
      endDate.value = null;
      showNotification.value = true;
      setTimeout(() => { showNotification.value = false; }, 3000);
      notificationType.value = 'success';
      notificationTitle.value = 'Bravo, le syndicat a bien été modifié';
      notificationMessage.value = '';
      open.value = false;
      emit('update');
    } catch (error) {
      notificationType.value = 'error';
      showNotification.value = true;
      notificationTitle.value = 'Erreur lors de l\'enregistrement du syndicat';
      if (error.response) {
        notificationMessage.value = error.response.data.message;
      }
    }
  }
};

const putUnion = async () => {
  if (isValid.value === false && endDate.value === null) {
    notificationType.value = 'error';
    showNotification.value = true;
    notificationTitle.value = 'Si le mandat est inactif, vous devez renseigner une date de fin';
  } else {

    try {
      const response = await client.put(`/api/profiles/${props.profileId}/unions/pivot/${unionSelected.value.pivot.id}`, {
        is_valid: isValid.value,
        start_date: startDate.value,
        end_date: endDate.value,
      });
      showNotification.value = true;
      setTimeout(() => { showNotification.value = false; }, 3000);
      notificationType.value = 'success';
      notificationTitle.value = 'Bravo, le syndicat a bien été modifié';
      notificationMessage.value = '';
      emit('update');
      open.value = false;
    } catch (error) {
      notificationType.value = 'error';
      showNotification.value = true;
      notificationTitle.value = 'Erreur lors de l\'enregistrement du syndicat';
      if (error.response) {
        notificationMessage.value = error.response.data.message;
      }
    }
  }
};


onMounted(async () => {
  fetchPersonalInfoServices.fetchUnions().then((data) => {
    relevantUnions.value = data;
  });
});
</script>
