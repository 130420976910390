<template>
    <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
        :detail="notificationMessage" @close="showNotification = false" style="z-index:3000; position:absolute" />
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative !z-100" @click="$emit('close')" style="z-index:2000;">
            <div class=" fixed inset-0" />

            <div class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                        <TransitionChild as="template"
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enter-from="translate-x-full" enter-to="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leave-from="translate-x-0" leave-to="translate-x-full">
                            <DialogPanel class="pointer-events-auto w-screen max-w-md">
                                <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                                    <div class="h-0 flex-1 overflow-y-auto">
                                        <div class="bg-indigo-700 px-4 py-6 sm:px-6">
                                            <div class="flex items-center justify-between">
                                                <DialogTitle class="text-base font-semibold leading-6 text-white">
                                                    Entrer
                                                    les heures pour {{ teacher.user.name }}</DialogTitle>
                                                <div class="ml-3 flex h-7 items-center">
                                                    <button type="button"
                                                        class="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                        @click="$emit('close')">
                                                        <span class="absolute -inset-2.5" />
                                                        <span class="sr-only">Fermer</span>
                                                        <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="mt-1">
                                                <p class="text-sm text-indigo-300">Entrer les heures pour
                                                    {{ teacher?.user?.name }}</p>
                                            </div>
                                        </div>
                                        <div class="px-4">
                                            <AlertMessage v-if="displayAlert" :type="alertType" :title="alertTitle"
                                                class="mt-12" :content="alertMessage" />
                                        </div>
                                        <div class="flex flex-1 flex-col justify-between">
                                            <div class="divide-y divide-gray-200 px-4 sm:px-6">
                                                <div class="space-y-6 pb-5 pt-6">
                                                    <div class="space-y-6 pb-5 pt-6">
                                                        <div class="relative">
                                                            <div class="absolute inset-0 flex items-center"
                                                                aria-hidden="true">
                                                                <div class="w-full border-t border-gray-300" />
                                                            </div>
                                                            <div class="relative flex justify-start">
                                                                <span
                                                                    class="bg-white pr-3 text-base font-semibold leading-6 text-gray-900">Heures
                                                                    prévisionnelles</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-span-full">
                                                            <label for="lectures"
                                                                class="block text-sm font-medium leading-6 text-gray-900">Cours
                                                                magistraux</label>
                                                            <div class="relative mt-2 rounded-md shadow-sm">
                                                                <input type="number" name="lectures" id="lectures"
                                                                    :disabled="teacher?.pivot?.ares_status_id > 1"
                                                                    step="0.25" value="0.00" min="0"
                                                                    v-model="formTeacher.expected_lecture_hours"
                                                                    class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200"
                                                                    placeholder="minimum 0"
                                                                    aria-describedby="lectures-currency" />
                                                                <div
                                                                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                                    <span class="text-gray-500 sm:text-sm"
                                                                        id="lectures-currency">H</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-span-full">
                                                            <label for="tutorials"
                                                                class="block text-sm font-medium leading-6 text-gray-900">Travaux
                                                                dirigés</label>
                                                            <div class="relative mt-2 rounded-md shadow-sm">
                                                                <input type="number" name="tutorials" id="tutorials"
                                                                    :disabled="teacher?.pivot?.ares_status_id > 1"
                                                                    v-model="formTeacher.expected_tutorial_hours"
                                                                    step="0.25" value="0.00" min="0"
                                                                    class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200"
                                                                    placeholder="minimum 0"
                                                                    aria-describedby="tutorials-currency" />
                                                                <div
                                                                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                                    <span class="text-gray-500 sm:text-sm"
                                                                        id="tutorials-currency">H</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-span-full">
                                                            <div>
                                                                <label for="computedTutorialHours"
                                                                    class="block text-sm font-medium leading-6 text-gray-900">Heures
                                                                    équivalent travaux dirigés
                                                                </label>
                                                                <div class="mt-2">
                                                                    <div class="mt-2">
                                                                        <input type="number"
                                                                            name="computedTutorialHours"
                                                                            id="computedTutorialHours" min="0"
                                                                            :value="Number(hetd(formTeacher.expected_lecture_hours, formTeacher.expected_tutorial_hours))"
                                                                            disabled=""
                                                                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6"
                                                                            placeholder="73736" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <!-- Edit status false -->
                                                        <div v-if="trainingDone" class="space-y-6 pb-5 pt-6">
                                                            <div class="relative">
                                                                <div class="absolute inset-0 flex items-center"
                                                                    aria-hidden="true">
                                                                    <div class="w-full border-t border-gray-300" />
                                                                </div>
                                                                <div class="relative flex justify-start">
                                                                    <span
                                                                        class="bg-white pr-3 text-base font-semibold leading-6 text-gray-900">Heures
                                                                        réalisées</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-span-full">
                                                                <label for="lectures"
                                                                    class="block text-sm font-medium leading-6 text-gray-900">Cours
                                                                    magistraux</label>
                                                                <div class="relative mt-2 rounded-md shadow-sm">
                                                                    <input type="number" name="lectures" id="lectures"
                                                                        step="0.25" value="0.00" min="0"
                                                                        v-model="formTeacher.actual_lecture_hours"
                                                                        class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                        placeholder="minimum 0"
                                                                        aria-describedby="lectures-currency" />
                                                                    <div
                                                                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                                        <span class="text-gray-500 sm:text-sm"
                                                                            id="lectures-currency">H</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-span-full">
                                                                <label for="tutorials"
                                                                    class="block text-sm font-medium leading-6 text-gray-900">Travaux
                                                                    dirigés</label>
                                                                <div class="relative mt-2 rounded-md shadow-sm">
                                                                    <input type="number" name="tutorials" id="tutorials"
                                                                        step="0.25" value="0.00" min="0"
                                                                        v-model="formTeacher.actual_tutorial_hours"
                                                                        class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                        placeholder="minimum 0"
                                                                        aria-describedby="tutorials-currency" />
                                                                    <div
                                                                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                                        <span class="text-gray-500 sm:text-sm"
                                                                            id="tutorials-currency">H</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-span-full">
                                                                <div>
                                                                    <label for="computedTutorialHours"
                                                                        class="block text-sm font-medium leading-6 text-gray-900">Heures
                                                                        équivalent travaux dirigés
                                                                    </label>
                                                                    <div class="mt-2">
                                                                        <div class="mt-2">
                                                                            <input type="number"
                                                                                name="computedTutorialHours"
                                                                                id="computedTutorialHours" min="0"
                                                                                :value="hetd(formTeacher.actual_lecture_hours, formTeacher.actual_tutorial_hours)"
                                                                                disabled=""
                                                                                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6"
                                                                                placeholder="73736" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-span-full">
                                                            <div class="mb-10">
                                                                <SwitchGroup as="div" :key="componentKey"
                                                                    class="flex items-center">
                                                                    <Switch v-model="is_remunerated"
                                                                        :class="[is_remunerated ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                                                                        <span aria-hidden="true"
                                                                            :class="[is_remunerated ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                                                                    </Switch>
                                                                    <SwitchLabel as="span" class="ml-3 text-sm">
                                                                        <span class="font-medium text-gray-900">{{
                                                                            is_remunerated ? 'Intervention rémunérée' :
                                                                                'Intervention non rémunérée' }}</span>
                                                                    </SwitchLabel>
                                                                </SwitchGroup>
                                                            </div>
                                                            <Listbox as="div" v-model="selected"
                                                                v-if="aresStatuses && profile.role?.slug === 'administrateur'">
                                                                <ListboxLabel class="sr-only">Change published status
                                                                </ListboxLabel>
                                                                <div class="relative">
                                                                    <div
                                                                        class="inline-flex divide-x divide-indigo-700 rounded-md shadow-sm">
                                                                        <div
                                                                            class="inline-flex items-center gap-x-1.5 rounded-l-md bg-indigo-600 px-3 py-2 text-white shadow-sm">
                                                                            <CheckIcon class="-ml-0.5 h-5 w-5"
                                                                                aria-hidden="true" />
                                                                            <p class="text-sm font-semibold"
                                                                                v-if="selected">{{
                                                                                    selected?.name }}</p>
                                                                            <p class="text-sm font-semibold" v-else>pas
                                                                                de
                                                                                statut Ares saisi</p>
                                                                        </div>
                                                                        <ListboxButton
                                                                            class="inline-flex items-center rounded-l-none rounded-r-md bg-indigo-600 p-2 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 focus:ring-offset-gray-50">
                                                                            <span class="sr-only">Change published
                                                                                status</span>
                                                                            <ChevronDownIcon class="h-5 w-5 text-white"
                                                                                aria-hidden="true" />
                                                                        </ListboxButton>
                                                                    </div>

                                                                    <transition
                                                                        leave-active-class="transition ease-in duration-100"
                                                                        leave-from-class="opacity-100"
                                                                        leave-to-class="opacity-0">
                                                                        <ListboxOptions
                                                                            class="absolute right-0 z-10 mt-2 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                            <ListboxOption as="template"
                                                                                v-for="option in aresStatuses"
                                                                                :key="option.name" :value="option"
                                                                                v-slot="{ active, selected }">
                                                                                <li
                                                                                    :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'cursor-default select-none p-4 text-sm']">
                                                                                    <div class="flex flex-col">
                                                                                        <div
                                                                                            class="flex justify-between">
                                                                                            <p
                                                                                                :class="selected ? 'font-semibold' : 'font-normal'">
                                                                                                {{ option.name }}</p>
                                                                                            <span v-if="selected"
                                                                                                :class="active ? 'text-white' : 'text-indigo-600'">
                                                                                                <CheckIcon
                                                                                                    class="h-5 w-5"
                                                                                                    aria-hidden="true" />
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            </ListboxOption>
                                                                        </ListboxOptions>
                                                                    </transition>
                                                                </div>
                                                            </Listbox>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex flex-shrink-0 justify-end px-4 py-4">
                                        <button type="button"
                                            class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                            @click="$emit('close')">Annuler</button>
                                        <button type="submit" @click.prevent="saveHours"
                                            class="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Enregistrer</button>
                                    </div>
                                </form>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>

</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, computed } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot, Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, ChevronDownIcon } from '@heroicons/vue/20/solid'
import client from '@/api/client';
import fetchTrainingsServices from '@/services/TrainingsServices.js';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import AlertMessage from '@/components/alerts/AlertMessage.vue';

const emit = defineEmits(['close', 'savingHours']);

const selected = ref(null);
const aresStatuses = ref([]);

const props = defineProps({
    editStatus: {
        type: Boolean,
        default: false,
    },
    idTraining: {
        type: [Number, String],
        required: true,
    },
    teacher: {
        type: [Array, Object],
        required: true,
    },
    type: {
        type: String,
        required: true,
    },
    training: [Array, Object],
    required: true,
});

const open = ref(true);
const formTeacher = ref({
});
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const trainingDone = ref(false);
const profile = ref(null);
const componentKey = ref(0);
const is_remunerated = ref(false);

const alertType = ref('');
const alertTitle = ref('');
const alertMessage = ref('');
const displayAlert = ref(false);

const hetd = ((cm, td) => {
    let x = (cm * 1.5) + td;
    if (cm !== null && td !== null) {
        let res = parseFloat(x).toFixed(2);
        return res;
    } else {
        return 0;
    }
});
const checkIfNull = () => {
    if (!trainingDone.value) {
        if (formTeacher.value.expected_lecture_hours === null || formTeacher.value.expected_tutorial_hours === null) {
            console.log('expected_lecture_hours', !formTeacher.value.expected_lecture_hours);
            console.log('expected_tutorial_hours', !formTeacher.value.expected_tutorial_hours);
            alertType.value = 'error';
            alertTitle.value = 'Les heures prévisionelles ne peuvent pas être nulles';
            alertMessage.value = 'Veuillez renseigner les heures prévisionnelles';
            displayAlert.value = true;
            return true;
        }
        displayAlert.value = false;
        return false;
    } else {
        if (formTeacher.value.actual_lecture_hours === null || formTeacher.value.actual_tutorial_hours === null) {
            alertType.value = 'error';
            alertTitle.value = 'Les heures réalisées ne peuvent pas être nulles';
            alertMessage.value = 'Veuillez renseigner les heures prévisionnelles';
            displayAlert.value = true;
            return true;
        }
        return false;
    }
};


const saveHours = async () => {
    if (!trainingDone.value) {
        if (formTeacher.value.expected_lecture_hours === null || formTeacher.value.expected_tutorial_hours === null) {
            console.log('expected_lecture_hours', !formTeacher.value.expected_lecture_hours);
            console.log('expected_tutorial_hours', !formTeacher.value.expected_tutorial_hours);
            alertType.value = 'error';
            alertTitle.value = 'Les heures prévisionelles ne peuvent pas être nulles';
            alertMessage.value = 'Veuillez renseigner les heures prévisionnelles';
            displayAlert.value = true;
            return

        }
        displayAlert.value = false;
        putTeacherHours();
    } else {
        if (formTeacher.value.actual_lecture_hours === null || formTeacher.value.actual_tutorial_hours === null) {
            alertType.value = 'error';
            alertTitle.value = 'Les heures réalisées ne peuvent pas être nulles';
            alertMessage.value = 'Veuillez renseigner les heures prévisionnelles';
            displayAlert.value = true;
            return
        }
        displayAlert.value = false;
        putTeacherHours();
    }

};

const putTeacherHours = async () => {
    console.log('putTeacherHours');
    let urlws = '';
    if (props.type === 'intervenant') {
        urlws = `api/trainings/${props.idTraining}/teachers/${props.teacher.id}`;
    } else if (props.type === 'responsable-pedagogique') {
        urlws = `api/trainings/${props.idTraining}/pedagogical-supervisors/${props.teacher.id}`;
    }
    let arrayToPost = {};
    if (props.teacher?.pivot?.ares_status_id < 2) {
        arrayToPost.expected_lecture_hours = formTeacher.value.expected_lecture_hours;
        arrayToPost.expected_tutorial_hours = formTeacher.value.expected_tutorial_hours;
    }
    if (trainingDone.value) {
        arrayToPost.actual_lecture_hours = formTeacher.value.actual_lecture_hours;
        arrayToPost.actual_tutorial_hours = formTeacher.value.actual_tutorial_hours;
    }
    arrayToPost.ares_status_id = selected.value?.id;
    arrayToPost.needs_accommodation = formTeacher.value.needs_accommodation;
    arrayToPost.reimbursed_transportation = formTeacher.value.reimbursed_transportation;
    arrayToPost.is_remunerated = is_remunerated.value;
    console.log('arrayToPost', arrayToPost);
    try {
        const response = await client.put(`${urlws}`, arrayToPost);
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié';
        notificationTitle.value += response.data.message;
        notificationMessage.value = ''
        emit('savingHours', props.type);
        open.value = false;
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de l\'item';
        if (error.response) {
            notificationMessage.value = error.response.data.message;
        }
    }
};

onMounted(() => {
    profile.value = JSON.parse(localStorage.getItem('user'));
    formTeacher.value.actual_lecture_hours = props.teacher?.pivot?.actual_lecture_hours;
    formTeacher.value.expected_lecture_hours = props.teacher?.pivot?.expected_lecture_hours;
    formTeacher.value.actual_tutorial_hours = props.teacher?.pivot?.actual_tutorial_hours;
    formTeacher.value.expected_tutorial_hours = props.teacher?.pivot?.expected_tutorial_hours;
    formTeacher.value.needs_accommodation = props.teacher?.pivot?.needs_accommodation;
    formTeacher.value.reimbursed_transportation = props.teacher?.pivot?.reimbursed_transportation;
    // is_remunerated.value = props.teacher?.pivot?.is_remunerated;
    if (props.teacher?.pivot?.is_remunerated === 1) {
        is_remunerated.value = true;
    } else {
        is_remunerated.value = false;
    }
    componentKey.value += 1;
    fetchTrainingsServices.fetchAresStatuses().then((data) => {
        aresStatuses.value = data;
        selected.value = aresStatuses.value.find((status) => status.id === props.teacher.pivot?.ares_status_id);
    });

    trainingDone.value = new Date(props.training.end_date) < new Date();
});
</script>
