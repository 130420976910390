<template>

  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    :detail="notificationMessage" @close="showNotification = false" />
  <ConfirmationDialog v-if="displayConfirmSuccessDialog" :title="modalTitle" :message="modalMessage"
    :primaryActionLabel="'confirmer'" :secondaryActionLabel="'annuler'" @close="displayConfirmSuccessDialog = false"
    @confirm="sendMails()" />
  <div
    class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0 mt-12">
    <BigLoader v-if="isLoading" :title="loaderTitle" :text="textLoader" />
    <button v-for="(action, actionIdx) in actions" :key="action.title" @click.prevent="startAction(action)"
      :class="[actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '', actionIdx === 1 ? 'sm:rounded-tr-lg' : '', actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '', actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '', 'group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 text-left']">
      <div>
        <span :class="[action.iconBackground, action.iconForeground, 'inline-flex rounded-lg p-3 ring-4 ring-white']">
          <component :is="action.icon" class="h-6 w-6" aria-hidden="true" />
        </span>
      </div>
      <div class="mt-8">
        <h3 class="text-base font-semibold leading-6 text-gray-900 text-left">
          <div> {{ action.title }}
          </div>
        </h3>
        <p class="mt-2 text-sm text-gray-500">{{ action.description }}</p>
      </div>
      <span class="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
        aria-hidden="true">
        <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
          <path
            d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
        </svg>
      </span>
    </button>
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, defineProps } from 'vue';
import {
  EnvelopeOpenIcon,
  ArrowDownCircleIcon,
  PencilSquareIcon,
  PaperAirplaneIcon,
  CheckCircleIcon,
  HandThumbUpIcon,
} from '@heroicons/vue/24/outline';
import client from '@/api/client';
import moment from 'moment';
import 'moment/locale/fr';
import BigLoader from '@/components/app/BigLoader.vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';

moment.locale('fr');

const props = defineProps({
  training: Object,
});
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const displayConfirmSuccessDialog = ref(false);
let modalTitle = ref('');
let modalMessage = ref('');
let componentKey = ref(0);
let typeSelected = ref('');
let textLoader = ref('Préparation en cours de votre document');

const emit = defineEmits(['changeTab']);
const actions = [
  {
    title: 'Télécharger les convocations',
    description: 'Télécharger les convocations de tous les stagiaires',
    href: 'api',
    icon: PencilSquareIcon,
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-teal-50',
  },
  {
    title: 'Exporter les émargements',
    description: 'Exporter les émargements pour toute la formation',
    type: 'api',
    icon: ArrowDownCircleIcon,
    iconForeground: 'text-purple-700',
    iconBackground: 'bg-purple-50',
  },
  {
    title: 'Saisir les absences',
    description: 'Saisir les absences des stagiaires',
    href: 'Présences',
    type: 'tab',
    icon: CheckCircleIcon,
    iconForeground: 'text-rose-700',
    iconBackground: 'bg-rose-50',
  },

  {
    title: 'Télécharger les attestations de présence',
    description: 'Télécharger les attestations de présence des stagiaires',
    type: 'api',
    icon: ArrowDownCircleIcon,
    iconForeground: 'text-yellow-700',
    iconBackground: 'bg-yellow-50',
  },
  {
    title: 'Transmettre les convocations',
    description: 'Envoyer les mails pour télécharger sa convocation à tous les stagiaires',
    href: '#',
    type: 'api',
    icon: EnvelopeOpenIcon,
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-teal-50',
  },
  {
    title: 'Transmettre les attestations',
    description: 'Envoyer les mails pour télécharger son attestations de présence à tous les stagiaires',
    href: 'api',
    icon: EnvelopeOpenIcon,
    iconForeground: 'text-yellow-700',
    iconBackground: 'bg-yellow-50',
  },

];
const isLoading = ref(false);
const loaderTitle = ref('');

const generateAttendanceSheet = async () => {
  const startDate = moment(new Date()).format('YYYY-MM-DD');
  isLoading.value = true;
  loaderTitle.value = 'Génération de l\'émargement en cours';
  try {
    const response = await client.get(`api/generate-attendance-sheet/${props.training.id}`, {
      responseType: 'blob',
    });

    if (response.status === 200) {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `f${props.training.id}-attestation-${startDate}.pdf`);
      document.body.appendChild(fileLink);
      fileLink.click();
      isLoading.value = false;
    }
  } catch (error) {
    isLoading.value = false;

    if (error.response && error.response.data instanceof Blob) {
      const reader = new FileReader();
      reader.onload = () => {
        const errorData = JSON.parse(reader.result);
        notificationMessage.value = errorData.error || "Une erreur s'est produite lors de la génération du document.";
      };
      reader.readAsText(error.response.data);
    } else {
      notificationMessage.value = error.message || "Une erreur s'est produite lors de la génération du document.";
    }

    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Erreur';
  }
}


const generateAttendances = async () => {
  const startDate = moment(new Date()).format('YYYY-MM-DD');
  isLoading.value = true;
  loaderTitle.value = 'Génération des feuilles de présence en cours';
  try {
    const response = await client.get(`api/certificate/${props.training.id}`, {
      responseType: 'blob',
    });

    if (response.status === 200) {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `f${props.training.id}-attestations-${startDate}.pdf`);
      document.body.appendChild(fileLink);
      fileLink.click();
      isLoading.value = false;
    }
  } catch (error) {
    isLoading.value = false;

    if (error.response && error.response.data instanceof Blob) {
      const reader = new FileReader();
      reader.onload = () => {
        const errorData = JSON.parse(reader.result);
        notificationMessage.value = errorData.error || "Une erreur s'est produite lors de la génération du document.";
      };
      reader.readAsText(error.response.data);
    } else {
      notificationMessage.value = error.message || "Une erreur s'est produite lors de la génération du document.";
    }

    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Erreur';
  }
}

const generateConvocations = async () => {
  const startDate = moment(new Date()).format('YYYY-MM-DD');
  isLoading.value = true;
  loaderTitle.value = 'Génération des convocations en cours';
  try {
    const response = await client.get(`api/trainings/${props.training.id}/convocations`, {
      responseType: 'blob',
    });

    if (response.status === 200) {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `f${props.training.id}-convocations-${startDate}.pdf`);
      document.body.appendChild(fileLink);
      fileLink.click();
      isLoading.value = false;
    }
  } catch (error) {
    isLoading.value = false;

    if (error.response && error.response.data instanceof Blob) {
      const reader = new FileReader();
      reader.onload = () => {
        const errorData = JSON.parse(reader.result);
        notificationMessage.value = errorData.error || "Une erreur s'est produite lors de la génération du document.";
      };
      reader.readAsText(error.response.data);
    } else {
      notificationMessage.value = error.message || "Une erreur s'est produite lors de la génération du document.";
    }

    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Erreur';
  }
}
const sendConvocationMails = async () => {
  isLoading.value = true;
  try {
    const response = await client.post(`api/send-convocations/${props.training.id}`);
    showNotification.value = true;
    notificationType.value = 'success';
    notificationTitle.value = 'Bravo, les emails ont bien été envoyés';
    notificationMessage.value = response.data.message + '\r\n';
    if (response.data.failed_emails.length > 0) {
      response.data.failed_emails.forEach((email) => {
        notificationMessage.value += '\r\n' + email + '\r\n';
      });
    } else {
      setTimeout(() => { showNotification.value = false; }, 3000);
    }
    isLoading.value = false;
    displayConfirmSuccessDialog.value = false;
  } catch (error) {
    showNotification.value = false;
    notificationType.value = 'error';
    notificationTitle.value = 'une erreur s\'est produite';
    notificationMessage.value = '';
  }
}

const sendAttendanceMails = async () => {
  isLoading.value = true;
  try {
    const response = await client.get(`api/send-certificates-email/${props.training.id}`);
    showNotification.value = true;
    notificationType.value = 'success';
    notificationTitle.value = 'Bravo, les emails ont bien été envoyés';
    notificationMessage.value = response.data.message + '\r\n';
    if (response.data.failed_emails.length > 0) {
      response.data.failed_emails.forEach((email) => {
        notificationMessage.value += '\r\n' + email + '\r\n';
      });
    } else {
      setTimeout(() => { showNotification.value = false; }, 3000);
    }
    isLoading.value = false;
    displayConfirmSuccessDialog.value = false;
  } catch (error) {
    showNotification.value = false;
    notificationType.value = 'error';
    notificationTitle.value = 'une erreur s\'est produite';
    notificationMessage.value = '';
  }
}
const sendMails = async () => {
  if (typeSelected.value === 'attestation') {
    sendAttendanceMails();
  } else if (typeSelected.value === 'convocation') {
    sendConvocationMails();
  }
}

const startAction = (action) => {
  if (action.type === 'tab') {
    emit('changeTab', action.href);
  } else if (action.title === 'Exporter les émargements') {
    generateAttendanceSheet();
  } else if (action.title === 'Transmettre les attestations') {
    modalTitle.value = 'Attention, vous allez renvoyer à tous les stagiaires, l\'email pour télécharger son attestation de présence !';
    modalMessage.value = 'Sachez que cet email a déjà été envoyé si vous avez saisi les absences et cliqué sur "Envoyer les attestations". Pour le renvoyer à une personne en particulier, vous pouvez utiliser l\'onglet "Actions par stagiaire". Voulez-vous quand même continuer ?'
    componentKey += 1;
    typeSelected.value = 'attestation';
    textLoader.value = 'Envoi des emails en cours';
    loaderTitle.value = 'Génération des emails en cours';
    displayConfirmSuccessDialog.value = true;
  } else if (action.title === 'Transmettre les convocations') {
    modalTitle.value = 'Attention, vous allez renvoyer à tous les stagiaires, l\'email pour télécharger sa convocation !';
    modalMessage.value = 'Sachez que cet email a déjà été envoyé lors de l\'inscription. Pour le renvoyer à une personne en particulier, vous pouvez utiliser l\'onglet "Actions par stagiaire". Voulez-vous quand même continuer ?'
    componentKey += 1;
    textLoader.value = 'Envoi des emails en cours';
    loaderTitle.value = 'Génération des emails en cours';
    typeSelected.value = 'convocation';
    displayConfirmSuccessDialog.value = true;
  }
  else if (action.title === 'Télécharger les convocations') {
    generateConvocations();
  } else if (action.title === 'Télécharger les attestations de présence') {
    generateAttendances();
  }
};
</script>
