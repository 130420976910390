<template>
  <DashboardLayout>
    <template v-slot:default="{ currentUserName }">
      <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left sm:pl-8">
        <h1 class="text-lg font-semibold leading-6 text-gray-900 mt-12">Bonjour,</h1>
        <h1 class="text-xl sm:text-3xl font-bold leading-tight tracking-tight text-gray-900 mb-2">{{ currentUserName }}
        </h1>
        <p class="text-xl font-bold text-gray-900 sm:text-2xl"></p>
        <p class="text-sm font-medium text-gray-600">{{ currentUserRole }}</p>
      </div>
    </template>

    <template v-slot:primary>
      <ProfileCompletion />
      <MyPastTrainings />
    </template>

    <template v-slot:secondary>
      <MyCurrentTraining :training="currentTraining" :trainingTitle="currentTraining.title"
        :startDate="currentTraining.start_date" :endDate="currentTraining.end_date" v-if="currentTraining"
        :key="componentKey" />
      <MyTrainings />
    </template>
  </DashboardLayout>
</template>

<script setup>
import {
  ref, onMounted, inject,
} from 'vue';
// Administrator components
import DashboardLayout from '@/layouts/DashboardLayout.vue';

// Trainee components
import ProfileCompletion from '@/components/dashboards/trainee/ProfileCompletion.vue';
import MyTrainings from '@/components/dashboards/trainee/MyTrainings.vue';
import MyCurrentTraining from '@/components/dashboards/trainee/MyCurrentTraining.vue';
import MyPastTrainings from '@/components/dashboards/trainee/MyPastTrainings.vue';

const store = inject('store');
const currentUserRole = ref('');
const currentProfile = ref();
const currentTraining = ref();
const componentKey = ref(0);
// const currentUserName = ref('');

onMounted(() => {
  let { user } = store;
  if (!user) {
    user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      store.setUser(user);
    }
  }

  if (user) {
    currentProfile.value = user;
  }
  currentTraining.value = currentProfile.value.current_training;
  componentKey.value += 1;
});
</script>
<style>
.one-col .grid-dahsboard {
  @apply !grid-cols-1;
}
</style>
