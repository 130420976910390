<template>
    <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
        style="z-index:3000; position:absolute" :detail="notificationMessage" @close="showNotification = false" />

    <TransitionRoot as="template" :show="open" style="z-index:2000;">
        <Dialog class="relative z-10" @close="$emit('close')">
            <div class="fixed inset-0" />

            <div class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                        <TransitionChild as="template"
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enter-from="translate-x-full" enter-to="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leave-from="translate-x-0" leave-to="translate-x-full">
                            <DialogPanel class="pointer-events-auto w-screen max-w-md">
                                <div class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                                    <div class="px-4 py-6 sm:px-6 bg-indigo-700">
                                        <div class="flex items-start justify-between">
                                            <h2 id="slide-over-heading"
                                                class="text-base font-semibold leading-6 text-white">Gérer le statut
                                            </h2>

                                            <div class="ml-3 flex h-7 items-center">
                                                <button type="button"
                                                    class="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                    @click="$emit('close')">
                                                    <span class="absolute -inset-2.5" />
                                                    <span class="sr-only">Fermer</span>
                                                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Main -->
                                    <div>
                                        <div class="">
                                            <div>
                                                <div class="relative h-40 sm:h-56"
                                                    v-if="trainee.temporary_profile_picture_url">
                                                    <img class="absolute h-full w-full object-cover"
                                                        :src="trainee.temporary_profile_picture_url" alt="" />
                                                </div>
                                                <div class="mt-6 px-4 sm:mt-8 sm:flex sm:items-end sm:px-6">
                                                    <div class="sm:flex-1">
                                                        <div>
                                                            <div class="flex items-center">
                                                                <h3 class="text-xl font-bold text-gray-900 sm:text-2xl">
                                                                    {{ trainee.name }}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="px-4 pb-5 pt-5 sm:px-0 sm:pt-0">
                                            <div class="px-4 py-6 sm:px-6 flex">
                                                <h3 class="text-xl font-bold text-gray-900 sm:text-2xl">
                                                    {{ trainee.user?.name }}
                                                </h3>
                                                <span
                                                    :class="'ml-auto registrationStatus' + trainee.pivot.registration_status_id">{{
                                                        registrationStatus.name }}</span>

                                            </div>

                                            <div>
                                                <dl class="space-y-8 px-4 sm:space-y-6 sm:px-6">
                                                    <button class="btn-secondary"
                                                        v-if="!isEdit && (new Date() <= new Date(endDate))"
                                                        @click="isEdit = true">modifier</button>
                                                    <Listbox as="div" v-model="selected" v-if="isEdit"
                                                        @update:model-value=" changeRegistration(selected)">
                                                        <ListboxLabel class="sr-only">Change published status
                                                        </ListboxLabel>
                                                        <div class="relative">
                                                            <div
                                                                class="inline-flex divide-x divide-indigo-700 rounded-md shadow-sm">
                                                                <div
                                                                    class="inline-flex items-center gap-x-1.5 rounded-l-md bg-indigo-600 px-3 py-2 text-white shadow-sm">
                                                                    <CheckIcon class="-ml-0.5 h-5 w-5"
                                                                        aria-hidden="true" />
                                                                    <p class="text-sm font-semibold" v-if="selected">{{
                                                                        selected?.title
                                                                    }}
                                                                    </p>
                                                                    <!-- <p v-else>Choisissez un statut</p> -->
                                                                </div>
                                                                <ListboxButton
                                                                    class="inline-flex items-center rounded-l-none rounded-r-md bg-indigo-600 p-2 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 focus:ring-offset-gray-50">
                                                                    <span class="sr-only">Changer le statut
                                                                        publié</span>
                                                                    <ChevronDownIcon class="h-5 w-5 text-white"
                                                                        aria-hidden="true" />
                                                                </ListboxButton>
                                                            </div>

                                                            <transition
                                                                leave-active-class="transition ease-in duration-100"
                                                                leave-from-class="opacity-100"
                                                                leave-to-class="opacity-0">
                                                                <ListboxOptions
                                                                    class="absolute right-0 z-10 mt-2 w-full origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                    <ListboxOption as="template"
                                                                        v-for=" option in publishingOptions "
                                                                        :key="option.title" :value="option"
                                                                        v-slot="{ active, selected }">
                                                                        <li
                                                                            :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'cursor-default select-none p-4 text-sm']">
                                                                            <div class="flex flex-col">
                                                                                <div class="flex justify-between">
                                                                                    <p
                                                                                        :class="selected ? 'font-semibold' : 'font-normal'">
                                                                                        {{ option.title }}</p>
                                                                                    <span v-if="selected"
                                                                                        :class="active ? 'text-white' : 'text-indigo-600'">
                                                                                        <CheckIcon class="h-5 w-5"
                                                                                            aria-hidden="true" />
                                                                                    </span>
                                                                                </div>
                                                                                <p
                                                                                    :class="[active ? 'text-indigo-200' : 'text-gray-500', 'mt-2']">
                                                                                    {{ option.description }}</p>
                                                                            </div>
                                                                        </li>
                                                                    </ListboxOption>
                                                                </ListboxOptions>
                                                            </transition>
                                                        </div>
                                                    </Listbox>
                                                    <SmallLoader v-if="isLoading" :text="'Changement du statut'" />
                                                </dl>
                                                <div class="px-4 pb-5 pt-5 sm:px-0 sm:pt-0"
                                                    v-if="selected?.slug === 'refuse' && isEdit">
                                                    <div class="sm:col-span-3 px-6 mt-5">
                                                        <label for="name"
                                                            class="text-base font-semibold leading-6 text-gray-900">Raison
                                                            du
                                                            refus</label>
                                                        <p class=" mt-1 text-sm leading-5 text-gray-400">Ce champ
                                                            appraîtra dans le
                                                            corps de
                                                            l'email envoyé au stagiaire. Ex: "La formation est
                                                            complète." ou "Vous n'êtes pas inscrit au bon syndicat,
                                                            n'avez pas le bon mandat." </p>
                                                        <div class="mt-2">
                                                            <textarea id="name" name="name" type="text"
                                                                v-model="refusal_reason" autocomplete="name"
                                                                class="mb-10 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                                        </div>
                                                        <button class="btn-primary"
                                                            @click="postRegistration(selected)">Refuser</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="px-4 pb-5 pt-5 sm:px-0 sm:pt-0">
                                                <div class="space-y-8 px-4 sm:space-y-6 sm:px-6 mt-10">
                                                    <dl
                                                        class="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
                                                        <div class="flex justify-between py-3 text-sm font-medium">
                                                            <dt class="text-gray-500">Code postal</dt>
                                                            <dd class="text-gray-900">{{ trainee.profile_zip_code }}
                                                            </dd>
                                                        </div>
                                                        <div class="flex justify-between py-3 text-sm font-medium">
                                                            <dt class="text-gray-500">Email</dt>
                                                            <dd class="text-gray-900">{{ trainee.user.email }}
                                                            </dd>
                                                        </div>
                                                        <div class="flex justify-between py-3 text-sm font-medium">
                                                            <dt class="text-gray-500">Téléphone</dt>
                                                            <dd class="text-gray-900">{{ trainee.phone }}
                                                            </dd>
                                                        </div>
                                                        <div class="flex justify-between py-3 text-sm font-medium">
                                                            <dt class="text-gray-500">Syndicat</dt>
                                                            <dd class="text-gray-900"
                                                                v-if="trainee.is_union_member === 1">
                                                                <span class="status status3">{{
                                                                    trainee.current_union }}</span>
                                                            </dd>
                                                            <dd v-else pas de syndicat></dd>
                                                        </div>
                                                        <div class="flex justify-between py-3 text-sm font-medium">
                                                            <dt class="text-gray-500">Mandats</dt>
                                                            <dd class="text-gray-900 text-right"
                                                                v-if="trainee.has_mandate === 1">
                                                                <div v-for=" mandate in trainee.mandates "
                                                                    :key="mandate.pivot.created_at">
                                                                    <div v-if="mandate.pivot.is_valid">{{
                                                                        mandate.name }}</div>
                                                                </div>
                                                            </dd>
                                                            <dd v-else>pas de mandats</dd>
                                                        </div>{{ trainee.pivot.council_id }}
                                                        <div class="flex justify-between py-3 text-sm font-medium"
                                                            v-if="mandateSelected">
                                                            <dt class="text-gray-500">Conseil Prudhomme</dt>
                                                            <dd class="text-gray-900">
                                                                {{ getCouncil(mandateSelected.pivot?.council_id) }}</dd>
                                                        </div>
                                                        <div class="flex justify-between py-3 text-sm font-medium"
                                                            v-if="mandateSelected">
                                                            <dt class="text-gray-500">Section Prudhomme</dt>
                                                            <dd class="text-gray-900">
                                                                {{
                                                                    getCouncilSection(mandateSelected.pivot?.council_section_id)
                                                                }}</dd>
                                                        </div>
                                                        <div class="flex justify-between py-3 text-sm font-medium"
                                                            v-if="type.name === 'CPH'">
                                                            <dt class="text-gray-500">Ancienneté</dt>
                                                            <dd class="text-gray-900">
                                                                {{ getSeniority(trainee.pivot?.seniority_id) }}</dd>
                                                        </div>
                                                        <div class="flex justify-between py-3 text-sm font-medium"
                                                            v-if="type.name === 'CPH'">
                                                            <dt class="text-gray-500 max-w-64">Nombre de formations
                                                                depuis le
                                                                début du mandat CPH</dt>
                                                            <dd class="text-gray-900">
                                                                {{
                                                                    getTrainingDuration(trainee.pivot?.training_duration_id)
                                                                }}
                                                            </dd>
                                                        </div>
                                                        <div class="flex justify-between py-3 text-sm font-medium">
                                                            <dt class="text-gray-500">Remboursement demandé</dt>
                                                            <dd class="text-gray-900"><span class="status status5"
                                                                    v-if="trainee.pivot?.reimbursed">demandé</span><span
                                                                    v-else class="status status1">non demandé</span>
                                                            </dd>
                                                        </div>
                                                        <div class="flex justify-between py-3 text-sm font-medium">
                                                            <dt class="text-gray-500">Hébergement demandé</dt>
                                                            <dd class="text-gray-900"><span class="status status5"
                                                                    v-if="trainee.pivot?.needs_accommodation">demandé</span><span
                                                                    v-else class="status status1">non demandé</span>
                                                            </dd>
                                                        </div>
                                                        <div class="flex justify-between py-3 text-sm font-medium">
                                                            <dt class="text-gray-500 max-w-64">Profil créé le</dt>
                                                            <dd class="text-gray-900">{{ moment(trainee.user?.created_at
                                                            ).format("Do MMM YYYY") }}</dd>
                                                        </div>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="px-4"><button class="btn-danger mt-10 w-full"
                                            @click.prevent="unregisterTrainee"
                                            v-if="trainingStatus === 3 || trainingStatus === 4">Désinscrire</button>
                                    </div>
                                    <div class="px-4 py-6 sm:px-6"> <router-link :to="'/stagiaires/' + trainee.id"
                                            target="_blank"><button class="btn-secondary">Voir le profil
                                                détaillé</button></router-link></div>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>

                </div>
            </div>

        </Dialog>
    </TransitionRoot>
</template>

<script setup>
/* eslint-disable */
import { ref, defineEmits, defineProps, onMounted } from 'vue'
import { Dialog, DialogPanel, TransitionChild, TransitionRoot, Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { XMarkIcon, CheckIcon, ChevronDownIcon } from '@heroicons/vue/24/outline';
import client from '@/api/client';
import { useRoute } from 'vue-router';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import SmallLoader from '@/components/app/SmallLoader.vue';
import fetchPersonalInfoServices from '@/services/PersonalInfoServices.js';
import moment from 'moment';
import 'moment/locale/fr';

moment.locale('fr');

const open = ref(true);
const route = useRoute();

const publishingOptions = [
    { title: 'Préinscription', description: 'Le stagiaire s\'est préinscrit', slug: 'preinscription' },
    { title: 'En attente', description: 'Le stagiaire est en attente.', slug: 'en_attente' },
    { title: 'Validé', description: 'Le stagiaire va participer à la formation.', slug: 'valide' },
    { title: 'Refusé', description: 'Le stagiaire ne pourra pas participer à la formation.', slug: 'refuse' }
]

const selected = ref(publishingOptions[0]);
const showNotification = ref(false);
const notificationType = ref('');
const notificationTitle = ref('');
const notificationMessage = ref('');
const refusal_reason = ref('');
const registrationStatus = ref(null);
const isEdit = ref(false);
const isLoading = ref(false);

const relevantSeniorities = ref([]);
const relevantTrainingDurations = ref([]);
const relevantCouncils = ref([]);
const relevantCouncilSections = ref([]);
const mandateSelected = ref(null);
const displayConfirmDeleteDialog = ref(false);

const emit = defineEmits(['statusUpdated', 'close', 'unregister'])

const props = defineProps({
    trainee: {
        type: Object,
        required: true
    },
    statuses: {
        type: Array,
        required: true
    },
    type: {
        type: Object,
        required: true
    },
    startDate: {
        type: String,
        required: true
    },
    endDate: {
        type: String,
        required: true
    },
    trainingStatus: [Number, String]
});

const changeRegistration = (status) => {
    if (status.slug !== 'refuse') {
        postRegistration(status);
    }
};

const postRegistration = async (status) => {
    isLoading.value = true;
    try {
        const response = await client.post(`api/trainings/${route.params.id}/update-status`, {
            profile_id: props.trainee.id,
            status: status.slug,
            refusal_reason: refusal_reason.value,
        });
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 4000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié';
        notificationMessage.value = '';
        isLoading.value = false;
        emit('statusUpdated', selected.value.slug);
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de l\'item';
        if (error.response) {
            notificationMessage.value = error.response.data.message;
        }
        isLoading.value = false;
    }
};

const getCouncil = ((id) => {
    const council = relevantCouncils.value.find(item => item.id === id);
    if (council) {
        return council?.name;
    }
    return '';
});

const getCouncilSection = ((id) => {
    const councilSection = relevantCouncilSections.value.find(item => item.id === id);
    if (councilSection) {
        return councilSection?.name;
    }
    return '';
});

const getSeniority = ((id) => {
    const seniority = relevantSeniorities.value.find(item => item.id === id);
    if (seniority) {
        return seniority?.name;
    }
    return '';
});

const getTrainingDuration = ((id) => {
    const trainingDuration = relevantTrainingDurations.value.find(item => item.id === id);
    if (trainingDuration) {
        return trainingDuration?.name;
    }
    return '';
});
const unregisterTrainee = () => {
    emit('close');
    emit('unregister', props.trainee);
};

onMounted(() => {
    registrationStatus.value = props.statuses.find(option => option.id === props.trainee.pivot.registration_status_id);
    if (props.trainee.pivot.registration_status_id === 1 && (new Date() <= new Date(props.endDate))) {
        isEdit.value = true;
    } else {
        isEdit.value = false;
    }
    publishingOptions.forEach(option => {
        const status = props.statuses.find(status => status.slug === option.slug);
        if (status) {
            option.id = status.id;
        }
    });
    selected.value = publishingOptions.find(option => option.slug === registrationStatus.value.slug);
    if (props.type.name === 'CPH') {
        fetchPersonalInfoServices.fetchSeniorities().then((data) => {
            relevantSeniorities.value = data;
        });
        fetchPersonalInfoServices.fetchTrainingDurations().then((data) => {
            relevantTrainingDurations.value = data;
        });
        fetchPersonalInfoServices.fetchCouncils().then((data) => {
            relevantCouncils.value = data;
        });
        fetchPersonalInfoServices.fetchCouncilSections().then((data) => {
            relevantCouncilSections.value = data;
        });
        for (const index in props.trainee.mandates) {
            if (props.trainee.mandates[index].name === 'Conseiller Prud’hommes' && (props.trainee.mandates[index].pivot.is_valid === 1)) {
                mandateSelected.value = props.trainee.mandates[index];
                return true;
            }
        }
    }
});
</script>
