<template>
  <BigLoader v-if="isLoading" title="Chargement des intervenants" text="" />
  <AppLayout>
    <TeachersListFilters v-model="paramsSearchTrainings" :show="displayTeachersFilters"
      style="z-index: 1000; position:absolute" @close="closeFiltersPanel" :key="componentKey" @clear="clearFilters"
      @filter="filterTrainings" />
    <div class="px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-base font-semibold leading-6 text-gray-900">Formateurs</h1>
          <p class="mt-2 text-sm text-gray-700">Liste des formateurs par formation.</p>
        </div>
        <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <div class="flex gap-x-1.5">
            <button @click="clearFilters" class="btn-secondary" v-if="!isFilterActive">Effacer</button>
            <button type="button" @click="displayTeachersFilters = !displayTeachersFilters" :class="[
              'inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm',
              'hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
              !isFilterActive ? 'bg-green-600 hover:bg-green-500 focus-visible:outline-green-600' : 'btn-primary'
            ]">
              <template v-if="!isFilterActive">
                <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                <span>Filtres actifs</span>
              </template>
              <template v-else>
                <AdjustmentsHorizontalIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                <span>Filtrer</span>
              </template>
            </button>
          </div>
        </div>
      </div>

      <div class="mt-8 flow-root">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table class="min-w-full">
              <thead class="bg-white">
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">Nom
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Rôle</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">CM
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">TD
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">HETD
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">CM
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">TD
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">HETD
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Statut ARES</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Rémunéré</th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-3">
                    <span class="sr-only">Détail</span>
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white">
                <template v-for="training in relevantTrainings" :key="training.title">
                  <tr class="border-t border-gray-200">
                    <th colspan="11" scope="colgroup"
                      class="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3 hover:text-indigo-600">
                      <router-link :to="'/formations/' + training.training_id">{{
                        training.training_name }}</router-link>
                      <div class="flex flex-wrap font-normal text-indigo-700">
                        <div> {{ moment(training.start_date).format("Do MMM YY") }}</div>
                        <div> - {{ moment(training.end_date).format("Do MMM YY") }}</div>
                      </div>
                    </th>
                  </tr>
                  <tr v-for="(teacher, teacherIdx) in training.staff" :key="teacher.email"
                    :class="[teacherIdx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t']">
                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                      <div class="font-medium text-gray-900">{{ teacher.profile.user?.name }}</div>
                      <div class="mt-1 truncate text-gray-500">{{ teacher.profile.user?.email }}</div>
                      <div class="mt-1 truncate text-gray-500">{{ teacher.profile.phone }}</div>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"> <span
                        class="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10"
                        v-if="teacher.role.slug === 'intervenant'">
                        {{ teacher.profile.role?.name }}</span>
                      <span v-else
                        class="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10">
                        {{ teacher.profile.role?.name }}</span>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <span
                        class="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">{{
                          teacher.expected_lecture_hours }}</span>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <span
                        class="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">{{
                          teacher.expected_tutorial_hours }}</span>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <span
                        class="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
                        {{ hetd(teacher.expected_lecture_hours, teacher.expected_tutorial_hours) || '-' }}</span>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <span :class="[
                        teacher.actual_lecture_hours ? 'inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20' : 'inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600',
                        teacher.actual_lecture_hours ? '' : 'text-gray-400'
                      ]">
                        {{ teacher.actual_lecture_hours }}
                      </span>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <span :class="[
                        teacher.actual_tutorial_hours ? 'inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20' : 'inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600',
                        teacher.actual_tutorial_hours ? '' : 'text-gray-400'
                      ]">
                        {{ teacher.actual_tutorial_hours }}
                      </span>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <span :class="[
                        teacher.actual_tutorial_hours ? 'inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20' : 'inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600',
                        teacher.actual_tutorial_hours ? '' : 'text-gray-400'
                      ]">
                        {{ hetd(teacher.actual_lecture_hours, teacher.actual_tutorial_hours) || '-' }}
                      </span>
                    </td>

                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                      v-if="teacher.profile.training_teacher_ares_status">
                      <span :class="'aresStatus aresStatus' + teacher.profile.training_teacher_ares_status[0]?.id"
                        v-if="teacher.profile.training_teacher_ares_status[0]?.id">
                        {{ teacher.profile.training_teacher_ares_status[0]?.name }}
                      </span>
                      <span v-else class="aresStatus aresStatus0">non renseigné</span>
                    </td>
                    <td v-if="teacher.profile.training_pedagogical_supervisor_ares_status"><span
                        v-if="teacher.profile.training_pedagogical_supervisor_ares_status[0]?.id"
                        :class="'aresStatus aresStatus' + teacher.profile.training_pedagogical_supervisor_ares_status[0]?.id">
                        {{ teacher.profile.training_pedagogical_supervisor_ares_status[0]?.name }}
                      </span><span class="aresStatus aresStatus0" v-else>non renseigné</span>
                    </td>
                    <td>
                      <span v-if="teacher.profile?.role?.slug === 'intervenant'"><span
                          v-if="teacher.profile?.pivot?.is_remunerated === 1"
                          class="status status5">rémunéré</span><span v-else class="status status1">Non rémunéré</span>
                      </span> <span v-else>-</span>
                    </td>
                    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                      <router-link :to="'/formateurs/' + teacher.profile.id"><button
                          class="text-indigo-600 hover:text-indigo-900">Détail<span class="sr-only">,
                            {{
                              teacher.user.name
                            }}</span></button></router-link>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <Pagination :current-page="currentPage" :total-pages="totalPages" @change-page="changePage" />
  </AppLayout>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, computed } from 'vue';
import AppLayout from '@/layouts/AppLayout.vue';
import Pagination from '@/components/lists/PaginationTables.vue';
import TeachersListFilters from '@/components/teachers/TeachersListFilters.vue';
import { CheckCircleIcon, AdjustmentsHorizontalIcon } from '@heroicons/vue/20/solid';
import { useRouter } from 'vue-router';
import client from '@/api/client';
import moment from 'moment';
import 'moment/locale/fr';
import BigLoader from '@/components/app/BigLoader.vue';
// import fetchTrainingsServices from '@/services/TrainingsServices.js';

moment.locale('fr');

const router = useRouter();

const currentPage = ref(1);
const totalPages = ref(1);
const totalItems = ref(0);
const pageSize = ref(20);
const componentKey = ref(0);
const isLoading = ref(false);
const profile = ref(null);
const aresStatuses = ref([]);

const displayTeachersFilters = ref(false);

const closeFiltersPanel = () => {
  displayTeachersFilters.value = false;
};
const relevantTrainings = ref([]);
const paramsSearchTrainings = ref({
  training_id: null,
  profile_id: null,
  startDate: null,
  endDate: null,
  ares_status_ids: null,
});

const hetd = ((cm, td) => {
  let x = (cm * 1.5) + td;
  let res = x.toFixed(2);
  return res;
});

const clearFilters = () => {
  paramsSearchTrainings.value = {
    training_id: null,
    profile_id: null,
    startDate: null,
    endDate: null,
    ares_status_ids: null,
  };
  componentKey.value += 1;
  changePage(1);
};
const isFilterActive = computed(() => {
  return Object.values(paramsSearchTrainings.value).every(value => value === null);
});
const changePage = async (page) => {
  fetchTrainings(page, paramsSearchTrainings);
};

const filterTrainings = (filters) => {
  console.log('filters', filters);
  fetchTrainings(1, filters);
};

const fetchTrainings = async (page, paramsSearch) => {
  isLoading.value = true;
  const params = new URLSearchParams();
  try {
    params.append('current_page', page);
    params.append('per_page', pageSize.value);
    if (paramsSearch.training_id) {
      console.log('paramsSearch.training_id', paramsSearch);
      params.append('training_id', paramsSearch.training_id);
    }
    if (paramsSearch.profile_id) {
      params.append('profile_id', paramsSearch.profile_id);
    }
    if (paramsSearch.start_date) {
      params.append('start_date', paramsSearch.start_date);
    }
    if (paramsSearch.end_date) {
      params.append('end_date', paramsSearch.end_date);
    }
    if (paramsSearch.ares_status_ids) {
      params.append('ares_status_ids[]', [paramsSearch.ares_status_ids]);
    }
    const response = await client.get(`api/trainings/with-staff?${params}`);
    relevantTrainings.value = response.data.data;
    currentPage.value = page;
    totalPages.value = Math.ceil(response.data.total / pageSize.value);
    totalItems.value = response.data.total;
    isLoading.value = false;
  } catch (error) {
    console.error('Failed to fetch training details:', error);
  }
};

onMounted(() => {
  fetchTrainings(1, paramsSearchTrainings.value);
  profile.value = JSON.parse(localStorage.getItem('user'));
});

</script>