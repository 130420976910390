<template>
  <TransitionRoot :show="open" as="template" @after-leave="query = ''" appear>
    <Dialog class="relative z-10" @close="$emit('close')">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto py-20 px-4 sm:py-20 sm:px-6 md:p-20">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100" leave="ease-in duration-200" leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95">
          <DialogPanel
            class="mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
            <Combobox v-slot="{ activeOption }">
              <div class="relative">
                <MagnifyingGlassIcon class="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                  aria-hidden="true" />
                <ComboboxInput
                  class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                  placeholder="Rechercher un profil..."
                  @change="query = $event.target.value; searchProfile($event.target.value)" @blur="query = ''" />
              </div>

              <ComboboxOptions v-if="query === '' || relevantResults.length > 0"
                class="flex transform-gpu divide-x divide-gray-100" as="div" static hold>
                <div
                  :class="['max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4', activeOption && 'sm:h-96']">
                  <div hold class="-mx-2 text-sm text-gray-700">
                    <ComboboxOption v-for="person in query === '' ? relevantResults : relevantResults" :key="person.id"
                      :value="person" as="template" v-slot="{ active }">
                      <div
                        :class="['group flex cursor-default select-none items-center rounded-md p-2', active && 'bg-gray-100 text-gray-900']">
                        <img v-if="person?.temporary_profile_picture_url" :src="person?.temporary_profile_picture_url"
                          alt="" class="h-6 w-6 flex-none rounded-full object-cover" />
                        <UserCircleIcon class="h-6 w-6 text-gray-400" v-else />
                        <span class="ml-3 flex-auto truncate">{{ person.user?.name }}</span>
                        <ChevronRightIcon v-if="active" class="ml-3 h-5 w-5 flex-none text-gray-400"
                          aria-hidden="true" />
                      </div>
                    </ComboboxOption>
                  </div>
                </div>

                <div v-if="activeOption"
                  class="hidden h-96 w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex">
                  <div class="flex-none p-6 text-center">
                    <img v-if="activeOption?.temporary_profile_picture_url"
                      :src="activeOption?.temporary_profile_picture_url" alt=""
                      class="mx-auto h-16 w-16 rounded-full object-cover" />
                    <UserCircleIcon class="mx-auto h-16 w-16 text-gray-400" v-else />
                    <h2 class="mt-3 font-semibold text-gray-900">
                      {{ activeOption.user?.name }}
                    </h2>
                    <p class="text-sm"> {{ activeOption.profile_number }}</p>
                    <p class="text-sm leading-6 text-gray-500 mt-2">
                      <span class="status status3">{{ activeOption.role?.name }} ({{ activeOption.is_active ? 'actif' :
                        'inactif' }})</span>
                    </p>
                  </div>
                  <div class="flex flex-auto flex-col justify-between p-6">
                    <dl class="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">
                      <dt class="col-end-1 font-semibold text-gray-900">Téléphone</dt>
                      <dd>{{ activeOption.phone }}</dd>
                      <dt class="col-end-1 font-semibold text-gray-900">Email</dt>
                      <dd class="truncate">
                        <a :href="`mailto:${activeOption.user.email}`" class="text-indigo-600 underline">
                          {{ activeOption.user?.email }}
                        </a>
                      </dd>
                    </dl>
                    <button type="button" @click="goToProfile(activeOption)"
                      class="mt-6 w-full rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Voir
                      le profil</button>
                  </div>
                </div>
              </ComboboxOptions>

              <div v-if="query !== '' && relevantResults.length === 0" class="px-6 py-14 text-center text-sm sm:px-14">
                <UsersIcon class="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                <p class="mt-4 font-semibold text-gray-900">Pas de résultats</p>
                <p class="mt-2 text-gray-500">Veuillez réesayer.</p>
              </div>
            </Combobox>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
/* eslint-disable */
import { computed, ref, onMounted } from 'vue'
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid'
import { ChevronRightIcon, UsersIcon, UserCircleIcon } from '@heroicons/vue/24/outline'
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { debounce } from 'lodash';
import { useRouter } from 'vue-router';
import client from '@/api/client';
import moment from 'moment';
import 'moment/locale/fr';

moment.locale('fr');

const router = useRouter();
const emit = defineEmits(['close']);
const props = defineProps({
  context: [String],
});
const open = ref(true);
const query = ref('');
const relevantResults = ref([]);
const roleId = ref([]);

const goToProfile = (item) => {
  if (props.context === 'trainees') {
    router.push(`/stagiaires/${item.id}`);
  } else if (props.context === 'teachers') {
    router.push(`/formateurs/${item.id}`);
  } else if (props.context === 'team') {
    router.push(`/equipe/${item.id}`);
  }
}

const searchProfile = debounce(async (keyword) => {
  if (props.context === 'trainees') {
    roleId.value = [5];
  } else if (props.context === 'teachers') {
    roleId.value = [3, 4];
  } else if (props.context === 'team') {
    roleId.value = [1, 2];
  }
  let profileNumber = '';
  let name = '';
  if (keyword.includes('20')) {
    profileNumber = `&profile_number=${keyword}`;
  } else {
    name = `&name=${keyword}`;
  }
  try {
    const response = await client.get(`/api/profiles-search?${name}&role_ids=[${roleId.value}]${profileNumber}`);
    relevantResults.value = response.data;
  } catch (error) {
    console.error('Failed to fetch structures details:', error);
  }
}, 600);

onMounted(() => {
  open.value = true;
});
</script>