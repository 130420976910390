<template>
  <AppLayout>
    <template v-slot:default="{}">
      <div>
        <div class="md:flex md:items-center md:justify-between">
          <div class="min-w-0 flex w-full">
            <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Éditer le
              référentiel</h2>
          </div>
        </div>
      </div>
      <div class="mx-auto pt-10 lg:flex lg:gap-x-16 lg:px-8">
        <aside
          class="overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20">
          <div id="registration">
            <h2 class="text-base font-semibold leading-7 text-gray-900">{{ secondaryNavigation[0].name }}</h2>
            <p class="mt-1 text-sm leading-6 text-gray-500">{{ secondaryNavigation[0].description }}</p>
            <ul class="text-sm space-y-2 lg:space-y-5 divide-y divide-gray-20">
              <li class="">
                <div class="pt-2 lg:pt-5">
                  <button
                    class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 text-left hover:text-indigo-600 hover:underline"
                    @click="typeSelected = 'referral-sources'; componentKey += 1">Connaissance de l'IRT</button>
                </div>
              </li>
            </ul>
          </div>
          <div id="trainee" class="mt-10 lg:mt-20">
            <h2 class="text-base font-semibold leading-7 text-gray-900">{{ secondaryNavigation[1].name }}</h2>
            <p class="mt-1 text-sm leading-6 text-gray-500">{{ secondaryNavigation[1].description }}</p>
            <ul class="text-sm space-y-2 lg:space-y-5 divide-y divide-gray-20">
              <li class="">
                <div class="pt-2 lg:pt-5">
                  <button
                    class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 text-left hover:text-indigo-600 hover:underline"
                    @click="typeSelected = 'degrees'; componentKey += 1">Diplôme</button>
                </div>
              </li>
              <li class="">
                <div class="pt-2 lg:pt-5">
                  <button
                    class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 text-left hover:text-indigo-600 hover:underline"
                    @click="typeSelected = 'professional-categories'; componentKey += 1">Catégorie
                    professionnelle</button>
                </div>
              </li>
              <li class="">
                <div class="pt-2 lg:pt-5">
                  <button
                    class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 text-left hover:text-indigo-600 hover:underline"
                    @click="typeSelected = 'activity-sectors'; componentKey += 1">Secteur professionnel </button>
                </div>
              </li>
              <li class="">
                <div class="pt-2 lg:pt-5">
                  <button
                    class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 text-left hover:text-indigo-600 hover:underline"
                    @click="typeSelected = 'professional-situations'; componentKey += 1">Situation
                    professionnelle</button>
                </div>
              </li>
              <li class="">
                <div class="pt-2 lg:pt-5">
                  <button
                    class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 text-left hover:text-indigo-600 hover:underline"
                    @click="typeSelected = 'company-sizes'; componentKey += 1">Taille de l'entreprise</button>
                </div>
              </li>
              <li class="">
                <div class="pt-2 lg:pt-5">
                  <button
                    class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 text-left hover:text-indigo-600 hover:underline"
                    @click="typeSelected = 'council-sections'; componentKey += 1">Section prud'homme</button>
                </div>
              </li>
              <li class="">
                <div class="pt-2 lg:pt-5">
                  <button
                    class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 text-left hover:text-indigo-600 hover:underline"
                    @click="typeSelected = 'councils'; componentKey += 1">Conseil prud'homme</button>
                </div>
              </li>
              <li class="">
                <div class="pt-2 lg:pt-5">
                  <button
                    class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 text-left hover:text-indigo-600 hover:underline"
                    @click="typeSelected = 'mandates'; componentKey += 1">Mandats</button>
                </div>
              </li>
            </ul>

          </div>
          <div id="trainings" class="mt-10 lg:mt-20">
            <h2 class="text-base font-semibold leading-7 text-gray-900">{{ secondaryNavigation[2].name }}</h2>
            <p class="mt-1 text-sm leading-6 text-gray-500">{{ secondaryNavigation[2].description }}</p>
            <ul class="text-sm space-y-2 lg:space-y-5 divide-y divide-gray-20">
              <li class="">
                <div class="pt-2 lg:pt-5">
                  <button
                    class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 text-left hover:text-indigo-600 hover:underline"
                    @click="typeSelected = 'training-themes'; componentKey += 1">Thématique</button>

                </div>
              </li>
              <li class="">
                <div class="pt-2 lg:pt-5">
                  <button
                    class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 text-left hover:text-indigo-600 hover:underline"
                    @click="typeSelected = 'training-categories'; componentKey += 1">Catégorie de formation - Modalités
                    de
                    défraiement</button>

                </div>
              </li>
              <li class="">
                <div class="pt-2 lg:pt-5">
                  <button
                    class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 text-left hover:text-indigo-600 hover:underline"
                    @click="typeSelected = 'training-places'; componentKey += 1">Lieu</button>

                </div>
              </li>
            </ul>
          </div>
        </aside>

        <main class="px-4 py-16 sm:px-4 lg:flex-auto lg:px-0 lg:py-20">
          <div class="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
            <ReferentialEditionForm :type="typeSelected" :key="componentKey" />
          </div>
        </main>
      </div>
    </template>
  </AppLayout>
</template>

<script setup>
/* eslint-disable */
import { ref, computed, onMounted, inject } from 'vue';
import AppLayout from '@/layouts/AppLayout.vue';
import ReferentialEditionForm from '@/components/referential/ReferentialEditionForm.vue';

import {
  UserCircleIcon,
  BriefcaseIcon,
  HomeIcon,
} from '@heroicons/vue/24/outline';


const typeSelected = ref('');
const componentKey = ref(0);

const secondaryNavigation = [
  {
    name: 'Inscription', description: '', href: '#registration', icon: UserCircleIcon, current: true, auth: ['responsable-pedagogique', 'intervenant', 'administrateur', 'gestionnaire', 'stagiaire']
  },
  {
    name: 'Profil stagiaire', href: '#trainee', description: '', icon: HomeIcon, current: false, auth: ['responsable-pedagogique', 'intervenant', 'administrateur', 'gestionnaire', 'stagiaire']
  },
  {
    name: 'Formations', description: '', href: '#trainings', icon: BriefcaseIcon, current: false, auth: ['stagiaire']
  },
];


onMounted(async () => {
  typeSelected.value = 'referral-sources';
  componentKey.value += 1;
});

</script>
