<template>
  <DashboardLayout>
    <template v-slot:default="{ currentUserName }">
      <div class="md:flex items-center w-full">
        <div class="mt-4 text-center md:mt-0 md:pt-1 md:text-left sm:pl-8">
          <h1 class="text-lg font-semibold leading-6 text-gray-900 mt-12">Bonjour,</h1>
          <h1 class="text-xl sm:text-3xl font-bold leading-tight tracking-tight text-gray-900 px-4 md:px-0 mb-2">{{
            currentUserName
          }}
          </h1>
          <p class="text-xl font-bold text-gray-900 sm:text-2xl"></p>
          <p class="text-sm font-medium text-gray-600">{{ currentUserRole }}</p>
        </div>
        <div class="mx-auto text-center md:ml-auto md:mr-0 md:pr-10">
          <div class="mt-3 sm:ml-4 sm:mt-3">
            <button type="button" @click="gotoTeacherCreation(4)"
              class="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
              <UserIcon class="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              <span>Créer un intervenant</span>
            </button>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:primary>
      <TrainingsHours :title="'Formations prévues dans 3 mois sans intervenant'" :type="'teachers'" />
      <div class="my-20">
        <TrainingsHours :title="'Formations prévues dans 3 mois sans heures saisies'" :type="'hours'" />
      </div>
    </template>

    <template v-slot:secondary>
      <TrainingsHours :title="'Formations passées sans heures saisies'" :type="'past_hours'" />
    </template>
  </DashboardLayout>
</template>

<script setup>
import {
  ref, onMounted, inject,
} from 'vue';

import DashboardLayout from '@/layouts/DashboardLayout.vue';
import TrainingsHours from '@/components/dashboards/leadTeacher/TrainingsHours.vue';
import { UserIcon } from '@heroicons/vue/24/outline';
import { useRouter } from 'vue-router';

const store = inject('store');
const router = useRouter();
const currentUserName = ref('');
const currentUserRole = ref('');

const gotoTeacherCreation = (idRole) => {
  console.log('idRole', idRole);
  router.push({ name: 'Créer un membre de l\'équipe', params: { roleId: idRole } });
};

onMounted(() => {
  let { user } = store;
  if (!user) {
    user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      store.setUser(user);
    }
  }

  if (user) {
    currentUserName.value = user.username;
    currentUserRole.value = user.role.name;
  }
});
</script>
