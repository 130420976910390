<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog class="relative z-10">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class="relative transform overflow-hidden  rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6">
              <div>
                <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                  <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle as="h4" class="text-base font-semibold leading-6 text-gray-900">
                    Conditions d'utilisation
                  </DialogTitle>
                  <div class="mt-2 max-h-[60vh] overflow-y-auto text-sm text-gray-500 text-left">
                    <h2>Mentions légales et conditions générales d’utilisation</h2>

                    <h3>Mentions d’identification</h3>

                    <p><strong>Propriétaire & hébergeur</strong></p>
                    <ul class="!list-none">
                      <li>Dénomination sociale de l’entreprise : Aix-Marseille Université</li>
                      <li>Numéro de SIRET : 130 015 332 00013</li>
                      <li>Forme juridique : établissement public national à caractère scientifique culturel et
                        professionnel</li>
                      <li>Adresse du siège social : Jardin du Pharo, 58 bd Charles Livon - 13284 Marseille Cedex 07
                      </li>
                      <p class="mt-5"><strong>Hébergeur :</strong></p>
                      <ul class="!list-none">
                        <li>GANDI SAS, Société par Actions Simplifiée au capital de 630000 € </li>
                        <li>63-65 boulevard Masséna Paris (75013) FRANCE</li>
                        <li>Siren 423 093 459 RCS PARIS</li>
                        <li>n. de TVA FR81423093459</li>
                        <li>Tel +33 (0) 1 70.37.76.61</li>
                        <li>Fax +33. (0) 1 43 73 18 51</li>
                      </ul>
                    </ul>

                    <h3>Conditions générales d’utilisation</h3>

                    <h4>Contenu éditorial et mises à jour</h4>
                    <p>L’IRT contrôle en permanence le contenu de son Site Internet. Mais les informations mises à
                      disposition sur le Site le sont toutefois sans aucune garantie du caractère exhaustif et de
                      l'exactitude de ces informations, qu'elles soient explicites ou implicites.</p>
                    <p>Les informations et recommandations ("Informations") disponibles sur ce Site vous sont proposées
                      en toute bonne foi. Ces informations sont censées être correctes au moment où vous en prenez
                      connaissance. Les informations peuvent toutefois contenir des erreurs ou des imprécisions pour
                      lesquelles l’IRT ne sera pas tenue pour responsable. En effet, en aucun cas l’IRT ne sera
                      responsable des dommages susceptibles de résulter du crédit accordé à ces informations, de leur
                      utilisation ou de l'utilisation d'un produit auquel elles font référence.</p>
                    <p>L’IRT se réserve le droit de modifier ou de corriger le contenu du Site à tout moment et sans
                      préavis.</p>

                    <h4>Droit d’auteur</h4>
                    <p>Toutes les informations ou documents contenus dans le Site ainsi que tous les éléments créés pour
                      le Site sont la propriété de l’IRT.</p>
                    <p>Ces informations, documents ou éléments (appelés collectivement : « contenus ») sont soumis aux
                      lois protégeant le droit d'auteur dès lors qu'ils sont mis à la disposition du public sur ce Site.
                    </p>
                    <p>Les copies des documents contenus dans ce Site ne peuvent être faites qu'à titre d'information et
                      exclusivement pour un seul usage strictement privé. Aucune licence ni aucun droit autre que celui
                      de consulter le Site n'est conféré à quiconque au regard des droits de propriété intellectuelle.
                    </p>
                    <p>La reproduction des documents du Site est autorisée à des fins exclusives d'information pour un
                      usage personnel et privé. Toute reproduction et toute utilisation de copie réalisée à d'autres
                      fins sont expressément interdites et soumises à l'autorisation préalable et expresse de la société
                      éditrice du Site.</p>

                    <h4>Clause d’exclusion de responsabilité</h4>
                    <p>L’IRT ne garantit pas que :</p>
                    <ul class="list-disc">
                      <li>le Service répondra aux exigences des Utilisateurs ;</li>
                      <li>le Service fonctionnera de manière ininterrompue, rapide, sécurisée ou sans erreurs ;</li>
                      <li>les résultats qui peuvent être obtenus en utilisant le Service seront précis ou fiables ;</li>
                      <li>la qualité des produits, services, informations ou autres supports achetés ou obtenus par les
                        Utilisateurs par l’intermédiaire du Service répondra à leurs attentes ;</li>
                      <li>les erreurs dans le logiciel seront corrigées.</li>
                    </ul>
                    <p>Tout support téléchargé ou obtenu par le biais du service est réalisé à la discrétion et aux
                      risques des utilisateurs. Par ailleurs, l’Utilisateur sera seul responsable des dommages causés à
                      ses systèmes informatiques ou des pertes de données résultant du téléchargement de ces supports ;
                    </p>
                    <p>Aucun conseil ou renseignement transmis oralement ou par écrit par l’IRT aux Utilisateurs ou par
                      le biais du service ne crée une quelconque garantie qui n’est pas expressément stipulée sur ce
                      Site.</p>
                    <p>Les Utilisateurs comprennent et acceptent expressément que l’IRT ne peut être tenue responsable
                      des dommages directs, indirects, accessoires, spéciaux, consécutifs ou exemplaires, y compris sans
                      s’y limiter les dommages pour perte de profit, d’utilisation, de données ou d’autres pertes
                      immatérielles (même si l’IRT a été informée de la possibilité de ces dommages) encourus par les
                      Utilisateurs ou par un tiers, que ce soit dans une action contractuelle ou délictuelle, découlant
                      de leur accès à ou de l’utilisation de ce Site ou d’un site Internet référencé par un hyperlien et
                      qui sont causés par :</p>
                    <ul>
                      <li>L’utilisation ou l’incapacité d’utiliser le Service ;</li>
                      <li>Le coût d’acquisition de biens et de services de substitution résultant de biens, données,
                        informations ou services achetés ou obtenus, ou de messages reçus, ou d’opérations effectuées
                        via ou depuis le Service ;</li>
                      <li>Un accès ou une modification non autorisés des transmissions ou données des Utilisateurs ;
                      </li>
                      <li>Des déclarations ou le comportement d’un tiers sur le Service ;</li>
                      <li>Toute autre question ayant trait au Service.</li>
                    </ul>
                    <p>La visite sur notre site Internet ou sur d'autres parties liées à notre site implique que vous
                      acceptiez ces termes et conditions. Si vous ne souhaitez pas accepter ces termes et conditions,
                      nous vous prions de renoncer à la visite de notre site et de n'y télécharger aucun contenu.</p>

                    <h2>Mentions relatives à l’utilisation des données personnelles et la gestion des cookies</h2>

                    <h4>Cookies</h4>
                    <ul>
                      <li><strong>Cookies nécessaires :</strong> Seuls des cookies fonctionnels nécessaires au bon
                        fonctionnement de Formations IRT sont déposés sur le terminal de l’utilisateur. Ils ne sont donc
                        pas soumis au consentement de l'utilisateur en vertu de l’article 82 de la loi Informatique et
                        Libertés. => si consentement pour la newsletter.</li>
                    </ul>

                    <h4>Données personnelles</h4>

                    <ul class="personal list-none">
                      <li><strong>Données enregistrées :</strong> nom, prénom, année de naissance, coordonnées postales,
                        coordonnées bancaires, mandat.</li>
                      <li><strong>Destinataires des données :</strong> Les données personnelles sont utilisées à des
                        fins règlementaires de contrôles financiers exigés par l’université ainsi que par les bailleurs
                        de fonds qui octroient des subventions permettant à l’IRT de réaliser ses missions dans le cadre
                        de l’agrément délivré par le ministère du travail. Elles ne font l’objet d’aucun autre
                        traitement et ne sont pas délivrées à d’autres tiers que ceux mentionnés ci-dessous.</li>
                      <li><strong>Traitement des données :</strong>
                        <p>Le traitement de ces données est réalisé uniquement
                          par des personnes de l’Institut Régional du Travail dûment habilitées.
                          Le traitement de ces données est réalisé uniquement par des personnes de l’Institut Régional
                          du
                          Travail dûment habilitées.</p>
                        <p>Les informations recueillies font l’objet d’un traitement informatique destiné à gérer les
                          inscriptions aux événements (formations, rencontres scientifiques) proposés par les URFIST en
                          tant que responsable de traitement. Le traitement repose sur l’obligation légale du
                          responsable
                          de traitement pour assurer les obligations liées au secteur de l’enseignement et de la
                          formation.</p>
                        <p>Elles sont nécessaires au processus d’inscription aux événements et au suivi de cette
                          inscription :</p>
                        <ul class="list-disc list-simple">
                          <li>Envoi de mél(s) pour informer le ou la stagiaire de la suite qui est donnée à sa demande
                            d’inscription</li>
                          <li>Envoi d’une convocation en cas d’acceptation de l’inscription
                          </li>
                          <li class="mb-4">Production des documents liés à l’événement :
                            <ul class="ml-4 mt-2 mb-4">
                              <li>Convocation</li>
                              <li>Feuille d’émargement,</li>
                              <li>Attestation de présence</li>
                            </ul>
                          </li>

                        </ul>
                        Ce traitement des données est obligatoire et si le ou la stagiaire refuse, il ou elle ne pourra
                        pas
                        s’inscrire aux événements organisés par l’Institut Régional du Travail.
                        <p>Les informations recueillies peuvent également servir à communiquer avec les publics inscrits
                          dans
                          la base Formations IRT sur des événements auxquels ils ne sont pas inscrits mais dont
                          l’Institut
                          Régional du Travail, en fonction de leur profil, estime qu’ils pourraient les intéresser. Ce
                          traitement est, quant à lui, facultatif et peut être modifié à partir de son espace personnel
                          à
                          tout
                          moment et les personnes disposent de la faculté de s’opposer à l'envoi de telles
                          communications
                          en,
                          utilisant le lien de désabonnement ou en contact à irt-contact@univ-amu.fr.</p>

                      </li>
                      <li><strong>Durée de conservation :</strong> Les données personnelles sont conservées 3 années
                        suivant la clôture du compte. La suppression des comptes stagiaires et formateurs est fixée à
                        3ans
                        d’inactivité à partir de la date de la dernière formation à laquelle le stagiaire a formulé une
                        demande d’inscription (quel que soit le statut de l’inscription) ou à laquelle l’intervenant a
                        participé.</li>
                      <li><strong>Droit d’accès, de modification et de suppression :</strong> Tout utilisateur du site
                        dispose d’un accès à ses données avec la possibilité de rectification ou d’effacement sur simple
                        demande à irt-contact@univ-amu.fr.</li>
                    </ul>

                    <p>En conformité avec le règlement européen (UE) 2016/679 du 27 avril 2016 (RGPD) et de la loi
                      Informatique et libertés du 6 janvier 1978 modifiée, l’utilisateur peut saisir l’équipe DPO d’AMU
                      en cliquant sur le lien suivant : <a href="https://www.univ-amu.fr/saisir-dpo" target="_blank"
                        class="text-indigo-700 hover:text-indigo-900 underline underline-offset-4">Saisine DPO</a>.
                    </p>
                    <p>Enfin, si l’utilisateur estime que ses droits ne sont pas respectés, il dispose du droit
                      d’introduire une réclamation auprès de la Commission nationale de l’informatique et des libertés
                      (CNIL) par courrier – 3 place de Fontenoy – TSA 80715- 75334 PARIS CEDEX 07 ou sur son site
                      internet : <a href="https://www.cnil.fr/fr/plaintes" target="_blank"
                        class="text-indigo-700 hover:text-indigo-900 underline underline-offset-4">Saisir la CNIL</a>.
                    </p>

                    <!-- Todo: add other parts -->
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <button type="button"
                  class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                  @click="registerTermsAcceptance">
                  J'accepte
                </button>
                <button type="button"
                  class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                  @click="closeModal">
                  Je refuse
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {
  Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { CheckIcon } from '@heroicons/vue/24/outline';
import { useRouter } from 'vue-router';
import store from '@/store/store';
import client from '@/api/client';

defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close']);
const router = useRouter();

// Accept terms and conditions
const registerTermsAcceptance = async () => {
  try {
    await client.post('/api/profile/accept-terms');
    emit('close');
    store.fetchUserProfile();
  } catch (error) {
    console.error("Échec de l'enregistrement de l'acceptation des conditions :", error);
  }
};

// Close modal and call logout method
const closeModal = async () => {
  emit('close');
  store.logOutUser(router);
};

</script>
<style scoped>
h2 {
  @apply text-xl font-semibold text-indigo-700 mb-3 mt-10;
}

h3 {
  @apply text-lg font-semibold text-gray-900 mb-2 mt-7;
}

h4 {
  @apply text-base font-semibold text-gray-900 mb-1 mt-5;
}

ul {
  /* list-style: unset; */
  @apply list-disc list-inside mb-1;
}

p {
  @apply mb-2;
}

.personal li {
  @apply mb-3;
}

.personal .list-simple li {
  @apply mb-1;
}
</style>
