<template>
  <div>
    <Disclosure as="div" class="border-b border-gray-200 py-6" v-slot="{ open }">
      <h3 class="-my-3 flow-root">
        <DisclosureButton
          class="flex w-full items-center justify-between bg-white py-3 text-gray-400 hover:text-gray-500">
          <span class="font-medium text-gray-900 flex items-center">
            Voir le détail
          </span>
          <span class="ml-6 flex items-center">
            <PlusIcon v-if="!open" class="h-5 w-5" aria-hidden="true" />
            <MinusIcon v-else class="h-5 w-5" aria-hidden="true" />
          </span>
        </DisclosureButton>
      </h3>
      <DisclosurePanel class="pt-6">
        <dl class="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
          <div class="flex justify-between py-3 text-sm font-medium">
            <dt class="text-gray-500">Repas</dt>
            <dd class="text-gray-900">{{ expense.evening_meals }} €</dd>
          </div>
          <div class="flex justify-between py-3 text-sm font-medium">
            <dt class="text-gray-500">Hébergement</dt>
            <dd class="text-gray-900">{{ expense.accommodation_costs }} €</dd>
          </div>
          <div class="flex justify-between py-3 text-sm font-medium">
            <dt class="text-gray-500">Frais
              kilométriques</dt>
            <dd class="text-gray-900">{{ expense.transport_costs }} €</dd>
          </div>
          <div class="flex justify-between py-3 text-sm font-medium">
            <dt class="text-gray-500">Péages,
              parking, etc.</dt>
            <dd class="text-gray-900">{{ expense.toll_parking_costs }} €</dd>
          </div>
          <div class="flex justify-between py-3 text-sm font-medium">
            <dt class="text-gray-500">Transport en commun</dt>
            <dd class="text-gray-900">{{ expense.public_transport_costs }} €</dd>
          </div>
          <div class="flex justify-between py-3 text-sm font-medium">
            <dt class="text-gray-500">Autres</dt>
            <dd class="text-gray-900">{{ expense.other_costs }} €</dd>
          </div>
          <div class="flex justify-between py-3 text-sm font-medium">
            <dt class="text-gray-500">Justificatifs</dt>
            <dd class="text-gray-900">{{ expense.receipts?.length }}</dd>
          </div>
        </dl>
        <div class="col-span-full" v-if="expense.receipts">
          <dd class="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            <ul role="list" class="divide-y divide-gray-100 rounded-md border border-gray-200">
              <li v-for="userReceipt in expense.receipts" :key="userReceipt.id"
                class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                <div class="flex w-0 flex-1 items-center">
                  <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                  <div class="ml-4 flex min-w-0 flex-1 gap-2">
                    <span class="truncate font-medium">{{
                      userReceipt.description }}</span>
                  </div>
                </div>
                <div class="ml-4 flex-shrink-0">
                  <a :href="userReceipt.temporary_receipt_url" target="_blank"
                    class="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer">Voir</a>
                </div>
              </li>
            </ul>
          </dd>
        </div>
      </DisclosurePanel>
    </Disclosure>
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, defineProps } from 'vue';
import { PlusIcon, MinusIcon } from '@heroicons/vue/20/solid';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/vue';
import fetchExpensesServices from '@/services/ExpensesServices.js';
import { PaperClipIcon } from '@heroicons/vue/24/solid';

let props = defineProps({
  expense: [Array, Object],
  openInfos: Boolean,
})
const open = ref(true);
const totalAmountMeals = ref(null);
const cap_amount = ref(null);

onMounted(() => {
  if (props.openInfos) {
    console.log('openInfos', props.openInfos);
    open.value = true;
  }
});
</script>
